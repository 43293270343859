import { Box, BoxProps } from "@material-ui/core";
import React from "react";
import { getUserDetails } from "../../requests/user";
import { UserDropdown } from "./UserDropdown";
export const headerHeight = 65;

const headerProps: BoxProps = {
  component: "header",
  height: headerHeight,
  bgcolor: "white",
  border: "none",
  boxShadow:
    "0px 3px 1px -2px rgba(0,0,0,0.13),0px 2px 2px 0px rgba(0,0,0,0),0px 1px 5px 0px rgba(0,0,0,0)",
  display: "flex",
  flexDirection: "row",
  zIndex: 1,
};

export const Header: React.FC = () => {
  const [userFirstLetter, setUserFirstLetter] = React.useState<string>("");
  const [userName, setUserName] = React.useState<any>();
  const [userRole, setUserRole] = React.useState<any>();
  const getUser = React.useCallback(async () => {
    try {
      const u = await getUserDetails();
      if (u?.fullName! && u.fullName !== undefined) {
        const firstLastLatter = `${u?.fullName!.split(" ")[0][0]}${
          u?.fullName!.split(" ")[1][0]
        }`;
        if (u?.isAdministrator) {
          setUserRole("Administrator");
        } else {
          setUserRole("User");
        }
        setUserFirstLetter(firstLastLatter);
      }
      setUserName(u?.fullName);
    } catch (error) {
      console.error(error);
    } finally {
    }
  }, []);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Box {...headerProps}>
      <Box
        px={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        flex="1 1 auto"
      >
        <Box flex="1 1 auto" />
        <UserDropdown
          userName={userFirstLetter}
          userFullName={userName}
          userRole={userRole}
        />
      </Box>
    </Box>
  );
};
