import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  papercontainer: {
    marginTop: theme.spacing(1),
    padding: "3em 4em",
  },
  container: {
    // width: "100%",
    // backgroundColor: theme.palette.background.paper
    // padding: `${theme.spacing(2)} ${theme.spacing(5)}`
    // padding: `1em 2em`
  },
  tile: {
    backgroundColor: "#f9f9f9",
    justifyContent: "center",
    alignItems: "center",
  },
  calendar: {
    backgroundColor: "lightgreen",
  },
}));
