import styled from "styled-components";

export const RectangleBadgeFilled = styled.div<{
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  font-size: 12px;
  color: ${({ theme, color }) => color || theme.colors.base.white};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.colors.brownGrey};
  padding: 2px 4px;
  box-sizing: border-box;
  text-transform: uppercase;
  text-align: center;
  width: ${({ width }) => width};
  min-width: 70px;
`;

export const RectangleBadgeOutlined = styled.div<{
  color?: string;
  backgroundColor?: string;
  width?: string;
}>`
  font-size: 12px;
  border: 1px solid ${({ theme, color }) => color || theme.colors.greyish};
  color: ${({ theme, color }) => color || theme.colors.brownGrey};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding: 2px 4px;
  min-width: 70px;
  box-sizing: border-box;
  text-transform: uppercase;
  width: ${({ width }) => width};
`;
