import React, { useCallback, useEffect, useState } from "react";
import { RWrapper } from "../../../components/layout/RWrapper";
import styled from "styled-components";
import {
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { useQuery } from "react-query";
import {
  getScheduledSms,
  GetScheduledSmsResponse,
} from "../../../requests/sms";
import { AxiosError } from "axios";
import moment from "moment";
import { useSnackbar } from "notistack";
import Dropdown from "../../../components/inputs/Dropdown";
import ScheduledSmsListItem from "./ScheduledSmsListItem";
import { Autocomplete, Pagination } from "@material-ui/lab";
import { User } from "../../StaffScreen";
import { requestGetStaff } from "../../StaffScreen/requests";
import { requestGetLocations, requestGetRosters } from "../../RosterScreen/requests";
import { AutocompleteInput } from "../../RosterScreen/views/RosterDayView/roster-day-view-form-row";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const DropdownContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const DropdownHolder = styled.div`
  width: 100px;
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-right: ${({ theme }) => theme.gap[1]};
  height: fit-content;
  &:last-child {
    margin-right: 0;
  }
`;

const FlexFiller = styled.div`
  display: flex;
  flex: 1;
`;

const TableTitle = styled.div`
  color: ${({ theme }) => theme.colors.greyishBrown};
  box-sizing: border-box;
  padding: 0 ${({ theme }) => theme.gap[1]};
  // font-size: ${({ theme }) => theme.fonts.size.default};
  font-size: 12px;
`;

const Spacer = styled.div`
  height: ${({ theme }) => theme.gap[1]};
`;

type StatusItem =
  | { title: "Scheduled"; value: "scheduled" }
  | { title: "Stopped"; value: "stopped" }
  | { title: "Error"; value: "error" }
  | { title: "Sent"; value: "success" }
  | { title: "All"; value: "all" };

type Order = { title: string; value: string };

type Recipient = { title: string; value: number | undefined };

type Recipient2 = { title: string; value: number | undefined };

type Roster = { title: string; value: number | undefined };

type Roster2 = { label: string; id: number | undefined };

function ScheduledSmsScreen() {
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));
  const { enqueueSnackbar } = useSnackbar();
  const [status, setStatus] = useState<StatusItem>({
    title: "Scheduled",
    value: "scheduled",
  });
  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [pages, setPages] = useState<number>(1);
  const [page, setPage] = useState<number>(1);
  const [recipient, setRecipient] = useState<Recipient>({
    title: "All Recipients",
    value: undefined,
  });

  const [roster, setRoster] = useState<Roster>({
    title: "All Rosters",
    value: undefined,
  });

  const { data, isLoading } = useQuery<GetScheduledSmsResponse, AxiosError>(
    ["get-scheduled-sms", status, take, order, page, recipient, roster],
    () => {
      const skip = (page - 1) * take;
      return getScheduledSms(
        {
          start: moment().subtract(1, "month"),
          end: moment().add(1, "month"),
        },
        order.value as "ASC" | "DESC",
        take,
        skip,
        status.value,
        roster.value,
        recipient.value
      );
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get scheduled SMS`,
          { variant: "error" }
        );
      },
    }
  );

  const [userList, setUserDropdown] = React.useState<Array<Recipient>>([]);

  const [rosterList, setRosterDropdown] = React.useState<Array<Roster>>([]);

  const retrieveUsers = useCallback(
    async () => {
      try {
        const ut = await requestGetStaff(undefined);

        let userDropdownOptions = [];

        userDropdownOptions.push({ title: "All Recipients", value: undefined });

        for (var i = 0; i < ut.length; i++) {
          // push the component to elements!
          if(!ut[i].deletedAt)
          userDropdownOptions.push({ title: ut[i].fullName || '', value: ut[i].id });
        }

        setUserDropdown(userDropdownOptions);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
      }
    },
    [enqueueSnackbar]
  );

  const retrieveRosters = useCallback(
    async () => {
      try {
        const locations = await requestGetLocations();

        const rosters = [];

        rosters.push({ title: "All Rosters", value: undefined });

        for (var i = 0; i < locations.length; i++) {
          const locID = locations[i].id;
          if (locID) {
            const rosterResult = await requestGetRosters(locID);
            if (rosterResult) {
              for (var i = 0; i < rosterResult.length; i++) {
                rosters.push({ title: rosterResult[i].name || '', value: rosterResult[i].id });
              }
            }
          }
        }

        setRosterDropdown(rosters);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
      }
    },
    [enqueueSnackbar]
  );

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      retrieveUsers();
      retrieveRosters();
      setPages(total);
    }
  }, [data, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  const statusOptions = [
    { title: "All", value: "all" },
    { title: "Scheduled", value: "scheduled" },
    { title: "Stopped", value: "stopped" },
    { title: "Error", value: "error" },
    { title: "Sent", value: "success" },
  ];

  const pageSizeOptions= [
    { title: "5", value: 5 },
    { title: "10", value: 10 },
    { title: "20", value: 20 },
    { title: "50", value: 50 },
    { title: "100", value: 100 },
    { title: "200", value: 200 },
  ];

  const orderOptions = [
    { title: "Desc", value: "DESC" },
    { title: "Asc", value: "ASC" },
  ];

  return (
    <RWrapper>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Scheduled SMS
        </Typography>
      </TitleBox>
      <Divider />
      <ContentContainer>
        <DropdownContainer>
          <Pagination count={pages} page={page} onChange={handlePageChange} />{" "}
          <FlexFiller />

          <Autocomplete
            options={pageSizeOptions}
            style={{ width: 200}}
            getOptionLabel={(option) => option.title}
            disableClearable
            defaultValue={pageSizeOptions[3]}
            onChange={(e, value) => {setPage(1);setTake(value ? value.value as number : pageSizeOptions[0].value)}}
            renderInput={(params) =>
              <TextField {...params} label="Records per page" variant="outlined" />}
          />

          <Autocomplete
            options={orderOptions}
            style={{ width: 200}}
            getOptionLabel={(option) => option.title}
            disableClearable
            defaultValue={order}
            onChange={(e, value) => {setPage(1);setOrder({ title: value ? value.title : orderOptions[0].title, value: value ? value.value as string : orderOptions[0].value})}}
            renderInput={(params) =>
              <TextField {...params} label="Order" variant="outlined" />}

          />

          <Autocomplete
            options={statusOptions}
            style={{ width: 200}}
            defaultValue={status}
            disableClearable
            getOptionLabel={(option) => option.title}
            onChange={(e, value) => {setPage(1);setStatus(value as StatusItem)}}
            renderInput={(params) =>
              <TextField {...params} label="Status" variant="outlined" />}
          />

          <Autocomplete
            options={rosterList}
            style={{ width: 200}}
            defaultValue={roster}
            disableClearable
            getOptionLabel={(option) => option.title}
            onChange={(e, value) => {setPage(1);setRoster(value as Roster)}}
            renderInput={(params) =>
              <TextField {...params} label="Roster" variant="outlined" />}
          />
          
          <Autocomplete
            options={userList}
            style={{ width: 200}}
            defaultValue={recipient}
            disableClearable
            getOptionLabel={(option) => option.title}
            onChange={(e, value) => {setPage(1);
              setRecipient(value as Recipient)}}
            renderInput={(params) =>
              <TextField {...params} label="Recipient" variant="outlined" />}
          />
        </DropdownContainer>

        <Spacer />
        <Spacer />

        {data && large ? (
          <Grid container spacing={1}>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Shift name</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Roster</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Scheduled at</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Sent at</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Recipient</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Shift start</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Shift end</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={1}>
              <TableTitle>Status</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <TableTitle>Message</TableTitle>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2}>
              <TableTitle>Error</TableTitle>
            </Grid>
          </Grid>
        ) : null}
        {data?.scheduled.map((sms) => (
          <ScheduledSmsListItem
            key={sms.id}
            uuid={sms.uuid}
            userName={sms.user?.fullName || ""}
            rosterName={sms.shift?.roster?.name || ""}
            status={sms.status}
            sentDateTime={
              sms.sentAt
                ? moment(sms.sentAt).format("YYYY/MM/DD HH:mm")
                : undefined
            }
            shiftName={sms.shift?.shiftName || ""}
            scheduledDateTime={moment(sms.scheduledAt).format(
              "YYYY/MM/DD HH:mm"
            )}
            startDateTime={
              sms.shift
                ? moment(sms.shift!.startDts).format("YYYY/MM/DD HH:mm")
                : ""
            }
            endDatetime={
              sms.shift
                ? moment(sms.shift!.endDts).format("YYYY/MM/DD HH:mm")
                : ""
            }
            error={sms.error}
            message={sms.message}
          />
        ))}
        <Pagination count={pages} page={page} onChange={handlePageChange} />
      </ContentContainer>
    </RWrapper>
  );
}

export default ScheduledSmsScreen;
