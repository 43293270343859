import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
  monthname: {
    cursor: "pointer",
  },
  cell: {
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.grey[100],
    },
  },
  header: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    cursor: "pointer",
  },
  calendarstatuslabel: {
    color: "#FFFFFF",
    borderRadius: theme.shape.borderRadius,
    padding: "0 1em",
    textTransform: "capitalize",
  },
  greyed: {
    backgroundColor: theme.palette.grey[400],
  },
  green: {
    backgroundColor: theme.palette.success.main,
  },
  red: {
    backgroundColor: theme.palette.error.main,
  },
  numbercurrent: {
    color: theme.palette.text.primary,
  },
  numbergrayed: {
    color: theme.palette.text.hint,
  },
}));
