import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink, useHistory } from "react-router-dom";
import roosterLogo from "../../assets/images/rooster.svg";
import calendarPic from "../../assets/images/pictograms/calendar.svg";
import staffPic from "../../assets/images/pictograms/staff.svg";
import templatesPic from "../../assets/images/pictograms/templates.svg";
import { Typography } from "@material-ui/core";
import { useProgressBar } from "../bars/ProgressBarGlobal";
import { PUBLIC_API_URL } from "../../ENDPOINTS";
import proaxiomLogo from "./ProaxiomLogo.png";
import { getUserDetails } from "../../requests/user";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "100%",
      minWidth: 240,
      maxWidth: "80vw",
      backgroundColor: theme.palette.background.paper,
      boxSizing: "border-box",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    logoContainer: {
      marginTop: theme.spacing(45),
      height: 80,
      // width: 80,
      // border: "1px solid black",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      // objectFit: "contain",

      // backgroundColor: theme.palette.primary.main,
    },
    companyLogo: {
      marginBottom: theme.spacing(10),
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    proaxiomLogo: {
      height: 80,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    proaxiomCopyRights: {
      justifyContent: "center",
      alignItems: "center",
      fontWeight: 500,
    },
    userRole: {
      fontWeight: 600,
      marginBottom: "10px",
    },
    logo: {
      height: "80px",
    },
    navlink: {
      width: "100%",
      // border: "1px solid black",
      marginBottom: theme.spacing(1),
      textDecoration: "none",
    },
    navlinkContainer: {
      display: "flex",
      width: "100%",
      // backgroundColor: "gold",
      alignItems: "center",
      color: theme.palette.text.primary,
    },
    active: {
      "& div div": {
        backgroundColor: theme.palette.primary.main,
      },
      "& div p": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& div img": {
        filter: `brightness(100)`,
        // filter: `brightness(100) sepia(1) hue-rotate(180deg) saturate(4)`,
      },
    },
    iconContainer: {
      width: 40,
      height: 30,
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1),
      boxSizing: "border-box",
      // border: "1px solid black",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      transition: `background-color ${theme.transitions.duration.short}ms ${theme.transitions.easing.easeOut}`,
    },
    icon: {
      // width: 50,
      height: "60%",
    },
  };
});

export default function SideNavAdmin() {
  const c = useStyles();
  const { barActivate, barStop } = useProgressBar();
  const history = useHistory();
  const [userCompanyLogo, setUserCompanyLogo] = React.useState<any>();
  const [userCompanyName, setUserCompanyName] = React.useState<any>();

  const getUser = React.useCallback(async () => {
    try {
      barActivate();
      const u = await getUserDetails();
      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [history]);

  React.useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <div className={c.container}>
      <div className={c.companyLogo}>
        <img
          src={userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`}
          alt={userCompanyName}
          width="190px"
        />
      </div>

      <NavLink to="/roster" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_calendar" src={calendarPic} />
          </div>
          <Typography color="textPrimary">Roster</Typography>
        </div>
      </NavLink>
      <NavLink to="/staff" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_staff" src={staffPic} />
          </div>
          <Typography color="textPrimary">Staff</Typography>
        </div>
      </NavLink>
      <NavLink to="/templates" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="roster_templates" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Templates</Typography>
        </div>
      </NavLink>
      <NavLink
        to="/swap-offers"
        activeClassName={c.active}
        className={c.navlink}
      >
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="swap-offers" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Swap offers</Typography>
        </div>
      </NavLink>
      <NavLink to="/settings" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="settings" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Settings</Typography>
        </div>
      </NavLink>
      <NavLink to="/sms" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="sms" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Scheduled SMS</Typography>
        </div>
      </NavLink>
      {/* <NavLink to="/email" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="email" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Sent Emails</Typography>
        </div>
      </NavLink> */}
      <NavLink to="/audit" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="audit" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Audit</Typography>
        </div>
      </NavLink>
      <NavLink to="/reports" activeClassName={c.active} className={c.navlink}>
        <div className={c.navlinkContainer}>
          <div className={c.iconContainer}>
            <img className={c.icon} alt="reports" src={templatesPic} />
          </div>
          <Typography color="textPrimary">Reports</Typography>
        </div>
      </NavLink>
      <div className={c.logoContainer}>
        <img className={c.logo} alt="logo" src={roosterLogo} />
      </div>
      {/* <LogoutButton onClick={logout}>Logout</LogoutButton> */}
      <div className={c.proaxiomLogo}>
        <img src={proaxiomLogo} alt="logo" width="190px" />
      </div>
      <Typography color="textPrimary" className={c.userRole}>
        &#169; 2022
      </Typography>
    </div>
  );
}
