import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import {
  getUserAllShifts,
  postSwapShifts,
  postTakeShift,
} from "../../../requests/shifts";
import { Shift } from "../../RosterScreen/requests";
import { useShift } from "../UserRequestSwapScreen/Provider";
import SwapModal from "./SwapModal";
import SwapShiftComponentDesktop from "./swapShiftComponentDesktop";

const useStyles = makeStyles((theme) => {
  const cellHeight = theme.typography.fontSize * 5;
  return {
    container: {
      boxSizing: "border-box",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      // border: `1px dotted black`,
    },
    desktopCalendar: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // backgroundColor: "red",
      padding: theme.spacing(1),
      boxSizing: "border-box",
      minWidth: "fit-content",
    },
    desktopCalendarContainer: {
      display: "flex",
      flex: 1,
    },
    desktopCalendarHolder: {
      display: "flex",
      width: "100%",
    },
    desktopCalendarNamesColumn: {
      display: "flex",
      flexDirection: "column",
      // backgroundColor: "yellow",
      width: 150,
      overflow: "hidden",
    },
    desktopCalendarColumn: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      // border: "1px solid white",
    },
    desktopCalendarCell: {
      width: "100%",
      minWidth: 82,
      padding: theme.spacing(0.5),
      height: cellHeight,
      // backgroundColor: "black",
      boxSizing: "border-box",
    },
    desktopCalendarCellInner: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxSizing: "border-box",
      // backgroundColor: "lightgreen",
      fontSize: theme.typography.fontSize,
      whiteSpace: "nowrap",
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
      transition: `box-shadow ${theme.transitions.duration.shorter}ms`,
    },
    activeShadow: {
      cursor: "pointer",
      "&:hover": {
        boxShadow: theme.shadows[1],
      },
    },
    desktopCalendarControlBar: {
      width: "100%",
      marginBottom: theme.spacing(1),
      // height: cellHeight * 2,
      // backgroundColor: "gold",
    },
    desktopCalendarNavigation: {
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
      height: cellHeight,
    },
    desktopCalendarDescription: {
      display: "flex",
      alignItems: "center",
      flex: 1,
      height: cellHeight,
    },
    desktopCalendarDescriptionCell: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: theme.typography.fontSize,
    },
    monthText: {
      width: 150,
      textAlign: "center",
    },
    arrow: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: 40,
      height: "50%",
      borderRadius: theme.shape.borderRadius,
      textAlign: "center",
      cursor: "pointer",
      touchAction: "manipulation",
      transition: `all ${theme.transitions.duration.shorter}ms`,
      "&:hover": {
        backgroundColor: theme.palette.grey[300],
      },
    },
    cardHolder: {
      // backgroundColor: "gold",
      width: "100%",
      padding: theme.spacing(1),
      boxSizing: "border-box",
    },
    cardHolderSelectedCardWrapper: {
      marginTop: theme.spacing(3),
    },
    selectButtonHolder: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      marginTop: theme.spacing(1),
    },
  };
});

export default function UserRequestShiftSwapScreen() {
  const c = useStyles();
  const history = useHistory();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const { shifts } = useShift();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [startMoment, setStartMoment] = useState<Moment>(moment());
  const [shiftsToSwap, setShiftsToSwap] = useState<{
    [key: string]: Array<Shift>;
  }>({});
  const [selectedShift, setSelectedShift] = useState<Shift | undefined>(
    undefined
  );

  const retrieveShiftsForSwap = useCallback(async () => {
    try {
      barActivate();
      if (shifts.length) {
        const result = await getUserAllShifts();
        setShiftsToSwap(result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      barStop();
    }
  }, [shifts, barActivate, barStop]);

  useEffect(() => {
    retrieveShiftsForSwap();
  }, [retrieveShiftsForSwap]);

  function onSelectSwapClick() {
    // setSelectedShift(s);
    setModalOpen(true);
  }
  async function TakeShiftRequest(p: { swapShift: Shift }) {
    try {
      barActivate();
      await postTakeShift(p.swapShift.id!);
      setModalOpen(false);
      enqueueSnackbar("Swap request sent", { variant: "success" });
      history.push("/request-swap");
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Swap request failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  }

  async function submitSwapRequest(p: {
    currentShift: Shift;
    swapShift: Shift;
  }) {
    try {
      barActivate();
      await postSwapShifts(p.currentShift.id!, p.swapShift.id!);
      setModalOpen(false);
      enqueueSnackbar("Swap request sent", { variant: "success" });
      history.push("/requests");
    } catch (err) {
      if (err.response) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar("Swap request failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  }

  return (
    <>
      <Typography variant="h4">Request Shift Swap</Typography>
      <SwapModal
        onClose={() => setModalOpen(false)}
        open={modalOpen}
        swapShift={shifts}
        currentShift={
          selectedShift && shifts.includes(selectedShift) === false
            ? selectedShift
            : undefined
        }
        onSubmit={submitSwapRequest}
        onTakeShift={TakeShiftRequest}
      />

      {shifts && (
        <SwapShiftComponentDesktop
          requestShift={shifts}
          selectedShift={selectedShift}
          setselectedShift={setSelectedShift}
          setstartMoment={setStartMoment}
          startMoment={startMoment}
          shiftsToSwap={shiftsToSwap}
          onSelectSwapClick={onSelectSwapClick}
        />
      )}
    </>
  );
}
