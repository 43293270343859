import { authorizedRequest } from "../httprequests";
import { Shift } from "../screens/RosterScreen/requests";
import { User } from "../screens/StaffScreen";

export async function getShift(shiftId: number) {
  return authorizedRequest.get<Shift>(`/shifts/single/${shiftId}`);
}

export async function postShifts(shifts: Array<Shift>) {
  return authorizedRequest.post<{ shifts: Array<Shift> }, Array<number>>(
    "/shifts/",
    { shifts }
  );
}

export async function putShiftAvailable(shiftId: number) {
  return authorizedRequest.put<{ shiftId: number }, unknown>(
    "/shifts/make-available",
    { shiftId }
  );
}

export async function postTakeAvailableShift(shiftId: number) {
  return authorizedRequest.post<{ shiftId: number }, unknown>(
    "/shifts/take-available-shift",
    { shiftId }
  );
}

export async function getUserShifts(
  year: number | string,
  month: number | string,
  own: boolean,
) {
  return authorizedRequest.get<{ [key: string]: Array<Shift> }>(
    `/shifts/${year}/${month}?own=${own}`
  );
}

export interface UserShiftsToSwapResponse {
  [key: number]: {
    userName: {
      fullName: string;
      firstName: string;
      lastName: string;
      id: number;
    };
    shifts: {
      [key: string]: Array<Shift>;
    };
  };
}

export async function getUserShiftsToSwap(shiftId: number) {
  return authorizedRequest.get<UserShiftsToSwapResponse>(
    `/shifts/to-swap/${shiftId}`
  );
}

interface PostSwapShiftsRequest {
  ownShiftId: number;
  swapShiftId?: number;
}
export async function postSwapShifts(ownShiftId: number, swapShiftId: number) {
  return authorizedRequest.post<PostSwapShiftsRequest, void>("/shifts/swap", {
    ownShiftId,
    swapShiftId,
  });
}

interface PostTakeShiftRequest {
  shiftId: number;
}
export async function postTakeShift(shiftId: number) {
  return authorizedRequest.post<PostTakeShiftRequest, void>("/shifts/take-shift-request", {
    shiftId,
  });
}


export async function deleteShifts(shiftIds: Array<number>) {
  return authorizedRequest.delete(`/shifts`, {
    params: {
      ids: shiftIds,
    },
  });
}

export async function deleteShift(shiftId: number) {
  return authorizedRequest.delete(`/shifts/single/${shiftId}`);
}

export async function getOwnAvailableShifts() {
  return authorizedRequest.get<Array<Shift>>(`/shifts/available`);
}

export interface GetShiftAssigneesResponse {
  assignees: Array<User>;
}
export function getShiftAssignees(rosterId: number) {
  return authorizedRequest.get<GetShiftAssigneesResponse>(
    `/shifts/assignees/roster-id/${rosterId}`
  );
}

  export async function getUserAllShifts() {
    return authorizedRequest.get<{ [key: string]: Array<Shift> }>(`/shifts/allShift`);
  }
