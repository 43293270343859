import React from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import ButtonToggle from "../../Buttons/ButtonToggle";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  button: {
    width: "100%",
    maxWidth: "100%",
  },
  buttonWrapper: {
    marginBottom: theme.spacing(0.5),
  },
}));

interface FormikButtonSeletorProps {
  name: string;
  options: Array<{
    title: string;
    id: string | number;
  }>;
}

export default function FormikButtonSeletor({
  name,
  options,
}: FormikButtonSeletorProps) {
  const classes = useStyles();
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const handleClick = (id: number | string) => {
    if (value.includes(id)) {
      setValue(value.filter((v: number | string) => v !== id));
    } else {
      setValue([...value, id]);
    }
  };

  return (
    <div className={classes.container}>
      {options.map((o) => (
        <div className={classes.buttonWrapper} key={o.id}>
          <ButtonToggle
            title={o.title}
            onClick={() => handleClick(o.id)}
            active={value.includes(o.id)}
            key={o.id}
            className={classes.button}
          />
        </div>
      ))}
    </div>
  );
}
