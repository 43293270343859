import React from "react";
import { Formik, Field, Form } from "formik";
import style from "./style.module.css";
import { emailRegex } from "../../../GLOBALS";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { History } from "history";

export interface SubmitValues {
  email: string;
}

export interface FormProps {
  onSubmit: ({ email }: SubmitValues) => void;
  history: History;
}

const validation = ({ email }: { email: string }) => {
  const errors: { [key: string]: string } = {};
  if (!email) {
    errors.email = "Email required";
  } else if (!emailRegex.test(email)) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default ({ onSubmit, history }: FormProps) => {
  return (
    <AnimatePresence>
      <motion.div
        className={style.formcontainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched, isSubmitting }) => (
            <Form className={style.form} noValidate>
              <div
                className={`${style.errorblock} ${
                  errors.email && touched.email ? style.erropacity1 : ""
                }`}
              >
                <span className={style.errortext}>
                  {errors.email ? errors.email : ""}
                </span>
              </div>
              <Field
                name="email"
                type="email"
                placeholder="Email"
                className={style.input}
                autoComplete="username"
                autoCorrect="off"
                autoCapitalize="none"
              />

              <div className={style.buttonsrow}>
                <Link
                  to="/login"
                  className={`${style.forgotpasswordtext} hidewhensmall`}
                >
                  Login
                </Link>
                <motion.button
                  type="submit"
                  className={style.button}
                  whileHover={
                    isSubmitting || !dirty || !isValid ? {} : { scale: 1.1 }
                  }
                  whileTap={
                    isSubmitting || !dirty || !isValid ? {} : { scale: 0.9 }
                  }
                  disabled={isSubmitting || !dirty || !isValid}
                >
                  <span className={style.buttonText}>Verify Email</span>
                </motion.button>
              </div>
            </Form>
          )}
        </Formik>
        <Link
          to="/login"
          className={`${style.forgotpasswordtext} hidewhenmedium ${style.margin20em}`}
        >
          Login
        </Link>
      </motion.div>
    </AnimatePresence>
  );
};
