import { Grid, Theme, useMediaQuery } from "@material-ui/core";
import React from "react";
import styled from "styled-components";

const Container = styled.div`
  box-sizing: border-box;
  // border: 1px solid black;
  margin: ${({ theme }) => theme.gap[1]} 0;
  background-color: ${({ theme }) => theme.colors.background.paper};
`;

const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 4px 8px;
  box-sizing: border-box;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.greyishBrown};
  // font-size: ${({ theme }) => theme.fonts.size.default};
  font-size: 12px;
  margin-bottom: 2px;
`;

const Text = styled.div`
  font-size: 12px;
`;

const StatusText = styled(Text)<{
  variant: string;
}>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  text-align: center;
  padding: 2px 0;
  box-sizing: border-box;
  overflow: hidden;
  text-transform: capitalize;
  border: 1px solid
    ${({ theme, variant }) =>
      variant === "success"
        ? "green"
        : variant === "error"
        ? theme.colors.deepRed
        : variant === "stopped"
        ? "orange"
        : variant === "scheduled"
        ? theme.colors.deepSkyBlue
        : theme.colors.greyishBrown};
  color: ${({ theme, variant }) =>
    variant === "success"
      ? "green"
      : variant === "error"
      ? theme.colors.deepRed
      : variant === "stopped"
      ? "orange"
      : variant === "scheduled"
      ? theme.colors.deepSkyBlue
      : theme.colors.greyishBrown};
`;

interface ScheduledSmsListItemProps {
  uuid: string;
  shiftName: string;
  startDateTime: string;
  endDatetime: string;
  userName: string;
  rosterName: string;
  scheduledDateTime: string;
  status: string;
  sentDateTime?: string;
  error?: string;
  message?: string;
}
function ScheduledSmsListItem(props: ScheduledSmsListItemProps) {
  const large = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"));

  return (
    <Container>
      <Grid container spacing={1}>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Shift name</Title>}
            <Text>{props.shiftName}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Roster</Title>}
            <Text>{props.rosterName}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Scheduled at</Title>}
            <Text>{props.scheduledDateTime}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Sent at</Title>}
            <Text>{props.sentDateTime}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Recipient</Title>}
            <Text>{props.userName}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Shift start</Title>}
            <Text>{props.startDateTime}</Text>
          </BlockContainer>
        </Grid>
        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Shift end</Title>}
            <Text>{props.endDatetime}</Text>
          </BlockContainer>
        </Grid>

        <Grid item xs={6} sm={4} md={3} lg={1}>
          <BlockContainer>
            {!large && <Title>Status</Title>}
            <StatusText variant={props.status}>{props.status}</StatusText>
          </BlockContainer>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={2}>
          <BlockContainer>
            {!large && <Title>Message</Title>}
            <Text>{props.message}</Text>
          </BlockContainer>
        </Grid>

        <Grid item xs={6} sm={6} md={6} lg={2}>
          <BlockContainer>
            {!large && <Title>Error</Title>}
            <Text>{props.error}</Text>
          </BlockContainer>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ScheduledSmsListItem;
