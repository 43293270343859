import React, { useState } from "react";
import styled from "styled-components";
import {
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { RWrapper } from "../../../components/layout/RWrapper";
import { useMutation, useQuery } from "react-query";
import { AxiosError } from "axios";
import {
  deleteOffer,
  getSwapOffers,
  GetSwapOffersResponse,
  OfferStatus,
} from "../../../requests/offers";
import { useSnackbar } from "notistack";
import OfferRow from "./offer-row";
import {
  OffersTable as Table,
  OffersTh as Th,
  OffersThead as Thead,
  OffersTr as Tr,
} from "./styled";
import usePagination from "../../../hooks/usePagination";
import { Pagination } from "@material-ui/lab";
import Dropdown, { SelectedOption } from "../../../components/inputs/Dropdown";
import StandardButton from "../../../components/Buttons/StandardButton";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;

const ContentContainer = styled.div`
  margin-top: 8px;
  // overflow-x: auto;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const FlexFiller = styled.div`
  display: flex;
  flex: 1;
`;

const DropdownHolder = styled.div`
  width: 100px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-right: ${({ theme }) => theme.gap[1]};
  &:last-child {
    margin-right: 0;
  }
`;

const DeleteButton = styled(StandardButton)`
  color: #aa0000;
`;

function SwapOffersScreen() {
  const { enqueueSnackbar } = useSnackbar();

  const [offerToDelete, setOfferToDelete] = useState<number | undefined>(
    undefined
  );

  const [status, setStatus] = useState<SelectedOption<OfferStatus | undefined>>(
    {
      title: "All",
      value: undefined,
    }
  );
  const [order, setOrder] = useState<SelectedOption<"DESC" | "ASC">>({
    title: "Desc",
    value: "DESC",
  });
  const [offersCount, setOffersCount] = useState<number>(0);

  const { handlePageChange, page, pages, setTake, take } = usePagination({
    count: offersCount,
    take: 50,
  });

  const { data, refetch } = useQuery<GetSwapOffersResponse, AxiosError>(
    ["get-swap-offers-for-admin", take, page, order, status],
    () => {
      const skip = (page - 1) * take;
      return getSwapOffers({
        take,
        skip,
        order: order.value as "DESC" | "ASC",
        status: status.value,
      });
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get scheduled SMS`,
          { variant: "error" }
        );
      },
      onSuccess: (data) => {
        if (data.count) {
          setOffersCount(data.count);
        }
      },
    }
  );

  const { mutate: cancelOffer } = useMutation<unknown, AxiosError, number>(
    (offerId) => {
      return deleteOffer(offerId);
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to cancel the offer`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        setOfferToDelete(undefined);
        await refetch();
        enqueueSnackbar(`Offer cancelled`, {
          variant: "success",
        });
      },
    }
  );

  function onCancelClick(offerId: number) {
    setOfferToDelete(offerId);
  }

  function onCancelConfirm() {
    if (offerToDelete) {
      cancelOffer(offerToDelete);
    }
  }

  return (
    <RWrapper>
      <Dialog
        open={Boolean(offerToDelete)}
        onClose={() => setOfferToDelete(undefined)}
      >
        <DialogTitle>Cancel offer?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Confirm that you want to cancel offer "{offerToDelete}"
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StandardButton
            title="Close"
            onClick={() => setOfferToDelete(undefined)}
          />
          <DeleteButton title="Delete" onClick={onCancelConfirm} />
        </DialogActions>
      </Dialog>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Swap offers
        </Typography>
      </TitleBox>
      <Divider />
      <ContentContainer>
        <OptionsContainer>
          <Pagination count={pages} page={page} onChange={handlePageChange} />
          <FlexFiller />

          <DropdownHolder>
            <Dropdown
              textShown={status.title}
              onSelect={(v) =>
                setStatus(v as SelectedOption<OfferStatus | undefined>)
              }
              options={[
                { title: "All", value: undefined },
                { title: "Pending", value: "pending" },
                { title: "Accepted", value: "accepted" },
                { title: "Cancelled", value: "cancelled" },
                { title: "Declined", value: "declined" },
              ]}
            />
          </DropdownHolder>

          <DropdownHolder>
            <Dropdown
              textShown={`${take}`}
              onSelect={(v) => setTake(v.value as number)}
              options={[
                { title: "5", value: 5 },
                { title: "10", value: 10 },
                { title: "20", value: 20 },
                { title: "50", value: 50 },
                { title: "100", value: 100 },
                { title: "200", value: 200 },
              ]}
            />
          </DropdownHolder>

          <DropdownHolder>
            <Dropdown
              textShown={order.title}
              onSelect={(v) => setOrder(v as SelectedOption<"DESC" | "ASC">)}
              options={[
                { title: "Desc", value: "DESC" },
                { title: "Asc", value: "ASC" },
              ]}
            />
          </DropdownHolder>
        </OptionsContainer>
        <Table>
          <Thead>
            <Tr>
              <Th width="50px" />
              <Th width="100px">Offer ID</Th>
              <Th>Offer placed at</Th>
              <Th>Outbound shift name</Th>
              <Th>Outbound user name</Th>
              <Th>Inbound shift name</Th>
              <Th>Inbound user name</Th>
              <Th width="70px">Status</Th>
            </Tr>
          </Thead>
          <tbody>
            {data &&
              data.offers.map((offer, index) => (
                <OfferRow
                  offer={offer}
                  key={offer.id}
                  index={index}
                  onCancelClick={onCancelClick}
                />
              ))}
          </tbody>
        </Table>
        <Pagination count={pages} page={page} onChange={handlePageChange} />
      </ContentContainer>
    </RWrapper>
  );
}

export default SwapOffersScreen;
