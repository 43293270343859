import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ButtonBase } from "@material-ui/core";

interface StyleProps {
  accent?: boolean;
  disabled?: boolean;
}

const useStyles = makeStyles((theme) => {
  return {
    container: ({ accent }: StyleProps) => ({
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderRadius: theme.shape.borderRadius,
      boxSizing: "border-box",
      border: accent ? `none` : `1px solid ${theme.palette.divider}`,
      backgroundColor: accent
        ? theme.palette.primary.main
        : theme.palette.background.paper,
      color: accent ? theme.palette.common.white : theme.palette.text.primary,
      "&:focus, &:active": {
        border: accent ? `none` : `1px solid ${theme.palette.divider}`,
      },
      "&:disabled": {
        backgroundColor: theme.palette.action.disabledBackground,
        border: "none",
        color: theme.palette.common.white,
        boxSizing: "border-box",
      },
    }),
  };
});

interface StandardButtonProps {
  title: string | JSX.Element;
  accent?: boolean;
  type?: "submit" | "button";
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
}

export default ({
  title,
  type,
  onClick,
  disabled,
  className,
  accent,
}: StandardButtonProps) => {
  const c = useStyles({ accent, disabled });

  return (
    <ButtonBase
      className={`${c.container} ${className ?? ""}`}
      type={type ?? "button"}
      onClick={onClick}
      disabled={disabled}
    >
      <Typography variant="body2">{title}</Typography>
    </ButtonBase>
  );
};
