import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import style from "./style.module.css";
import TemplateListItem from "./TemplateListItem";
import ModalContainer from "../../components/modals/ModalContainer";
import CreateTemplateWindow, {
  CreateTemplateWindowSubmitValues,
} from "./CreateTemplateWindow";
import {
  createNewTemplate,
  requestGetTemplates,
  GetTemplatesResponse,
  TemplateMini,
  editTemplateName,
  deleteTemplate,
} from "./requests";
import PageHeader from "../../components/PageHeader";
import StandardButton from "../../components/Buttons/StandardButton";
import { RWrapper } from "../../components/layout/RWrapper";

const templateExistsMessage = "Template already exists!";

export default () => {
  const history = useHistory();

  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [apiError, setapiError] = useState<string>("");
  const [currentTemplateId, setcurrentTemplateId] = useState<
    number | undefined
  >(undefined);
  const [currentTemplateName, setcurrentTemplateName] = useState<string>("");

  const [templates, settemplates] = useState<GetTemplatesResponse>([]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setapiError("");
    }, 2000);
    return () => clearTimeout(timer);
  }, [apiError]);

  useEffect(() => {
    _getTemplates();
  }, []);

  const _defineCurrentTemplateName = useCallback(
    (tID?: number) => {
      try {
        if (tID && templates.length) {
          const te = templates.find((t) => t.id === tID);
          const name = te?.name;
          setcurrentTemplateName(tID && name ? name : "");
        } else {
          if (currentTemplateName) setcurrentTemplateName("");
        }
      } catch (error) {
        console.debug(error);
      }
    },
    [currentTemplateName, templates]
  );

  // to keep current template name updated
  useEffect(() => {
    _defineCurrentTemplateName(currentTemplateId);
  }, [currentTemplateId, _defineCurrentTemplateName]);

  const _submitTemplate = async ({
    name,
  }: CreateTemplateWindowSubmitValues) => {
    if (currentTemplateId) {
      try {
        const editedTemplateId = await editTemplateName(
          currentTemplateId,
          name
        );
        const updatedTemplates = templates.map((t) => {
          if (t.id === editedTemplateId) {
            t.name = name;
          }
          return t;
        });
        settemplates(updatedTemplates);
        _closeModal();
      } catch (error) {
        console.error(error);
        const { status } = error.response;
        if (status === 409) {
          setapiError(templateExistsMessage);
        }
      }
    } else {
      try {
        const templateId: number = await createNewTemplate(name);
        _openTemplate(templateId);
      } catch (error) {
        console.error(error);
        const { status } = error.response;
        if (status === 409) {
          setapiError(templateExistsMessage);
        }
      }
    }
  };

  const _getTemplates = async () => {
    try {
      const t = await requestGetTemplates();
      settemplates(t);
    } catch (error) {
      console.error(error);
    }
  };

  const _openTemplate = (id: number) => {
    history.push(`/templates/${id}`);
  };

  const _deleteTemplate = async (id: number) => {
    try {
      const deletedId: number = await deleteTemplate(id);
      console.log(deletedId);
      const filtered = templates.filter((t) => t.id !== deletedId);
      settemplates(filtered);
      _closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const _openModal = (id?: number) => {
    if (id) {
      setcurrentTemplateId(id);
    }
    setmodalOpen(true);
  };

  const _closeModal = () => {
    setcurrentTemplateId(undefined);
    setmodalOpen(false);
  };

  return (
    <RWrapper>
      <ModalContainer onClose={_closeModal} open={modalOpen}>
        <CreateTemplateWindow
          onSubmit={_submitTemplate}
          onDelete={_deleteTemplate}
          apiError={apiError}
          onCancel={_closeModal}
          templateId={currentTemplateId}
          templateName={currentTemplateName}
        />
      </ModalContainer>

      <PageHeader title="Templates">
        <div className={style.inheadercontainer}>
          <StandardButton
            title="Create New +"
            // height="tall"
            onClick={() => _openModal()}
          />
        </div>
      </PageHeader>

      {!templates.length ? (
        <div className={style.notemplatescontainer}>
          <span className={style.notemplatestext}>No Templates</span>
          <StandardButton
            // height="short"
            title="Create a Template"
            // width={200}
            accent
            onClick={() => _openModal()}
          />
        </div>
      ) : (
        <div className={style.templatelistcontainer}>
          {templates.map((template: TemplateMini) => (
            <TemplateListItem
              key={template.id}
              title={template.name}
              id={template.id}
              onClick={_openTemplate}
              onOptionsClick={_openModal}
            />
          ))}
        </div>
      )}
    </RWrapper>
  );
};
