import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { History } from "history";
import PageHeader from "../../components/PageHeader";
import styles from "./style.module.css";
import TabButton from "../../components/Buttons/TabButton";
import TemplateForm from "./TemplateForm";
import {
  getTemplate,
  postTemplateShifts,
  putTemplateShifts,
  deleteTemplateShifts,
} from "./requests";
import {
  convertResponseShifts,
  filterNewTemplateShifts,
  filterDeletedTemplateShifts,
  filterEditedTemplateShifts,
  addShiftDuration,
} from "./logic";
import { FormikHelpers } from "formik";

export interface Shift {
  id?: number;
  name: string;
  oncall: boolean;
  start: string;
  end: string;
  duration: number;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export type Day =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export default () => {
  const history: History = useHistory();

  const { id } = useParams();

  const [templateName, settemplateName] = useState<string>("");

  const [selectedTab, setselectedTab] = useState<"week" | "day">("week");
  const [shifts, setshifts] = useState<Array<Shift>>([]);

  const _getShifts = useCallback(
    async (templateId?: string) => {
      try {
        const { templateShifts: ts, name: tn } = await getTemplate(templateId);
        settemplateName(tn);
        const templateShifts = convertResponseShifts(ts);
        setshifts(templateShifts);
      } catch (error) {
        console.error(error);
        history.replace("/templates");
      }
    },
    [history]
  );

  useEffect(() => {
    _getShifts(id);
  }, [id, _getShifts]);

  const submitShifts = async (
    v: { shifts: Shift[] },
    f: FormikHelpers<{ shifts: Shift[] }>
  ) => {
    try {
      const templateId = Number(id);
      const newShifts = addShiftDuration(filterNewTemplateShifts(v.shifts));
      const deletedShiftIDs = filterDeletedTemplateShifts(shifts, v.shifts);
      const editedShifts = addShiftDuration(
        filterEditedTemplateShifts(shifts, v.shifts)
      );

      const results = await Promise.all([
        postTemplateShifts(templateId, newShifts),
        putTemplateShifts(templateId, editedShifts),
        deleteTemplateShifts(templateId, deletedShiftIDs),
      ]);
      if (results) {
        await _getShifts(id);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <PageHeader title="Template Builder">
        <div className={styles.tabsholder}>
          <TabButton
            title="Day"
            onClick={() => setselectedTab("day")}
            active={selectedTab === "day"}
          />
          <TabButton
            title="Week"
            onClick={() => setselectedTab("week")}
            active={selectedTab === "week"}
          />
        </div>
      </PageHeader>
      <TemplateForm
        templateName={templateName}
        shifts={shifts}
        onSubmit={submitShifts}
        view={selectedTab}
      />
    </div>
  );
};
