import React, { useEffect, Component, FC, useRef } from "react";
import useOnScreen from "../../hooks/useOnScreen";

interface ViewableProps {
  onBecomeVisible?: () => void;
  children?:
    | Array<Element | Component | FC | JSX.Element>
    | Element
    | Component
    | FC
    | JSX.Element;
}

export default function Viewable({ onBecomeVisible, children }: ViewableProps) {
  const { setRef, visible } = useOnScreen({ threshold: 1 });
  const becomeVisible = useRef(onBecomeVisible);

  useEffect(() => {
    if (visible && becomeVisible.current) {
      becomeVisible.current();
    }
  }, [visible]);

  return (
    <div
      ref={setRef}
      style={{
        display: "flex",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}
