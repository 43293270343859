import { Moment } from "moment";
import { authorizedRequest } from "../httprequests";
import { Shift } from "../screens/RosterScreen/requests";
import { User } from "../screens/StaffScreen";

interface DateRange {
  start: Moment;
  end: Moment;
}

interface ScheduledSms {
  id: number;
  uuid: string;
  scheduledAt: Date;
  sentAt?: Date;
  message?: string;
  user?: User;
  shift?: Shift;
  status: "scheduled" | "stopped" | "success" | "error" | "all";
  error?: string;
  providerResponse?: string;
  failedParent?: this;
}
export interface GetScheduledSmsResponse {
  scheduled: Array<ScheduledSms>;
  count: number;
}
export function getScheduledSms(
  range: DateRange,
  order: "DESC" | "ASC",
  take: number,
  skip: number,
  status: "scheduled" | "stopped" | "error" | "success" | "all",
  roster?: number,
  recipient?: number,
) {
  return authorizedRequest.get<GetScheduledSmsResponse>(
    `/sms/start-period/${range.start.format(
      "YYYY-MM-DD"
    )}/end-period/${range.end.format(
      "YYYY-MM-DD"
    )}/take/${take}/skip/${skip}/order/${order}/status/${status}/roster/${roster}/recipient/${recipient}`
  );
}
//http://localhost:5000/api/sms/start-period/2022-12-17/end-period/2023-02-17/take/50/skip/0/order/DESC/status/scheduled/roster/1/recipient/
