import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: theme.typography.fontSize * 1.5,
    height: theme.typography.fontSize * 1.5,
    backgroundColor: ({ color }: { color: string }) => color,
    justifyContent: "center",
    alignItems: "center",
    color: ({ color }: { color: string }) => "#ffffff",
    // {
    //   try {
    //     return theme.palette.getContrastText(color);
    //   } catch (err) {
    //     return "#000000";
    //   }
    // },
    borderRadius: theme.shape.borderRadius,
  },
  text: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.fontSize,
  },
}));

export default function LetterIcon({
  letter,
  color,
}: {
  letter: string;
  color: string;
}) {
  const classes = useStyles({ color });
  return (
    <div className={classes.container}>
      <span className={classes.text}>{letter}</span>
    </div>
  );
}
