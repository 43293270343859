import React from "react";
import styles from "./styles.module.css";
import {
  Formik,
  Form,
  Field,
  FormikProps,
  FieldProps,
  FieldArray,
} from "formik";
import StandardButton from "../../../../components/Buttons/StandardButton";
import { Typography, InputLabel, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModalWindow from "../../../../components/modals/ModalWindow";
import ButtonToggle from "../../../../components/Buttons/ButtonToggle";
import { UserType } from "../../../StaffScreen";
import TextInputFormik from "../../../../components/inputs/TextInputFormik";
import ColorPickerPalette from "../../../../components/inputs/colorPickers/ColorPickerPalette";
import { pastelPalette } from "../../../../GLOBALS";

interface CreateRosterModalInnerSubmitValues {
  name: string;
  userTypes: Array<UserTypeSelected>;
  color: string;
}

export interface CreateRosterModalSubmitValues {
  name: string;
  userTypeIDs: Array<number>;
  color: string;
}

interface UserTypeSelected extends UserType {
  selected?: boolean;
}

interface CreateRosterModalProps {
  onSubmit: (values: CreateRosterModalSubmitValues) => void;
  onCancel: () => void;
  userTypes?: Array<UserTypeSelected>;
}

interface ValidationProps {
  name: string;
  userTypes?: Array<UserTypeSelected>;
}

interface ValidationErrors {
  [key: string]: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: "0.3em",
  },
  switchbuttonsgrid: {
    marginTop: "1em",
    marginBottom: "1em",
  },
  buttonToggle: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const validation = ({ name, userTypes }: ValidationProps) => {
  try {
    const errors: ValidationErrors = {};
    if (!name) {
      errors.name = "Location name can not be empty";
    }
    const userTypeSelected = userTypes?.find((ut) => ut.selected);
    if (!userTypeSelected) errors.userTypes = "Select user type";
    return errors;
  } catch (error) {
    console.error(error);
  }
};

export default ({
  onSubmit,
  onCancel,
  userTypes = [],
}: CreateRosterModalProps) => {
  const classes = useStyles();

  const submit = async (values: CreateRosterModalInnerSubmitValues) => {
    const filtered = values.userTypes.filter((ut) => ut.selected);
    const uts = filtered.map((ut) => ut.id);
    const v: CreateRosterModalSubmitValues = {
      name: values.name,
      userTypeIDs: uts,
      color: values.color,
    };
    return onSubmit(v);
  };

  return (
    <ModalWindow>
      <Formik
        initialValues={{ name: "", userTypes: userTypes, color: "" }}
        validate={validation}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({
          values,
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate autoComplete="off">
            <Typography variant="h5" align="center">
              Create Roster
            </Typography>
            <InputLabel color="primary" className={classes.label}>
              Roster name
            </InputLabel>
            <Field
              name="name"
              type="text"
              className={styles.input}
              component={TextInputFormik}
            />

            {values.userTypes && values.userTypes.length ? (
              <Grid container spacing={1} className={classes.switchbuttonsgrid}>
                <FieldArray
                  name="userTypes"
                  render={() =>
                    values.userTypes.map(
                      (ut: UserTypeSelected, index: number) => (
                        <Grid item xs={12} sm={4} key={ut.id}>
                          <Field name={ut.id} value={ut.selected}>
                            {({ form: { setFieldValue } }: FieldProps) => (
                              <ButtonToggle
                                title={ut.name}
                                active={ut.selected}
                                className={classes.buttonToggle}
                                onClick={() =>
                                  setFieldValue(
                                    `userTypes.${index}.selected`,
                                    !ut.selected
                                  )
                                }
                              />
                            )}
                          </Field>
                        </Grid>
                      )
                    )
                  }
                />
              </Grid>
            ) : null}

            <div>
              <ColorPickerPalette name="color" colors={pastelPalette} />
            </div>

            <div className={styles.buttonsholder}>
              <div />
              <div className={styles.submitbuttonsholder}>
                <div className={styles.cancelbuttonmargin}>
                  <StandardButton title="Cancel" onClick={onCancel} />
                </div>
                <StandardButton
                  title="Create"
                  type="submit"
                  accent
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWindow>
  );
};
