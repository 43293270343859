import { unauthorizedRequest } from "../../httprequests";

export interface DoLoginParams {
  email: string;
  password: string;
}

interface DoLoginRequest {
  email: string;
  password: string;
}

interface DoLoginResponse {}

export interface DoLoginReturn {}

export const requestLogin = ({ email, password }: DoLoginParams) => {
  return unauthorizedRequest.post<DoLoginRequest, DoLoginResponse>(
    "/auth/login",
    { email, password }
  );
};
