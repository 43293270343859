import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Typography, Divider } from "@material-ui/core";
import { RWrapper } from "../../../components/layout/RWrapper";
import { useQuery } from "react-query";
import { AxiosError } from "axios";
import { useSnackbar } from "notistack";
import { getAudit, GetAuditResponse } from "../../../requests/audit";
import Dropdown from "../../../components/inputs/Dropdown";
import AuditTableRow from "./auditTableRow";
import { Pagination } from "@material-ui/lab";

const TitleBox = styled.div`
  margin: 0.5em 0;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const FlexFiller = styled.div`
  display: flex;
  flex: 1;
`;

const DropdownHolder = styled.div`
  width: 100px;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.background.paper};
  margin-right: ${({ theme }) => theme.gap[1]};
  &:last-child {
    margin-right: 0;
  }
`;

const ContentContainer = styled.div`
  margin-top: 8px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const Th = styled.th<{ width?: number }>`
  width: ${({ width }) => (width ? `${width}%` : "")};
  font-weight: 300;
  font-size: ${({ theme }) => theme.fonts.size.default};
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: ${({ theme }) => theme.colors.background.paper};
`;

type Order = { title: string; value: string };

function AuditHistoryScreen() {
  const { enqueueSnackbar } = useSnackbar();

  const [order, setOrder] = useState<Order>({ title: "Desc", value: "DESC" });
  const [take, setTake] = useState<number>(50);
  const [page, setPage] = useState<number>(1);
  const [pages, setPages] = useState<number>(1);

  const { data, isLoading } = useQuery<GetAuditResponse, AxiosError>(
    ["get-audit", order, take, page],
    () => {
      const skip = (page - 1) * take;
      return getAudit(order.value as "ASC" | "DESC", skip, take);
    },
    {
      keepPreviousData: true,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get audit data`,
          { variant: "error" }
        );
      },
    }
  );

  useEffect(() => {
    if (data) {
      const total = Math.ceil(data.count / take);
      setPages(total);
    }
  }, [data, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  return (
    <RWrapper>
      <TitleBox>
        <Typography variant="h4" color="textPrimary">
          Audit history
        </Typography>
      </TitleBox>
      <Divider />
      <ContentContainer>
        <OptionsContainer>
          <Pagination count={pages} page={page} onChange={handlePageChange} />
          <FlexFiller />
          <DropdownHolder>
            <Dropdown
              textShown={`${take}`}
              onSelect={(v) => setTake(v.value as number)}
              options={[
                { title: "5", value: 5 },
                { title: "10", value: 10 },
                { title: "20", value: 20 },
                { title: "50", value: 50 },
                { title: "100", value: 100 },
                { title: "200", value: 200 },
              ]}
            />
          </DropdownHolder>

          <DropdownHolder>
            <Dropdown
              textShown={order.title}
              onSelect={(v) =>
                setOrder({ title: v.title, value: v.value as string })
              }
              options={[
                { title: "Desc", value: "DESC" },
                { title: "Asc", value: "ASC" },
              ]}
            />
          </DropdownHolder>
        </OptionsContainer>

        <Table>
          <thead>
            <tr>
              <Th width={5}>ID</Th>
              <Th width={15}>Task ID</Th>
              <Th width={10}>Audit type</Th>
              <Th width={10}>Source type</Th>
              <Th width={10}>Source ID</Th>
              <Th width={10}>Column name</Th>
              <Th width={10}>Old value</Th>
              <Th width={10}>New Value</Th>
              <Th width={10}>User name</Th>
              <Th width={10}>Created at</Th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.auditData.map((o) => <AuditTableRow key={o.id} {...o} />)}
          </tbody>
        </Table>
        <Pagination count={pages} page={page} onChange={handlePageChange} />
      </ContentContainer>
    </RWrapper>
  );
}

export default AuditHistoryScreen;
