import { authorizedRequest } from "../../httprequests";
import { Location } from ".";
import { CreateRosterModalSubmitValues } from "./modals/CreateRosterModal";
import { UserType } from "../StaffScreen";
import { YearCalendarMonthStatuses } from "../../components/calendars/YearCalendar";
import { Offer } from "../../requests/offers";

interface PostLocationRequest {
  locationName: string;
}

interface PostRosterRequestProps extends CreateRosterModalSubmitValues {
  locationId: number;
}
interface PostRosterRequest {
  roster: PostRosterRequestProps;
}

export interface Roster {
  id: number;
  name: string;
  locationId?: number;
  userTypes?: Array<UserType>;
  color: string;
  createdBy?: number;
  createdAt?: Date;
}

export const requestPostLocation = (locationName: string) =>
  authorizedRequest.post<PostLocationRequest, Location>(`/roster/locations`, {
    locationName,
  });

export const requestGetLocations = () =>
  authorizedRequest.get<Array<Location>>(`/roster/locations`);

export const requestPostRoster = (rosterDetails: PostRosterRequestProps) =>
  authorizedRequest.post<PostRosterRequest, Roster>(`/roster/`, {
    roster: rosterDetails,
  });

export const requestGetRosters = (locationId: number) => {
  return authorizedRequest.get<Array<Roster>>(`/roster/${locationId}`);
};

interface RosterInShift {
  color: string;
  createdBy: number;
  id: number;
  locationId: number;
  name: string;
  tenantId: number;
  userTypes: Array<{ id: number }>;
  location?: {
    name: string;
  };
}

export interface Shift {
  endDts: Date;
  id?: number;
  onCall: boolean;
  status: "draft" | "published";
  recorderedEndDts?: Date;
  recorderedStartDts?: Date;
  rosterId: number;
  rosteredUser?: number;
  shiftName: string;
  ownerName?:number;
  current?: boolean;
  // shiftType?: null
  startDts: Date;
  startTime?: string;
  endTime?: string;
  templateShiftId?: number;
  roster?: RosterInShift;
  available?: boolean;
  own?: boolean;
  owner?: {
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
  };
  user?: {
    firstName: string;
    fullName: string;
    id: number;
    lastName: string;
  };
  offered?: boolean;
  offersOut?: Array<Offer>;
  offersIn?: Array<Offer>;
}

// interface GetRostersShiftsResponse {
//   previous: Array<Shift>;
//   current: Array<Shift>;
// }

export type GetRostersShiftsResponse = Array<Shift & { current: boolean }>;

export const getRostersShifts = ({
  rosterIds,
  date,
}: {
  rosterIds: Array<number>;
  date: string; // in a format YYYY-MM-DD
}) => {
  return authorizedRequest.get<GetRostersShiftsResponse>(
    `/shifts/for-rosters/${rosterIds}/for-date/${date}`
  );
};

export const getRosterStatuses = ({
  rosterIds,
  year,
  month,
}: {
  rosterIds: Array<number>;
  year: number;
  month: number;
}) => {
  return authorizedRequest.get<{
    [key: number]: {
      status: "published" | "unpublished" | "worked";
      rosters: Array<{ id: number; color: string; name: string }>;
    };
  }>(`/roster/status/${rosterIds}/year/${year}/month/${month}`);
};

export const getRosterStatusesYear = ({
  rosterIds,
  year,
}: {
  rosterIds: Array<number>;
  year: number | string;
}) => {
  return authorizedRequest.get<YearCalendarMonthStatuses>(
    `/roster/status/${rosterIds}/year/${year}`
  );
};

type GetShiftStatusesResponse = Array<"draft" | "published">;
export const getShiftStatusVariants = () => {
  return authorizedRequest.get<GetShiftStatusesResponse>(
    "/shifts/status-variants"
  );
};

interface PostPublishMonthRosterCheckResponse {
  code: number;
  message?: string;
  unassignedShifts: Array<Shift>;
}
export async function postPublishMonthRosterCheck(
  year: string | number,
  month: string | number,
  rostersIds: Array<number>
) {
  return authorizedRequest.post<
    { rosters: Array<number> },
    PostPublishMonthRosterCheckResponse
  >(`/roster/publish/year/${year}/month/${month}/check`, {
    rosters: rostersIds,
  });
}

interface PostPublishMonthRosterRequest {
  rosters: Array<number>;
}
interface PostPublishMonthRosterResponse {
  code: number;
  message?: string;
}
export async function postPublishMonthRoster(
  year: string | number,
  month: string | number,
  rostersIds: Array<number>
) {
  // code 0 means nothing to update
  // code 1 means update happened successfully
  return authorizedRequest.post<
    PostPublishMonthRosterRequest,
    PostPublishMonthRosterResponse
  >(`/roster/publish/year/${year}/month/${month}`, {
    rosters: rostersIds,
  });
}
