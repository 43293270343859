import { authorizedRequest } from "../httprequests";
import { User } from "../screens/StaffScreen";

interface AuditType {
  name: string;
  description?: string;
}

export interface AuditResponseItem {
  id: number;
  taskId: string;
  sourceType: string;
  sourceId: number;
  columnName: string;
  oldValue?: string | null;
  newValue?: string | null;
  comment?: string | null;
  createdAt: string;
  user?: User | null;
  auditType: AuditType;
}

export interface GetAuditResponse {
  auditData: Array<AuditResponseItem>;
  count: number;
}
export function getAudit(order: "DESC" | "ASC", skip: number, take: number) {
  return authorizedRequest.get<GetAuditResponse>(
    `/audit/order/${order}/skip/${skip}/take/${take}`
  );
}
