import React, { useState, useEffect, useCallback } from "react";
import ModalWindow from "../ModalWindow";
import ModalContainer from "../ModalContainer";
import { ModalBaseProps } from "../interfaces";
import { Formik, FormikProps, Form, Field } from "formik";
import { Roster } from "../../../screens/RosterScreen/requests";
import { Typography, Box, Grid } from "@material-ui/core";
import useStyles from "./styles";
import SelectFormik from "../../inputs/SelectFormik";
import StandardButton from "../../Buttons/StandardButton";
import {
  TemplateMini,
  requestGetTemplates,
} from "../../../screens/Templates/requests";
import FormikButtonSeletor from "../../inputs/Selectors/ButtonSelector";
import DateRangeSelectorFormik from "../../inputs/Dates/DateRangeSelectorFormik";
import { OnRangeCalendarSelectedProps } from "../../calendars/RangeCalendar";
import * as yup from "yup";
import { postImportTemplate } from "../../../requests/roster";

interface ImportTemplateModalProps extends ModalBaseProps {
  onActionSuccess?: () => void;
  onActionError?: (error: Error) => void;
  rosterNames: Array<Roster>;
}

export interface ImportTemplateFormValues {
  roster: "" | number;
  templates: Array<TemplateMini>;
  dates: OnRangeCalendarSelectedProps | undefined;
}

const validationSchema = yup.object().shape({
  roster: yup.number().required("no roster selected"),
  templates: yup
    .array(yup.mixed())
    .required("no template selected")
    .min(1, "no template selected"),
  dates: yup.lazy((value) => {
    const v:
      | OnRangeCalendarSelectedProps
      | undefined = value as OnRangeCalendarSelectedProps;
    if (v && "single" in v) {
      return yup.object({
        single: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
      });
    }
    return yup.object({
      range: yup.object().shape({
        leftDate: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
        rightDate: yup.object().shape({
          day: yup.number().required(),
          month: yup.number().required(),
          year: yup.number().required(),
        }),
      }),
    });
  }),
});

const ImportTemplateModal = ({
  onClose,
  open = false,
  rosterNames,
  onActionSuccess,
  onActionError,
}: ImportTemplateModalProps) => {
  const classes = useStyles();

  const [templates, settemplates] = useState<Array<TemplateMini>>([]);

  const getTempaltes = useCallback(async () => {
    try {
      const result = await requestGetTemplates();
      settemplates(result);
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    getTempaltes();
  }, [getTempaltes]);

  const submit = async (v: ImportTemplateFormValues) => {
    try {
      await postImportTemplate(v);
      if (onActionSuccess) {
        onActionSuccess();
      }
    } catch (err) {
      console.log(err);
      if (onActionError) {
        onActionError(err);
      }
    }
  };

  return (
    <ModalContainer onClose={onClose} open={open}>
      <ModalWindow>
        <Formik
          initialValues={{ roster: "", templates: [], dates: undefined }}
          onSubmit={submit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({
            errors,
            isValid,
            dirty,
            isSubmitting,
            resetForm,
          }: FormikProps<ImportTemplateFormValues>) => (
            <Form noValidate autoComplete="off">
              <Typography variant="h5" align="center">
                Import Template
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={5}>
                  <Box className={classes.leftSideContainer}>
                    <Typography color="textSecondary">Select roster</Typography>
                    <Field
                      component={SelectFormik}
                      name="roster"
                      options={rosterNames.map((r: Roster) => ({
                        value: r.id,
                        title: r.name,
                      }))}
                    />
                    <Box className={classes.spacerVertical} />
                    <Typography color="textSecondary">
                      Select Templates
                    </Typography>
                    <Box className={classes.selectorButtonsOuterHolder}>
                      <FormikButtonSeletor
                        name="templates"
                        options={templates.map((t) => ({
                          title: t.name,
                          id: t.id,
                        }))}
                      />
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={7}>
                  <DateRangeSelectorFormik name="dates" />
                </Grid>
              </Grid>
              <Box className={classes.buttonsHolder}>
                <div className={classes.cancelbuttonmargin}>
                  <StandardButton
                    title="Cancel"
                    onClick={() => {
                      resetForm();
                      onClose();
                    }}
                  />
                </div>
                <StandardButton
                  title="Import"
                  type="submit"
                  accent
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </Box>
            </Form>
          )}
        </Formik>
      </ModalWindow>
    </ModalContainer>
  );
};

export default ImportTemplateModal;
