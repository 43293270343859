import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  RouteProps,
} from "react-router-dom";
import Login from "../screens/Login";
import ResetPassword from "../screens/ForgotPassword";
import Templates from "../screens/Templates";
import TemplateBuilder from "../screens/TemplateBuilder";
import StaffScreen from "../screens/StaffScreen";
import StaffEditScreen from "../screens/StaffEditScreen";
import RosterScreen from "../screens/RosterScreen";
import { ThemeProvider } from "@material-ui/core/styles";
import mainTheme from "../themes/mainTheme";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import ShiftScreen from "../screens/ShiftScreen";
import { decode } from "jsonwebtoken";
import UserMonthlyRoster from "../screens/userView/UserMonthlyRoster";
import NavContainer from "../components/containers/NavContainer";
import UserRequestsScreen from "../screens/userView/UserRequestsScreen";
import UserSwapScreen from "../screens/userView/UserSwapScreen";
import ProgressBarGlobalProvider from "../components/bars/ProgressBarGlobal";
import SnackbarProviderCustomized from "../components/bars/SnackbarProviderCustomized";
import UserSelectionsContextProvider from "../context/userSelectionsProvider";
import SideNavUser from "../components/nav/SideNavUser";
import SideNavAdmin from "../components/nav/SideNavAdmin";
import UserDetailContextProvider from "../context/userDetailProvider";
import SettingsScreen from "../screens/adminView/settings";
import ScheduledSmsScreen from "../screens/adminView/scheduledSms";
import SentEmailsScreen from "../screens/adminView/ListEmail";
import { styledTheme } from "../themes/styledTheme";
import { QueryClient, QueryClientProvider } from "react-query";
import UserAccountScreen from "../screens/userView/UserAccountScreen";
import AuditHistoryScreen from "../screens/adminView/auditHistory";
import SwapOffersScreen from "../screens/adminView/swapOffersScreen";
import ChangePasswordScreen from "../screens/userView/ChangePassword";
import ReportScreen from "../screens/ReportScreen";
import UserRequestSwapScreen from "../screens/userView/UserRequestSwapScreen";

interface ProtectedRouteProps extends RouteProps {
  adminComponent: JSX.Element | null;
  userComponent: JSX.Element | null;
}

const ProtectedRoute = ({
  adminComponent,
  userComponent,
  ...rest
}: ProtectedRouteProps) => {
  const token: string | null = localStorage.getItem("token");
  const decoded = token ? decode(token, { complete: true }) : undefined;
  const userId = decoded ? (decoded as any).payload.bird : undefined;
  const isAdmin = decoded ? (decoded as any).payload.isa : false;
  const tenantId = decoded ? (decoded as any).payload.nest : undefined;

  if (userId && isAdmin) {
    return (
      <Route {...rest}>
        <NavContainer sideNav={<SideNavAdmin />}>
          {adminComponent || <></>}
        </NavContainer>
      </Route>
    );
  }
  if (userId && isAdmin === false) {
    return (
      <Route {...rest}>
        <NavContainer sideNav={<SideNavUser />}>
          {userComponent || <></>}
        </NavContainer>
      </Route>
    );
  }
  return <Redirect to="/login" />;
};

const queryClient = new QueryClient();

export default () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={mainTheme}>
        <StyledThemeProvider theme={styledTheme}>
          <SnackbarProviderCustomized>
            <ProgressBarGlobalProvider>
              <UserDetailContextProvider>
                <UserSelectionsContextProvider>
                  <BrowserRouter>
                    <Switch>
                      <Route exact path="/login" component={Login} />
                      <Route
                        exact
                        path="/forgot-password"
                        component={ResetPassword}
                      />
                      <Route
                        path="/forgot-password/:rkey"
                        component={ResetPassword}
                      />

                      <ProtectedRoute
                        exact
                        path="/"
                        adminComponent={<Redirect to="/roster" />}
                        userComponent={<UserMonthlyRoster />}
                      />

                      <ProtectedRoute
                        exact
                        path="/templates"
                        adminComponent={<Templates />}
                        userComponent={<Redirect to="/" />}
                      />

                      <ProtectedRoute
                        exact
                        path="/requests"
                        adminComponent={<Redirect to="/roster" />}
                        userComponent={<UserRequestsScreen />}
                      />

                      <ProtectedRoute
                        exact
                        path="/swap-offers"
                        adminComponent={<SwapOffersScreen />}
                        userComponent={<Redirect to="/" />}
                      />

                      <ProtectedRoute
                        exact
                        path="/swap"
                        adminComponent={<Redirect to="/roster" />}
                        userComponent={<UserSwapScreen />}
                      />

                      <ProtectedRoute
                        exact
                        path="/account"
                        adminComponent={<Redirect to="/roster" />}
                        userComponent={<UserAccountScreen />}
                      />

                      <ProtectedRoute
                        exact
                        path="/templates/:id"
                        adminComponent={<TemplateBuilder />}
                        userComponent={<Redirect to="/login" />}
                      />
                      <ProtectedRoute
                        exact
                        path="/staff"
                        adminComponent={<StaffScreen />}
                        userComponent={<Redirect to="/login" />}
                      />

                      <ProtectedRoute
                        exact
                        path="/staff/e/:id?"
                        adminComponent={<StaffEditScreen />}
                        userComponent={<Redirect to="/login" />}
                      />

                      <ProtectedRoute
                        path="/roster"
                        adminComponent={<RosterScreen />}
                        userComponent={<Redirect to="/login" />}
                      />

                      <ProtectedRoute
                        path="/shift/:shiftId?"
                        adminComponent={<ShiftScreen />}
                        userComponent={<Redirect to="/login" />}
                      />

                      <ProtectedRoute
                        path="/settings"
                        adminComponent={<SettingsScreen />}
                        userComponent={<Redirect to="/roster" />}
                      />

                      <ProtectedRoute
                        path="/sms"
                        adminComponent={<ScheduledSmsScreen />}
                        userComponent={<Redirect to="/roster" />}
                      />

                      <ProtectedRoute
                        path="/email"
                        adminComponent={<SentEmailsScreen />}
                        userComponent={<Redirect to="/roster" />}
                      />

                      <ProtectedRoute
                        path="/audit"
                        adminComponent={<AuditHistoryScreen />}
                        userComponent={<Redirect to="/roster" />}
                      />

                      <ProtectedRoute
                        path="/reports"
                        adminComponent={<ReportScreen />}
                        userComponent={<ReportScreen />}
                      />

                      <ProtectedRoute
                        path="/user/change-password"
                        adminComponent={<ChangePasswordScreen />}
                        userComponent={<ChangePasswordScreen />}
                      />

                      <ProtectedRoute
                        path="/request-swap"
                        adminComponent={<Redirect to="/roster" />}
                        userComponent={<UserRequestSwapScreen />}
                      />

                      <Route render={() => <div>404</div>} />
                    </Switch>
                  </BrowserRouter>
                </UserSelectionsContextProvider>
              </UserDetailContextProvider>
            </ProgressBarGlobalProvider>
          </SnackbarProviderCustomized>
        </StyledThemeProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
