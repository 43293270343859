import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
  },
  innerContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  highlighted: {
    transform: "scale(1.2)",
    WebkitBoxShadow: "0px 1px 4px 0px rgba(186,186,186,1)",
    MozBoxShadow: "0px 1px 4px 0px rgba(186,186,186,1)",
    boxShadow: "0px 1px 4px 0px rgba(186,186,186,1)",
  },
  color: {
    height: theme.typography.fontSize * 2.4,
    width: theme.typography.fontSize * 2.4,
    borderRadius: theme.shape.borderRadius,
    transition: "all 0.2s",
    margin: theme.spacing(0.5),
  },
}));

export default function ColorPickerPalette({
  name,
  colors,
}: {
  name: string;
  colors: Array<string>;
}) {
  const classes = useStyles();
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        {colors.map((c) => (
          <div
            onClick={() => setValue(c)}
            className={`${classes.color} ${
              c === value ? classes.highlighted : ""
            }`}
            key={c}
            style={{ backgroundColor: c }}
          />
        ))}
      </div>
    </div>
  );
}
