import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Tooltip, withStyles } from "@material-ui/core";
import { AuditResponseItem } from "../../../requests/audit";

const Td = styled.td`
  background-color: ${({ theme }) => theme.colors.background.paper};
  min-height: 20px;
  box-sizing: border-box;
  padding: 2px 4px;
`;

const Text = styled.span`
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: text;
`;

const HtmlTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

function AuditTableRow(props: AuditResponseItem) {
  return (
    <tr>
      <Td>
        <HtmlTooltip title={`ID: ${props.id}`} arrow>
          <Text>{props.id}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Task ID: ${props.taskId}`} arrow>
          <Text>{props.taskId}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Audit type: ${props.auditType.name}`} arrow>
          <Text>{props.auditType.name}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Source type: ${props.sourceType}`} arrow>
          <Text>{props.sourceType}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Source ID: ${props.sourceId}`} arrow>
          <Text>{props.sourceId}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Column name: ${props.columnName}`} arrow>
          <Text>{props.columnName}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`Old value: ${props.oldValue}`} arrow>
          <Text>{props.oldValue}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`New value: ${props.newValue}`} arrow>
          <Text>{props.newValue}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip title={`User name: ${props.user?.fullName}`} arrow>
          <Text>{props.user?.fullName}</Text>
        </HtmlTooltip>
      </Td>

      <Td>
        <HtmlTooltip
          title={`Created at: ${moment(props.createdAt).format(
            "YYYY/MM/DD HH:mm"
          )}`}
          arrow
        >
          <Text>{moment(props.createdAt).format("YYYY/MM/DD HH:mm")}</Text>
        </HtmlTooltip>
      </Td>
    </tr>
  );
}

export default AuditTableRow;
