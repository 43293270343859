import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useIsFetching } from "react-query";

const useStyles = makeStyles((theme) => {
  return {
    bar: {
      height: 3,
      position: "absolute",
      zIndex: 7777,
      width: "100%",
      background: theme.palette.primary.main,
      WebkitBoxShadow: "0px 0px 5px 0px rgba(97,202,255,1)",
      MozBoxShadow: "0px 0px 5px 0px rgba(97,202,255,1)",
      boxShadow: "0px 0px 5px 0px rgba(97,202,255,1)",
      opacity: 0,
    },
    animate: {
      animation: "$move 1s linear infinite",
    },
    "@keyframes move": {
      "0%": {
        // background: `linear-gradient(to right, ${theme.palette.primary.dark}, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
        opacity: 0.2,
      },
      "50%": {
        // background: `linear-gradient(to right, ${theme.palette.primary.light}, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
        opacity: 1,
      },
      "100%": {
        // background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.light}, ${theme.palette.primary.dark})`,
        opacity: 0.4,
      },
    },
  };
});

const ProgressBarGlobalContext = createContext({
  active: 0,
  barActivate: () => {},
  barStop: () => {},
});

export default function ProgressBarGlobalProvider({
  children,
}: {
  children: ReactNode;
}) {
  const fetching = useIsFetching();
  const [active, setActive] = useState<number>(0);
  const c = useStyles(active);

  const barActivate = useCallback(() => {
    setActive((active) => active + 1);
  }, []);

  const barStop = useCallback(() => {
    setActive((active) => (active > 0 ? active - 1 : 0));
  }, []);

  return (
    <ProgressBarGlobalContext.Provider value={{ active, barActivate, barStop }}>
      <div className={`${c.bar} ${active || fetching ? c.animate : ""}`} />
      {children}
    </ProgressBarGlobalContext.Provider>
  );
}

export function useProgressBar() {
  return useContext(ProgressBarGlobalContext);
}
