import { useEffect, useState } from "react";

interface UsePagination {
  count?: number;
  take: number;
  page?: number;
}
export default function usePagination(options: UsePagination) {
  const [take, setTake] = useState<number>(options.take);
  const [page, setPage] = useState<number>(options.page || 1);
  const [pages, setPages] = useState<number>(1);

  useEffect(() => {
    if (options.count) {
      const total = Math.ceil(options.count / take);
      setPages(total);
    }
  }, [options.count, take]);

  function handlePageChange(event: React.ChangeEvent<unknown>, value: number) {
    setPage(value);
  }

  return { handlePageChange, page, take, setTake, pages };
}
