import React, { useState, useEffect } from "react";
import { useField } from "formik";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  input: {
    borderRadius: theme.shape.borderRadius,
    border: ({ bordered }: { bordered: boolean }) => {
      return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
    },
    height: "100%",
    width: "100%",
  },
}));

export default function TextInputBorderedFormik({
  name,
  placeholder,
}: {
  name: string;
  placeholder?: string;
}) {
  const [field, meta] = useField(name);
  const [bordered, setbordered] = useState<boolean>(true);
  const [blured, setblured] = useState<boolean>(meta.value ? true : false);
  const c = useStyles({ bordered });

  useEffect(() => {
    if (meta.initialValue) {
      setbordered(false);
    }
  }, [meta.initialValue]);

  useEffect(() => {
    if (blured) {
      if (meta.value) {
        setbordered(false);
      } else {
        setbordered(true);
        setblured(false);
      }
    } else {
      if (!meta.value) {
        setbordered(true);
      }
    }
  }, [meta.value, blured]);

  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    field.onBlur(e);
    setblured(true);
  };

  return (
    <input
      placeholder={placeholder}
      className={c.input}
      autoComplete="off"
      {...field}
      onBlur={blurHandler}
    />
  );
}
