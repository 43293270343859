import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div<{ active: boolean }>`
  cursor: pointer;
  width: 50px;
  min-width: 50px;
  height: 30px;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.deepSkyBlue : theme.colors.paleGrey};
  border-radius: 15px;
  align-items: center;
  box-sizing: border-box;
  position: relative;
  transition: all 0.2s ease-out;
  & > div {
    background-color: ${({ active, theme }) =>
      active ? theme.colors.base.white : theme.colors.greyish};
    left: ${({ active }) => (active ? "22px" : "2px")};
    box-shadow: ${({ active }) =>
      active
        ? "0px 1px 3px 0px rgba(0,0,0,0.4)"
        : "0px 0px 0px 0px rgba(0,0,0,0.4)"};
  }
`;

const Circle = styled.div`
  position: absolute;
  top: 2px;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  transition: all 0.2s ease-out;
`;

interface SwitchProps {
  active?: boolean;
  onToggle?: (state: boolean) => void;
}
/**
 * this component can be controlled or uncontrolled
 * if there is no active prop - uncontrolled
 */
export default function Switch({ active, onToggle }: SwitchProps) {
  const [on, setOn] = useState<boolean>(false);

  function onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    if (active !== undefined && onToggle) {
      onToggle(!active);
    } else {
      setOn((previous) => {
        if (onToggle) {
          onToggle(!previous);
        }
        return !previous;
      });
    }
  }

  return (
    <Container active={active === undefined ? on : active} onClick={onClick}>
      <Circle />
    </Container>
  );
}
