import React, { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import CalendarBase, {
  CalendarBaseDay,
} from "../../../../../components/calendars/CalendarBase";
import DayCell from "./DayCell";
import moment, { Moment } from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "./styles";
import { useSnackbar } from "notistack";
import styled from "styled-components";
import { DateNavText,DateNavButtonText,DateNavButtonRight,DateNavButtonLeft } from "../../../../../components/Buttons/DateNavigationButtons";
import StandardButton from "../../../../../components/Buttons/StandardButton";
import { useProgressBar } from "../../../../../components/bars/ProgressBarGlobal";
import { useUserSelections } from "../../../../../context/userSelectionsProvider";
import { getRosterStatuses, postPublishMonthRoster, postPublishMonthRosterCheck } from "../../../../RosterScreen/requests";

const DateHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const DateTextHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 200px;
`;

const RosterMonthView = () => {
  const { year, month } = useParams<{ year: string; month: string }>();
  const history = useHistory();
  const classes = useStyles();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRosters } = useUserSelections();

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const [monthToShow, setMonthToShow] = useState<number | undefined>(undefined);
  const [yearToShow, setYearToShow] = useState<number | undefined>(undefined);

  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );

  const [dayRosters, setDayRosters] = useState<{
    [key: number]: {
      status: "worked" | "unpublished" | "published";
      rosters: Array<{ id: number; color: string; name: string }>;
    };
  }>({});

  const [buttonPublishVisible, setButtonPublishVisible] = useState<boolean>(
    false
  );

  useEffect(() => {
    const isUnpublished = Object.entries(dayRosters).find(
      ([key, value]) => value.status === "unpublished"
    );
    if (isUnpublished) {
      setButtonPublishVisible(true);
    } else {
      setButtonPublishVisible(false);
    }
  }, [dayRosters]);

  const retrieveRosterStatuses = useCallback(async () => {
    if (selectedRosters.length && yearToShow && monthToShow) {
      try {
        const result = await getRosterStatuses({
          rosterIds: selectedRosters.map((r) => r.id),
          year: yearToShow,
          month: monthToShow,
        });
        setDayRosters(result);
      } catch (err) {
        console.error(err);
      }
    }
  }, [monthToShow, yearToShow, selectedRosters]);

  useEffect(() => {
    retrieveRosterStatuses();
  }, [retrieveRosterStatuses]);

  useEffect(() => {
    if (yearToShow && monthToShow) {
      setCurrentMoment(moment(`${yearToShow}-${monthToShow}`, "YYYY-MM"));
    }
  }, [yearToShow, monthToShow]);

  useEffect(() => {
    const m = Number(month);
    const y = Number(year);
    if (year && y && month && m) {
      if (m && m > 0 && m <= 12) setMonthToShow(m);
      if (y && y >= 2000 && y <= 2100) setYearToShow(y);
    } else {
      const d = new Date();
      setMonthToShow(d.getMonth() + 1);
      setYearToShow(d.getFullYear());
    }
  }, [month, year]);

  const publishRoster = useCallback(async () => {
    if (yearToShow && monthToShow) {
      try {
        barActivate();
        const { code } = await postPublishMonthRoster(
          yearToShow,
          monthToShow,
          selectedRosters.map((r) => r.id)
        );
        if (code === 0) {
          enqueueSnackbar("No shifts to publish", { variant: "warning" });
        } else {
          enqueueSnackbar("Roster published", { variant: "success" });
        }
        retrieveRosterStatuses();
        setDialogOpen(false);
      } catch (err) {
        if (err.response) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Unable to publish roster", { variant: "error" });
        }
      } finally {
        barStop();
      }
    } else {
      enqueueSnackbar("No year / month selected", { variant: "warning" });
    }
  }, [
    yearToShow,
    monthToShow,
    enqueueSnackbar,
    barActivate,
    barStop,
    selectedRosters,
    retrieveRosterStatuses,
  ]);

  const checkRosterPublish = useCallback(async () => {
    if (yearToShow && monthToShow) {
      try {
        barActivate();
        const { unassignedShifts, code } = await postPublishMonthRosterCheck(
          yearToShow,
          monthToShow,
          selectedRosters.map((r) => r.id)
        );
        if (code === 0) {
          enqueueSnackbar(
            "There are no shifts to publish in selected roster(s)",
            { variant: "warning" }
          );
          return;
        } else {
          if (unassignedShifts.length) {
            // OPEN DIALOG
            setDialogOpen(true);
          } else {
            // PUBLISH SHIFT
            publishRoster();
          }
        }
      } catch (err) {
        if (err.response) {
          enqueueSnackbar(err.response.data.message, { variant: "error" });
        } else {
          enqueueSnackbar("Unable to check the roster(s)", {
            variant: "error",
          });
        }
      } finally {
        barStop();
      }
    } else {
      enqueueSnackbar("No year / month selected", { variant: "warning" });
    }
  }, [
    yearToShow,
    monthToShow,
    enqueueSnackbar,
    barActivate,
    barStop,
    selectedRosters,
    publishRoster,
  ]);

  const navToDay = (d: CalendarBaseDay) => {
    history.push(`/request-swap/year/${d.year}/month/${d.month}/day/${d.day}`);
  };

  const navToMonth = ({
    year,
    month,
  }: {
    year: number | string;
    month: number | string;
  }) => {
    history.push(`/request-swap/year/${year}/month/${month}`);
  };

  const nextMonth = (
    year: number,
    month: number
  ): { year: number; month: number } => {
    let y = year;
    let m = month + 1;

    if (month === 12) {
      m = 1;
      y = year + 1;
    }

    return { year: y, month: m };
  };

  const prevMonth = (
    year: number,
    month: number
  ): { year: number; month: number } => {
    let y = year;
    let m = month - 1;

    if (month === 1) {
      m = 12;
      y = year - 1;
    }

    return { year: y, month: m };
  };

  if (!yearToShow || !monthToShow) {
    return null;
  }

  return (
    <div>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Publish Roster?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Some shifts for selected rosters are not assigned. These shifts will
            become available after roster publish. Confirm that you want to
            proceed
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <StandardButton title="Close" onClick={() => setDialogOpen(false)} />
          <StandardButton title="Publish" accent onClick={publishRoster} />
        </DialogActions>
      </Dialog>

      {currentMoment && (
        <DateHolder>
          <DateNavButtonLeft
            onClick={() => navToMonth(prevMonth(yearToShow, monthToShow))}
          />
          <DateTextHolder>
            <DateNavButtonText
              onClick={() =>
                history.push(`/request-swap/year/${currentMoment.format("YYYY")}`)
              }
            >
              {currentMoment.format("YYYY")}
            </DateNavButtonText>
            <DateNavText>{currentMoment.format("MMMM")}</DateNavText>
          </DateTextHolder>
          <DateNavButtonRight
            onClick={() => navToMonth(nextMonth(yearToShow, monthToShow))}
          />
        </DateHolder>
      )}

      <CalendarBase
        month={monthToShow}
        year={yearToShow}
        cellComponent={(d) => (
          <DayCell
            day={d}
            onClick={navToDay}
            items={
              dayRosters[d.day]
                ? dayRosters[d.day].rosters.map((e) => ({
                    id: e.id,
                    title: e.name.split("")[0],
                    color: e.color,
                  }))
                : []
            }
            status={dayRosters[d.day] ? dayRosters[d.day].status : undefined}
          />
        )}
      />
      {buttonPublishVisible && currentMoment && (
        <div className={classes.postRosterButtonHolder}>
          <StandardButton
            title={`Publish ${currentMoment.format("MMMM")} Roster`}
            type="button"
            onClick={checkRosterPublish}
          />
        </div>
      )}
    </div>
  );
};

export default RosterMonthView;
