import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import jwt from "jsonwebtoken";
import { API_URL } from "../ENDPOINTS";
import { AuthorizationError } from "../errors/AuthorizationError";

if (!API_URL) {
  console.warn("No API_URL environment variable set. Refer to the README");
}

const authorisedRequester = axios.create({
  baseURL: API_URL,
  withCredentials: true,
});

let refreshTokenPromise: Promise<any> | undefined = undefined;

async function doRefreshTokens(
  accessToken: string,
  refreshToken: string
): Promise<{ accessToken: string; refreshToken: string }> {
  const { at, rt } = await wrappedUnauth.post(`/auth/token`, {
    at: accessToken,
    rt: refreshToken,
  });

  if (!at || !rt) {
    throw new Error("Unauthorized");
  }

  return { accessToken: at, refreshToken: rt };
}

// Add interceptor for adding authorization header to the request
authorisedRequester.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    let token = localStorage.getItem("token");
    if (!token) {
      document.location.replace("/login");
      throw new AuthorizationError("Not logged in");
    }
    const decoded = jwt.decode(token);
    if (!decoded || typeof decoded === "string") {
      document.location.replace("/login");
      throw new AuthorizationError("Not logged in. decoding failed");
    }
    const expiryDate = new Date(decoded.exp * 1000 - 60000);
    const rt = localStorage.getItem("rt");

    if (new Date() > expiryDate && rt) {
      if (refreshTokenPromise) {
        const { accessToken, refreshToken } = await refreshTokenPromise;
        token = accessToken;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("rt", refreshToken);
      } else {
        refreshTokenPromise = doRefreshTokens(token, rt);
        const { accessToken, refreshToken } = await refreshTokenPromise;
        refreshTokenPromise = undefined;
        token = accessToken;
        localStorage.setItem("token", accessToken);
        localStorage.setItem("rt", refreshToken);
      }
    }

    config.headers.Authorization = `Bearer ${token}`;

    return config;
  },
  undefined
);

// Add interceptor for unauthorized responses (which reset the token)
authorisedRequester.interceptors.response.use(
  (response) => {
    // Set the token in Local Storage to the one in the headers
    if (response.headers.nt) {
      localStorage.setItem("token", response.headers.nt);
    }

    if (response.headers.rt) {
      localStorage.setItem("rt", response.headers.rt);
    }
    return response;
  },
  (err) => {
    // Ensure this happened at the response part of the process (i.e. not a network error)
    if (err.response) {
      const response = (err as AxiosError).response;

      if (response) {
        // Set the token in Local Storage to the one in the headers
        if (response.headers.nt) {
          localStorage.setItem("token", response.headers.nt);
        }
        if (response.headers.rt) {
          localStorage.setItem("rt", response.headers.rt);
        }

        // Remove the token on 401 statuses
        if (response.status === 401) {
          localStorage.clear();
          document.location.replace("/login");
        }
      }
    }

    return Promise.reject(err);
  }
);

const unAuthorisedRequester = axios.create({
  baseURL: API_URL,
});

// Add interceptor for unauthorized responses (which reset the token)
unAuthorisedRequester.interceptors.response.use((response) => {
  // Set the token in Local Storage to the one in the headers
  if (response.headers.nt) {
    localStorage.setItem("token", response.headers.nt);
  }
  if (response.headers.rt) {
    localStorage.setItem("rt", response.headers.rt);
  }

  return response;
});

/**
 * Wrap an Axios instance to provide better typing.
 *
 * Request type is now fully typed in the requests that use them, so types can be better enforced with function type parameters.
 * These functions also directly return the response data, since most of the header interactions is not necessary for the logic of the application.
 * In case direct access is needed, the Axios instance itself is available
 * @param instance Axios instance to wrap
 */
function wrapInstance(instance: AxiosInstance) {
  return {
    /**
     * The real Axios instance
     */
    instance,
    async get<ResponseType>(url: string, config?: AxiosRequestConfig) {
      const response = await instance.get<ResponseType>(url, config);
      return response.data;
    },
    async delete<ResponseType>(url: string, config?: AxiosRequestConfig) {
      const response = await instance.delete<ResponseType>(url, config);
      return response.data;
    },
    async head<ResponseType>(url: string, config?: AxiosRequestConfig) {
      const response = await instance.head<ResponseType>(url, config);
      return response.data;
    },
    async post<RequestType, ResponseType>(
      url: string,
      data: RequestType,
      config?: AxiosRequestConfig
    ) {
      const response = await instance.post<ResponseType>(url, data, config);
      return response.data;
    },
    async put<RequestType, ResponseType>(
      url: string,
      data: RequestType,
      config?: AxiosRequestConfig
    ) {
      const response = await instance.put<ResponseType>(url, data, config);
      return response.data;
    },
    async patch<RequestType, ResponseType>(
      url: string,
      data: RequestType,
      config?: AxiosRequestConfig
    ) {
      const response = await instance.patch<ResponseType>(url, data, config);
      return response.data;
    },
  };
}

const wrappedAuth = wrapInstance(authorisedRequester);
const wrappedUnauth = wrapInstance(unAuthorisedRequester);

export {
  wrappedAuth as authorizedRequest,
  wrappedUnauth as unauthorizedRequest,
};
