import React, { ReactNode } from "react";
import { Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "45em",
    height: "fit-content",
    maxWidth: "85vw",
    boxSizing: "border-box",
    padding: "1em 1.5em",
  },
}));

const ModalWindow = ({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) => {
  const classes = useStyles();
  return (
    <Paper className={`${classes.container} ${className ? className : ""}`}>
      {children}
    </Paper>
  );
};

export default ModalWindow;
