import React, { useCallback, useRef } from "react";
import RangeCaledar, {
  OnRangeCalendarSelectedProps,
} from "../../calendars/RangeCalendar";
import { useField } from "formik";

interface DateRangeSelectorFormikProps {
  name: string;
}

export default function DateRangeSelectorFormik({
  name,
}: DateRangeSelectorFormikProps) {
  const [, , helpers] = useField(name);
  const { setValue } = helpers;
  const setValueRef = useRef(setValue);
  const handleSelection = useCallback(
    (o: OnRangeCalendarSelectedProps | undefined) => {
      setValueRef.current(o);
    },
    []
  );

  return <RangeCaledar onSelected={handleSelection} />;
}
