import { unauthorizedRequest } from "../../httprequests";

interface DoCheckKeyResponse {}
export interface DoCheckKeyReturn {}

// export type DoResetPasswordParam = string;
interface DoResetPasswordRequest {
  email: string;
}
interface DoResetPasswordResponse {}
export interface DoResetPasswordReturn {}

interface DoNewPasswordRequest {
  key: string;
  password: string;
}
interface DoNewPasswordResponse {}
export interface DoRNewPasswordReturn {}

export const requestCheckKey = (key: string) => {
  return unauthorizedRequest.get<DoCheckKeyResponse>(`/auth/checkkey/${key}`);
};

export const requestResetPassword = (email: string) => {
  return unauthorizedRequest.post<
    DoResetPasswordRequest,
    DoResetPasswordResponse
  >("/auth/resetpassword", { email });
};

export const requestNewPassword = (key: string, password: string) => {
  return unauthorizedRequest.post<DoNewPasswordRequest, DoNewPasswordResponse>(
    "/auth/newpassword",
    { key, password }
  );
};
