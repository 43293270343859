import { GetTemplateShift } from "./requests";
import {
  calculateTime,
  convertTimeToHHmm,
  calculateDuration,
} from "../../utils/time";
import { Shift } from ".";
import isEqual from "lodash.isequal";

export const convertResponseShifts = (
  shiftsFromResponse: Array<GetTemplateShift>
) => {
  return shiftsFromResponse.map((element) => ({
    id: element.id,
    name: element.name,
    oncall: element.onCall,
    start: convertTimeToHHmm(element.startTime),
    end: calculateTime(element.startTime, element.duration),
    duration: element.duration,
    monday: element.monday,
    tuesday: element.tuesday,
    wednesday: element.wednesday,
    thursday: element.thursday,
    friday: element.friday,
    saturday: element.saturday,
    sunday: element.sunday,
  }));
};

export const addShiftDuration = (shifts: Array<Shift>) => {
  return shifts.map((shift: Shift) => {
    shift.duration = calculateDuration(shift.start, shift.end);
    return shift;
  });
};

/**
 * @param shifts shifts from form's onSubmit
 */
export const filterNewTemplateShifts = (
  shifts: Array<Shift> = []
): Array<Shift> => {
  return shifts.filter((shift) => !shift.id);
};

/**
 * @param original untouched shifts which were used as initial values for the form
 * @param submitted shifts from form's onSubmit
 */
export const filterDeletedTemplateShifts = (
  original: Array<Shift> = [],
  submitted: Array<Shift> = []
): Array<number> => {
  const originalIDs = original
    .filter((shift) => shift.id)
    .map((shift) => shift.id);
  const submittedIDs = submitted
    .filter((shift) => shift.id)
    .map((shift) => shift.id);

  return originalIDs.filter((id) => !submittedIDs.includes(id)) as Array<
    number
  >;
};

/**
 * @param original untouched shifts which were used as initial values for the form
 * @param submitted shifts from form's onSubmit
 */
export const filterEditedTemplateShifts = (
  original: Array<Shift> = [],
  submitted: Array<Shift> = []
) => {
  const shifts = submitted.filter((shift) => shift.id);
  const editedShifts = shifts.filter(
    (shift) =>
      !isEqual(
        shift,
        original.find((s) => s.id === shift.id)
      )
  );
  return editedShifts;
};
