import React, { useState, useEffect, useCallback, Fragment, useMemo } from "react";
import styles from "./styles.module.css";
import styled from "styled-components";
import { Formik, Form, Field } from "formik";
import StandardButton from "../../components/Buttons/StandardButton";
import { emailRegex } from "../../GLOBALS";
import ErrorBlockThin from "../../components/ErrorBlockThin";
import { ReportTo, User, UserType } from "../StaffScreen";
import { requestPostUser, requestGetUser, requestPutUser } from "./requests";
import { useParams, useHistory } from "react-router-dom";
import {
  Box,
  Grid,
  Paper,
  InputLabel,
  Typography,
  Select,
  Chip,
  Divider,
  MenuItem
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextInputFormik from "../../components/inputs/TextInputFormik";
import CheckBox from "../../components/inputs/FormikCheckbox";
import SelectFormik from "../../components/inputs/SelectFormik";
import MultipleSelectFormik from "../../components/inputs/Selectors/MultipleSelectFormik";
import { requestGetUserTypes, requestGetUsers } from "../StaffScreen/requests";
import { RWrapper } from "../../components/layout/RWrapper";
import { useSnackbar } from "notistack";
import { useProgressBar } from "../../components/bars/ProgressBarGlobal";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { deleteUser, putRestoreUser } from "../../requests/user";
import moment from "moment";
import OptionsButton from "../../components/Buttons/OptionsButton";
import { string } from "yup";

export interface FormUser
  extends Omit<User, "mobileCode" | "mobileNumber" | "fullName" | "userType" | "reportTo"> {
  mobileCode?: number | "";
  mobileNumber?: number | "";
  userType?: number;
  reportTo?: string;
}

interface ValidationProps {
  title: string;
  firstName: string;
  lastName: string;
  email: string;
  isAdministrator: boolean;
  userType?: number;
  mobileCode?: number | "";
  mobileNumber?: number | "";
  reportTo?: string ;
}

interface ValidationErrorsProps {
  title?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  isAdministrator?: string;
  userType?: string;
  mobileCode?: string;
  mobileNumber?: string;
  reportTo?: string;
}

interface ValidationTouchedProps {
  title?: boolean;
  firstName?: boolean;
  lastName?: boolean;
  email?: boolean;
  isAdministrator?: boolean;
  userType?: boolean;
  mobileCode?: boolean;
  mobileNumber?: boolean;
  reportTo?: boolean;
}

const DeleteButton = styled(StandardButton)`
  color: ${({ theme }) => theme.colors.deepRed};
`;

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.fontSize,
    marginBottom: "0.3em",
  },
  margincontainer: {
    marginTop: "0.5em",
  },
  spacer1: {
    marginRight: 5,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  control: {
    minWidth: 320,
    border: "0.5px solid #9e9e9e",
    borderBottom: "0.5px solid #9e9e9e",
  },
}));

const validation = ({
  title,
  firstName,
  lastName,
  email,
  userType,
  isAdministrator,
  mobileCode,
  mobileNumber,
  reportTo,
}: ValidationProps) => {
  let errors: ValidationErrorsProps = {};
  if (!title) errors.title = "Title required";
  if (!firstName) errors.firstName = "First Name required";
  if (!lastName) errors.lastName = "Last Name required";
  if (!email) { errors.email = "Email required"; }
  else if (!emailRegex.test(email)) errors.email = "Email doesn't look right";
  if (!isAdministrator && !userType) errors.userType = "User type is required";
  if (!reportTo) errors.reportTo = "Reports To is required";
  if (mobileCode && !mobileNumber)
    errors.mobileNumber = "Please enter mobile number";
  if (mobileNumber && !mobileCode)
    errors.mobileCode = "Please enter country code";
  return errors;
};

export default () => {
  const classes = useStyles();
  const { id } = useParams<{ id?: string }>();
  const history = useHistory();

  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();

  const [reportTo, setreportTo] = useState<Array<User>>([]);
  const [user, setuser] = useState<User | undefined>(undefined);
  const [formError, setFormError] = useState<string>('');
  const [userTypes, setuserTypes] = useState<Array<UserType>>([]);
    
  const getUser = useCallback(
    async (userID: string) => {
      try {
        barActivate();
        const u = await requestGetUser(userID);
        setuser(u);
      } catch (error) {
        history.push("/staff");
        console.error(error);
      } finally {
        barStop();
      }
    },
    [history]
  );

  useEffect(() => {
    if (id) {
      getUser(id);

    }
  }, [id, getUser]);

  useEffect(() => {
    _getUserTypes();
  }, []);

  useEffect(() => {
    retrieveUsers();
  }, []);

  const retrieveUsers = useCallback(
    async (reportTo?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetUsers(reportTo);
        const activeUsers = ut.filter((u) => u.deletedAt === null)
        setreportTo(activeUsers);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );

  const _getUserTypes = async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setuserTypes(ut);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to get user types. Try again later", {
        variant: "error",
      });
    } finally {
      barStop();
    }
  };

  const submitUser = async (userDetails: FormUser) => {
    try {
      barActivate();
      const check = await validation(userDetails)
      if (check && check.firstName) { setFormError(check.firstName); return; }
      if (check && check.lastName) { setFormError(check.lastName); return; }
      if (check && check.email) { setFormError(check.email); return; }
      if (check && check.userType) { setFormError(check.userType); return; }
      if (check && check.mobileNumber) { setFormError(check.mobileNumber); return; }
      if (check && check.mobileCode) { setFormError(check.mobileCode); return; }
      if (user && user.id === Number(id)) {
        await requestPutUser({
          ...userDetails,
          id: user.id,
          mobileCode: userDetails.mobileCode || undefined,
          mobileNumber: userDetails.mobileNumber || undefined,
          
        });  
      } else {
        await requestPostUser({
          ...userDetails,
          mobileCode: userDetails.mobileCode || undefined,
          mobileNumber: userDetails.mobileNumber || undefined,
        });
      }
      
      history.push("/staff");
      enqueueSnackbar("Saved", { variant: "success" });
    } catch (error) {
      if (error.response && error.response.status === 409) {
        enqueueSnackbar("User with such email exists", { variant: "warning" });
      } else {
        console.error(error);
        enqueueSnackbar("Submit user failed", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const { mutate: deactivateUser, isLoading: isDeactivating } = useMutation<
    unknown,
    AxiosError,
    number
  >((id) =>
    deleteUser(id)
    , {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to deactivate the user`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        if (id) {
          getUser(id)
            ;
        }
        enqueueSnackbar(`Deactivated`, {
          variant: "success",
        });
      },
    });

  const { mutate: activateUser, isLoading: isActivating } = useMutation<
    unknown,
    AxiosError,
    number
  >((id) => putRestoreUser(id), {
    onError: (err) => {
      enqueueSnackbar(
        err.response && err.response.data.message
          ? err.response.data.message
          : `Unable to activate the user`,
        { variant: "error" }
      );
    },
    onSuccess: async () => {
      if (id) {
        getUser(id);
      }
      enqueueSnackbar(`Activated`, {
        variant: "success",
      });
    },
  });
  const cancelForm = () => {
    history.push("/staff")
  }
  return (
    <Formik
      enableReinitialize
      initialValues={useMemo<FormUser>(()=>{
        return {
          title: user && user.title ? user.title : "",
          firstName: user && user.firstName ? user.firstName : "",
          lastName: user && user.lastName ? user.lastName : "",
          email: user && user.email ? user.email : "",
          isAdministrator:
            user && user.isAdministrator ? user.isAdministrator : false,
          userType: user && user.userType ? user.userType.id : 0,
          mobileCode: user && user.mobileCode ? user.mobileCode : "",
          mobileNumber: user && user.mobileNumber ? user.mobileNumber : "",
          reportTo: user?.reportTo,
        }
      },[user])}
      onSubmit={submitUser}
    >
      {({ isValid, dirty, errors, isSubmitting, touched, resetForm, setFieldValue }) => (
        <Form noValidate autoComplete="off">
          <RWrapper>
            <Typography
              variant="h6"
              color="textPrimary"
              className={classes.margincontainer}
            >
              {id ? "Edit Staff Member" : "New Staff Member"}
            </Typography>
            <Divider />
            <Paper className={styles.paper} elevation={1}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    Title
                  </InputLabel>
                  <Field component={TextInputFormik} name="title" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    First Name
                  </InputLabel>
                  <Field component={TextInputFormik} name="firstName" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    Last Name
                  </InputLabel>
                  <Field component={TextInputFormik} name="lastName" />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    Email
                  </InputLabel>
                  <Field component={TextInputFormik} name="email" />
                </Grid>
                <Grid item container xs={12} sm={6} md={6} lg={3}>
                  <Grid item xs={4}>
                    <InputLabel color="primary" className={classes.label}>
                      Code
                    </InputLabel>
                    <Field
                      component={SelectFormik}
                      name="mobileCode"
                      options={[
                        { value: 64, title: "+64" },
                        { value: 61, title: "+61" },
                      ]}
                    />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={7}>
                    <InputLabel color="primary" className={classes.label}>
                      Mobile Number
                    </InputLabel>
                    <Field
                      component={TextInputFormik}
                      name="mobileNumber"
                      type="number"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    User Type
                  </InputLabel>
                  <Field
                    component={SelectFormik}
                    name="userType"
                    options={userTypes.map((e) => ({
                      value: e.id,
                      title: e.name,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    Report To
                  </InputLabel>
                  <Field
                    component={MultipleSelectFormik}
                    name="reportTo"
                    options={reportTo.map((e) => ({
                        value: e.id,
                        fullName: e.fullName,
                    }))}
                    onSelectedValueChange={(rosterId: any) => {
                      setFieldValue('reportTo',rosterId)
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={3}>
                  <InputLabel color="primary" className={classes.label}>
                    Administrator
                  </InputLabel>
                  <Field component={CheckBox} name="isAdministrator" />
                </Grid>
                {user?.id ? (
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    {user?.deletedAt ? (
                      <Fragment>
                        <InputLabel color="primary" className={classes.label}>
                          Deactivated at:
                          {moment(user.deletedAt).format("YYYY/MM/DD HH:mm")}
                        </InputLabel>
                        <StandardButton
                          type="button"
                          title="Activate"
                          disabled={isActivating}
                          onClick={() => activateUser(user.id as number)}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <InputLabel color="primary" className={classes.label}>
                          Active
                        </InputLabel>
                        <DeleteButton
                          type="button"
                          title="Deactivate"
                          disabled={isDeactivating}
                          onClick={() => deactivateUser(user.id as number)}
                        />
                      </Fragment>
                    )}
                  </Grid>
                ) : null}
              </Grid>

              <Box className={classes.margincontainer}>
                <ErrorBlockThin message={formError} />
              </Box>
            </Paper>
            <Box className={classes.margincontainer}>
              <Grid container spacing={1} justify="flex-end">
                <Grid item>
                  <StandardButton title="Cancel" onClick={() => cancelForm()} />
                </Grid>
                <Grid item>
                  <StandardButton title="Reset" onClick={() => resetForm()} />
                </Grid>
                <Grid item>
                  <StandardButton
                    title="Submit"
                    type="submit"
                    accent

                  />
                </Grid>
              </Grid>
            </Box>
          </RWrapper>
        </Form>
      )}
    </Formik>
  );
};