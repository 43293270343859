import React, {
  useRef,
  useCallback,
  useState,
  Fragment,
  useEffect,
} from "react";
import TimePicker, { TimePickerReturn } from "./TimePicker";
import { makeStyles } from "@material-ui/core/styles";
import { Schedule } from "@material-ui/icons";
import { useField } from "formik";
import ModalContainer from "../../modals/ModalContainer";
import ModalWindow from "../../modals/ModalWindow";
import { Typography } from "@material-ui/core";
import StandardButton from "../../Buttons/StandardButton";

interface TimePickerBorderedFormikProps {
  name: string;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flex: 1,
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
    },
    wrapper: {},
    title: {
      width: "100%",
      textAlign: "center",
      marginBottom: theme.spacing(2),
    },
    buttonHolder: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      marginTop: theme.spacing(2),
    },
    input: {
      borderRadius: theme.shape.borderRadius,
      border: ({ bordered }: { bordered: boolean }) => {
        return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
      },
      height: "100%",
      flex: 1,
    },
    icon: {
      display: "flex",
      alignItems: "center",
      marginLeft: theme.spacing(1),
      cursor: "pointer",
      borderRadius: theme.shape.borderRadius,
      transition: "background-color 0.2s ease-out",
      "&:hover": {
        backgroundColor: theme.palette.grey[200],
      },
    },
  };
});

export default function TimePickerBorderedFormik({
  name,
}: TimePickerBorderedFormikProps) {
  const [modalOpen, setmodalOpen] = useState<boolean>(false);
  const [field, meta, helpers] = useField(name);
  const { setValue } = helpers;
  const setValueRef = useRef(setValue);

  const c = useStyles({ bordered: meta.value ? false : true });

  const [inputValue, setinputValue] = useState<string>("");

  useEffect(() => {
    setinputValue(meta.value);
  }, [meta.value]);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setinputValue(event.target.value);
    if (!event.target.value.length) {
      setValueRef.current(undefined);
    } else if (
      event.target.value.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/)
    ) {
      setValueRef.current(event.target.value);
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (meta.value !== inputValue) {
      setinputValue(meta.value ?? "");
    }
  };

  const handleSelect = useCallback((v: TimePickerReturn) => {
    const timeString = `${v.hour
      .toString()
      .padStart(2, "0")}:${v.minute.toString().padStart(2, "0")}`;
    setValueRef.current(timeString);
    setinputValue(timeString);
  }, []);

  return (
    <Fragment>
      <div className={c.container}>
        <input
          autoComplete="off"
          className={c.input}
          {...field}
          placeholder="HH:MM"
          onChange={onInputChange}
          value={inputValue}
          onBlur={handleBlur}
        />
        <div onClick={() => setmodalOpen(true)} className={c.icon}>
          <Schedule color="action" />
        </div>
      </div>

      <ModalContainer open={modalOpen} onClose={() => setmodalOpen(false)}>
        <ModalWindow className={c.wrapper}>
          <Typography variant="h5" color="textSecondary" className={c.title}>
            {meta.value || "Pick a Time"}
          </Typography>
          <TimePicker onSelected={handleSelect} />
          <div className={c.buttonHolder}>
            {/* <StandardButton
          title="Clear"
          onClick={() => {
            handleSelect(undefined);
          }}
        /> */}
            <StandardButton
              title="Done"
              accent
              onClick={() => setmodalOpen(false)}
            />
          </div>
        </ModalWindow>
      </ModalContainer>
    </Fragment>
  );
}
