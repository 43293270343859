import React from "react";
import styled from "styled-components";
import StandardButton from "../../../components/Buttons/StandardButton";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { AxiosError } from "axios";
import { postImage } from "../../../requests/settings";
import { useSnackbar } from "notistack";
import { Box, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { getUserDetails } from "../../../requests/user";
import { useProgressBar } from "../../../components/bars/ProgressBarGlobal";
import { PUBLIC_API_URL } from "../../../ENDPOINTS";

const SectionContainer = styled.div`
  padding: ${({ theme }) => theme.gap[1]};
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  box-sizing: border-box;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
  display: flex;
  align-items: center;
`;

interface FormInputs {
  offset: string;
}

function TenantLogoForm() {
  const [selectedImage, setSelectedImage] = React.useState<any>();
  const [imageUrl, setImageUrl] = React.useState<any>();
  const [userCompanyLogo, setUserCompanyLogo] = React.useState<any>();
  const [userCompanyName, setUserCompanyName] = React.useState<any>();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  React.useEffect(() => {
    if (selectedImage) {
      setImageUrl(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  const history = useHistory();
  const [userID, setUserID] = React.useState<any>();

  const getUser = React.useCallback(async () => {
    try {
      barActivate();
      const u = await getUserDetails();
      setUserID(u?.tenants![0].id);
      setUserCompanyLogo("");
      if (u?.tenants![0].companyLogo) {
        setUserCompanyLogo(u?.tenants![0].companyLogo);
      }
      setUserCompanyName(u?.tenants![0].name);
    } catch (error) {
      console.error(error);
    } finally {
      barStop();
    }
  }, [history]);
  React.useEffect(() => {
    getUser();
  }, [getUser]);

  const { handleSubmit, errors } = useForm<FormInputs>();

  const { mutate: imageUpload } = useMutation<unknown, AxiosError, any>(
    ({ userID, formData }) => postImage(userID, formData),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to Uploaded Image`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        getUser();
        enqueueSnackbar(`saved Image`, {
          variant: "success",
        });
      },
    }
  );

  const onSubmitHan = async (e: any) => {
    const formData = new FormData();
    formData.append("myImage", selectedImage);
    imageUpload({ userID, formData });
    getUser();
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHan)} encType="multipart/form-data">
      <SectionContainer>
        <Title>Tenant Logo</Title>
        <input
          accept="image/*"
          type="file"
          id="select-image"
          onChange={(e) => setSelectedImage(e.target.files![0])}
        />
        <StandardButton type="submit" title="Save" accent />
      </SectionContainer>
      <Box mt={2} textAlign="center">
        <img
          src={userCompanyLogo && `${PUBLIC_API_URL}/logo/${userCompanyLogo}`}
          alt={userCompanyName}
          width="100px"
        />
      </Box>
      {imageUrl && selectedImage && (
        <Box mt={2} textAlign="center">
          <div>Image Preview:</div>
          <img src={imageUrl} alt={selectedImage.name} height="100px" />
        </Box>
      )}
    </form>
  );
}

export default TenantLogoForm;
