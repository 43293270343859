import { authorizedRequest } from "../httprequests";
import { ImportTemplateFormValues } from "../components/modals/ImportTemplateModal";

interface PostImportTemplateRequest {
  data: ImportTemplateFormValues;
}

export const postImportTemplate = async (values: ImportTemplateFormValues) =>
  authorizedRequest.post<PostImportTemplateRequest, unknown>(
    "/roster/template-import",
    { data: values }
  );
