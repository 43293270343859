import React, { ReactNode } from "react";
import styled from "styled-components";
import moment from "moment";
import { Shift } from "../../RosterScreen/requests";
import { Box } from "@material-ui/core";

const Container = styled.div<{ height?: string; width?: string }>`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  min-height: 160px;
  height: ${({ height }) => height || "80%"};
  width: ${({ width }) => width || "95%"};
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.gap[1]};
  box-sizing: border-box;
`;

const Title  = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
const ComponentOne  = styled.div<{ height?: string; width?: string }>`
  background-color: green;
  border-radius:"50%";
  height: 5px;
  width: 5px;
 
`;

const Row = styled.div`
  display: flex;
`;

const Text = styled.span<{ flex?: number }>`
  display: flex;
  flex: ${({ flex }) => flex || 1};
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

interface Props {
  shift: Shift;
  children?: ReactNode;
  width?: string;
  height?: string;
}

function ShiftCard({ shift, children, height, width }: Props) {
  const startMoment = moment(shift.startDts);
  const endMoment = moment(shift.endDts);
  return (
    <Container height={height} width={width}>
     <Row style={{justifyContent: "space-between"}}>
     
      <Title>{startMoment.format("ddd DD MMM YYYY")}</Title>
     {shift.available && !shift.own ? 
       <ComponentOne/>
     :null}
    
     </Row>
      <Row>
        <Text flex={2}>Time</Text>
        <Text flex={3}>{`${startMoment.format("HH:mm")} - ${endMoment.format(
          "HH:mm"
        )}`}</Text>
      </Row>
      <Row>
        <Text flex={2}>Shift name</Text>
        <Text flex={3}>{shift.shiftName}</Text>
      </Row>
      <Row>
        <Text flex={2}>Location</Text>
        <Text flex={3}>{shift.roster?.location?.name}</Text>
      </Row>
      <Row>
        <Text flex={2}>Owner</Text>
        <Text flex={3}>{shift.user?.fullName}</Text>
      </Row>
      {children}
    </Container>
  );
}

export default ShiftCard;
