import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
// import useTokens from "../hooks/useTokens";

interface UserDetailsContextProps {
  isAdministrator?: boolean;
}
const UserDetailsContext = createContext<UserDetailsContextProps>({});

interface UserDetailContextProviderProps {
  children?: ReactNode;
}
export default function UserDetailContextProvider({
  children,
}: UserDetailContextProviderProps) {
  // const { refreshToken, accessToken } = useTokens();

  // useEffect(() => {
  //   console.log({ refreshToken, accessToken });
  // }, [refreshToken, accessToken]);

  return (
    <UserDetailsContext.Provider value={{}}>
      {children}
    </UserDetailsContext.Provider>
  );
}

export function useUserDetail() {
  return useContext(UserDetailsContext);
}
