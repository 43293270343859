import styled from "styled-components";

export const OffersTable = styled.table`
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

export const OffersThead = styled.thead<{ backgroundColor?: string }>`
  border-bottom: 1px solid ${({ theme }) => theme.colors.greyish};
  background-color: ${({ theme }) => theme.colors.background.paper};
`;

export const OffersTr = styled.tr<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor};
`;

export const OffersTh = styled.th<{ width?: string }>`
  font-size: 12px;
  padding: 4px;
  width: ${({ width }) => width};
`;

export const OffersTd = styled.td`
  text-align: center;
  font-size: 12px;
`;
