import React from "react";
import styles from "./styles.module.css";

interface DayCheckboxProps {
  value: boolean;
  onClick?: (v: boolean) => void;
  title?: string;
}

export default ({ value, onClick, title }: DayCheckboxProps) => {
  const _handleClick = () => {
    onClick && onClick(!value);
  };

  return (
    <div
      className={`${styles.container} ${value && styles.blue}`}
      onClick={_handleClick}
    >
      {title}
    </div>
  );
};
