import React, { useState } from "react";
import Logo from "../Login/Logo";
import style from "./style.module.css";
import ForgotPasswordForm, { SubmitValues } from "./ForgotPasswordForm";
import ResendPassword from "./ResendPassword";
import { useParams } from "react-router-dom";
import ResetPasswordForm from "./ResetPasswordForm";
import { History } from "history";
import { requestResetPassword, requestNewPassword } from "./requests";
import { useSnackbar } from "notistack";

export default ({ history }: { history: History }) => {
  const { rkey } = useParams<{ rkey: string }>();
  const [passwordSent, setpasswordSent] = useState(false);
  const [email, setEmail] = useState("");

  const { enqueueSnackbar } = useSnackbar();

  const _submit = async ({ email }: SubmitValues) => {
    if (!email) return;
    try {
      setEmail(email);
      await requestResetPassword(email);
      setpasswordSent(true);
    } catch (err) {
      if (err.response && err.response.status === 400) {
        setpasswordSent(true);
      }
    }
  };

  const _submitPassword = async ({ password }: { password: string }) => {
    if (!rkey || !password) return;
    try {
      await requestNewPassword(rkey, password);
      history.push("/login");
    } catch (err) {
      if (err.response && err.response.data.message) {
        enqueueSnackbar(err.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "Something is wrong. Please click the link in the email again",
          { variant: "error" }
        );
      }
    }
  };

  return (
    <div className={style.container}>
      <Logo />
      {rkey ? (
        <ResetPasswordForm
          onSubmit={_submitPassword}
          history={history}
          resetKey={rkey}
        />
      ) : !passwordSent ? (
        <ForgotPasswordForm history={history} onSubmit={_submit} />
      ) : (
        <ResendPassword history={history} onSubmit={_submit} email={email} />
      )}
    </div>
  );
};
