import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Card as MCard } from "@material-ui/core";
import StandardButton from "../../../components/Buttons/StandardButton";
import { UserShiftsToSwapResponse } from "../../../requests/shifts";
import { Shift } from "../../RosterScreen/requests";
import moment from "moment";
import merge from "deepmerge";
import ShiftCard from "../UserMonthlyRoster/ShiftCard";
import styled from "styled-components";
import { useShift } from "../UserRequestSwapScreen/Provider";

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      flexDirection: "column",
    },
    ownCardContainer: {
      backgroundColor: theme.palette.background.default,
      top: 50,
      position: "sticky",
      zIndex: 2,
    },
    date: {
      display: "flex",
      justifyContent: "center",
      padding: theme.spacing(0.5),
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
    sticky: {
      position: "sticky",
      top: 230,
      zIndex: 1000,
    },
    cardScrollerConainer: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      position: "relative",
    },
  };
});

function DateTitle({
  children: text,
  sticky,
}: {
  children: string;
  sticky?: boolean;
}) {
  const c = useStyles();
  return (
    <MCard variant="outlined" className={`${c.date} ${sticky ? c.sticky : ""}`}>
      <Typography variant="body1">{text}</Typography>
    </MCard>
  );
}

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  flex: 1;
`;

interface SwapShiftComponentMobileProps {
  shiftsToSwap: UserShiftsToSwapResponse;
  selectedShift: Array<Shift>
  ownShift: Shift;
  onSelectSwapClick: (s: void) => void;
}

export default function SwapShiftComponentMobile({
  shiftsToSwap,
  onSelectSwapClick,
  selectedShift,
  ownShift,
}: SwapShiftComponentMobileProps) {
  const c = useStyles();
  const { shifts, setShifts } = useShift();

  const [shiftsList, setShiftsList] = useState<{
    [key: string]: Array<Shift>;
  }>({});

  useEffect(() => {
    const u = Object.entries(shiftsToSwap).map(([key, entry]) => entry.shifts);
    const s = u.reduce((a, c) => merge(a, c), {});

    let count = Object.keys(s).length;
    for (let i = 0; i < count; i++) {
      let arr: any = Object.values(s);
      if (arr[i]) {
        arr[i].sort((b: any, c: any) => {
          if (new Date(b.startDts) < new Date(c.startDts)) {
            return -1;
          }
          if (new Date(b.startDts) > new Date(c.startDts)) {
            return 1;
          }
          return 0;
        })
      }
      const sorted = Object.keys(s)
        .sort()
        .reduce((accumulator: any, key: string) => {
          accumulator[key] = s[key];
          return accumulator;
        }, {});
      setShiftsList(sorted);
    }
  }, [shiftsToSwap]);

  function onShiftCellClicked(shift: Shift, id: number | undefined) {
    if (shift) {
      if (
        (selectedShift?.length) && selectedShift.includes(shift)) {
        const newShift = shifts.filter(
          (shift: any) => shift.id !== id
        )
        setShifts(newShift)
      } else if (shifts.length < 5) {
        setShifts([...shifts, shift])
      }
    }
  }

  return (
    <div className={c.container}>
      <div className={c.ownCardContainer}>
        <ButtonHolder>
          <StandardButton
            title="Submit Selection"
            type="submit"
            accent
            disabled={!selectedShift.length}
            onClick={() => onSelectSwapClick(shifts)}
          />
        </ButtonHolder>
        <Typography variant="h5">Current shift</Typography>
        <ShiftCard shift={ownShift} width="100%" height="160px" />
        <Typography variant="h5">Shifts to swap</Typography>
      </div>
      <div className={c.cardScrollerConainer}>

        {
          Object.entries(shiftsList).map(([k, s], i) => (
            <Fragment key={i}>
              <DateTitle sticky>
                {moment(k, "YYYY.MM.DD").format("DD MMMM YYYY")}
              </DateTitle>
              {s.map((sh, j) => (
                <ShiftCard key={j} shift={sh} width="100%" height="160px">
                  <ButtonHolder>
                    <StandardButton
                      title={selectedShift.includes(sh) ? "Selected" : "Select"}
                      onClick={() => onShiftCellClicked(sh, sh.id)}
                    />
                  </ButtonHolder>
                </ShiftCard>
              ))}
            </Fragment>
          ))
        }
      </div>
    </div>
  );
}
