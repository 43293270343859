import {
  Button,
  Checkbox,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from "@material-ui/core";
import { ErrorOutline } from "@material-ui/icons";
import { AxiosError } from "axios";
import { FieldArray, Form, Formik } from "formik";
import moment, { Moment } from "moment";
import { useSnackbar } from "notistack";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import * as yup from "yup";
import {
  DateNavButtonLeft,
  DateNavButtonRight,
  DateNavButtonText,
  DateNavText,
} from "../../../../components/Buttons/DateNavigationButtons";
import StandardButton from "../../../../components/Buttons/StandardButton";
import { useUserSelections } from "../../../../context/userSelectionsProvider";
import { hoursMinutesRegex } from "../../../../GLOBALS";
import { deleteShifts, postShifts } from "../../../../requests/shifts";
import { styledTheme } from "../../../../themes/styledTheme";
import {
  getRostersShifts,
  GetRostersShiftsResponse,
  Shift,
} from "../../requests";
import RosterDayViewFormRow from "./roster-day-view-form-row";

const DateHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
`;

const DateTextHolder = styled.div`
  display: flex;
  justify-content: center;
  width: 280px;
`;

const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const Th = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
  font-weight: 400;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brownGrey};
  // font-size: ${({ theme }) => theme.fonts.size.default};
  padding-top: 4px;
  padding-bottom: 4px;
  // background-color: ${({ theme }) => theme.colors.background.paper};
`;

const TimeLineHeader = styled.div`
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  // border-bottom: 1px solid ${({ theme }) => theme.colors.greyish};
`;

const TimeHeaderCell = styled.div`
  display: flex;
  width: calc(100% / 24);
  border-right: 1px solid ${({ theme }) => theme.colors.greyish};
  box-sizing: border-box;
  padding-left: 2px;
  &:last-child {
    border-right: none;
  }
  font-size: 10px;
`;

const ButtonHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const FloatingMenu = styled.div<{ x: number; y: number }>`
  display: flex;
  flex-direction: column;
  width: 100px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.colors.greyish};
  background-color: ${({ theme }) => theme.colors.background.paper};
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  position: fixed;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
  z-index: 600;
`;

const FloatingMenuItem = styled.div<{ color?: string }>`
  cursor: pointer;
  padding: 4px 8px;
  text-align: center;
  font-size: 14px;
  color: ${({ color }) => color};
  &:hover {
    background-color: ${({ theme }) => theme.colors.paleGrey};
  }
`;

const newShiftPlaceholder = {
  available: false,
  current: true,
  endDts: "",
  endTime: "",
  offersIn: [],
  offersOut: [],
  onCall: false,
  recordedEndDts: null,
  recordedStartDts: null,
  rosterId: "",
  rosteredUser: "",
  ownerName:"",
  shiftName: "",
  startDts: "",
  startTime: "",
  status: "draft",
  user: undefined,
  roster: undefined,
};

const validationSchema = yup.object({
  shifts: yup.array(
    yup.object({
      shiftName: yup.string().required("required"),
      rosterId: yup.number().required("required"),
      startTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
      endTime: yup
        .string()
        .required("required")
        .matches(hoursMinutesRegex, "wrong format"),
    })
  ),
});

const RosterDayView = () => {
  const { year, month, day } = useParams<{
    year?: string;
    month?: string;
    day?: string;
  }>();
  const history = useHistory();
  const { selectedRosters } = useUserSelections();
  const { enqueueSnackbar } = useSnackbar();
  const [rightClickMenu, setRightClickMenu] = useState<{
    index?: number;
    x: number;
    y: number;
  }>({ index: undefined, x: 0, y: 0 });

  const [selectedData, setSelectedData] = useState<Array<any>>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [currentMoment, setCurrentMoment] = useState<Moment | undefined>(
    undefined
  );

  useEffect(() => {
    if (year && month && day) {
      setCurrentMoment(moment(`${year}-${month}-${day}`, "YYYY-MM-DD"));
    }
  }, [year, month, day]);

  const { data: shifts, refetch } = useQuery<
    GetRostersShiftsResponse,
    AxiosError
  >(
    ["get-shifts-for-the-day", currentMoment, selectedRosters],
    async () => {
      return await getRostersShifts({
        rosterIds: selectedRosters.map((r) => r.id),
        date: currentMoment?.format("YYYY-MM-DD") as string,
      });
    },
    {
      keepPreviousData: true,
      initialData: [],
      enabled: Boolean(currentMoment && selectedRosters.length),
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get shifts`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutateAsync } = useMutation<
    unknown,
    AxiosError,
    { deleted: Array<number> }
  >(
    (data) => {
      const promises = [];
      if (data.deleted && data.deleted.length) {
        promises.push(deleteShifts(data.deleted));
      }
      return Promise.all(promises);
    },
    {
      onError: (err) => {
        enqueueSnackbar(`Unable to Delete Shift`, { variant: "error" });
      },
      onSuccess: async () => {
        handleClose();
        setSelectedData([]);
        await refetch();
        enqueueSnackbar(`Shift Deleted Successfully`, {
          variant: "success",
        });
      },
    }
  );

  const { mutate } = useMutation<
    unknown,
    AxiosError,
    { shifts: Array<Shift>; deleted: Array<number> }
  >(
    (data) => {
      const promises = [];
      if (data.shifts.length) {
        promises.push(postShifts(data.shifts));
      }
      if (data.deleted && data.deleted.length) {
        promises.push(deleteShifts(data.deleted));
      }
      return Promise.all(promises);
    },
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to save changes`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        enqueueSnackbar(`Saved`, {
          variant: "success",
        });
      },
    }
  );

  const onRightClick = (details: {
    id?: number;
    index: number;
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>;
  }) => {
    const { id, index, event } = details;
    setRightClickMenu({ index, x: event.clientX, y: event.clientY });
  };

  const onClickAway = () => {
    setRightClickMenu({ index: undefined, x: 0, y: 0 });
  };

  const _submit = async (form: { shifts: Array<Shift> }) => {
    if (currentMoment) {
      const withDts = form.shifts?.map((s) => {
        const [sh, sm] = s.startTime!.split(":");
        const [eh, em] = s.endTime!.split(":");
        
        //Start time
        let st = currentMoment.clone();
        if(s.startDts) st = moment(s.startDts);
        st.set("hours", Number(sh));
        st.set("minutes", Number(sm));

        //end time
        let en = st.clone();

        //logic to set end date
        if((s.startTime && s.endTime) && s.startTime > s.endTime)
          {
            en.add(1, "day");
          }

        en.set("hours", Number(eh));
        en.set("minutes", Number(em));

        
        s.startDts = st.toDate();
        s.endDts = en.toDate();
        return s;
      });
      const existingIds: Array<number> = [];
      withDts!.forEach((s) => {
        if (s.id) {
          existingIds.push(s.id);
        }
      });
      const deleted = shifts
        ?.filter((s) => !existingIds.includes(s.id!))
        .map((s) => s.id);

      mutate({ shifts: withDts, deleted: deleted as Array<number> });
    }
  };

  const goToPreviousDay = useCallback(() => {
    if (currentMoment) {
      const prevDay = currentMoment
        .clone()
        .subtract(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${prevDay}`);
    }
  }, [currentMoment, history]);

  const goToNextDay = useCallback(() => {
    if (currentMoment) {
      const nextDay = currentMoment
        .clone()
        .add(1, "day")
        .format("[year/]YYYY/[month/]M/[day/]D");
      history.push(`/roster/${nextDay}`);
    }
  }, [currentMoment, history]);

  const handleDialogOpen = React.useCallback(() => setIsOpen(true), []);

  const handleClose = React.useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleClick = (e: any) => {
    const { checked } = e.target;
    if (shifts !== undefined && checked) {
      setSelectedData(shifts.map((data) => data.id));
    }
    if (!checked) {
      setSelectedData([]);
    }
  };

  const handleCancel = () => {
    handleClose();
    setSelectedData([]);
  };

  const handleDelete = () => {
    const deleted = shifts
      ?.filter((val) => selectedData.includes(Number(val.id)))
      .map((s) => s.id);
    mutateAsync({ deleted: deleted as Array<number> });
  };

  return (
    <Fragment>
      {currentMoment && (
        <DateHolder>
          <DateNavButtonLeft onClick={goToPreviousDay} />
          <DateTextHolder>
            <DateNavButtonText
              onClick={() =>
                history.push(`/roster/year/${currentMoment.format("YYYY")}`)
              }
            >
              {currentMoment.format("YYYY")}
            </DateNavButtonText>
            <DateNavButtonText
              onClick={() =>
                history.push(
                  `/roster/year/${currentMoment.format("YYYY/[month/]M")}`
                )
              }
            >
              {currentMoment.format("MMM")}
            </DateNavButtonText>
            <DateNavText>{currentMoment.format("dddd DD")}</DateNavText>
          </DateTextHolder>
          <DateNavButtonRight onClick={goToNextDay} />
        </DateHolder>
      )}
      {shifts && (
        <Formik
          initialValues={{ shifts }}
          onSubmit={_submit}
          enableReinitialize={true}
          validationSchema={validationSchema}
        >
          {({ values, dirty, isSubmitting, resetForm, isValid }) => (
            <Form autoComplete="off">
              <Table>
                <thead>
                  <tr>
                    <Th width={"40px"} />
                    <Th width={"90px"} />
                    <Th width={"60px"} />
                    <Th width={"100px"}>Roster</Th>
                    <Th width={"10%"}>Owner Name</Th>
                    <Th width={"10%"}>Assignee</Th>
                    <Th width={"10%"}>Shift name</Th>
                    <Th width={"28px"}>Avail.</Th>
                    <Th width={"28px"}>On call</Th>
                    <Th width={"48px"}>Start</Th>
                    <Th width={"48px"}>End</Th>
                    <Th width={"84px"}>Posted status</Th>
                    {/* <Th width={"78px"}>Editable</Th> */}
                    <Th>
                      <TimeLineHeader>
                        {Array.from(Array(24).keys()).map((item) => (
                          <TimeHeaderCell key={item}>
                            {`${item}`.padStart(2, "0")}
                          </TimeHeaderCell>
                        ))}
                      </TimeLineHeader>
                      {/* Timeline */}
                    </Th>
                  </tr>
                </thead>
                <tbody>
                  <FieldArray name="shifts">
                    {({ push, remove }) => (
                      <Fragment>
                        {values.shifts &&
                          values.shifts.map((shift, index) => (
                            <RosterDayViewFormRow
                              refetchShifts={refetch}
                              index={index}
                              key={index}
                              onRightClick={onRightClick}
                              selectedData={selectedData}
                              setSelectedData={setSelectedData}
                            />
                          ))}
                        {shifts.length>0 && (
                        <tr>
                          <td />
                          <Th>
                            <Checkbox
                              checked={
                                selectedData.length === shifts.length &&
                                shifts.length !== 0
                              }
                              onClick={(e) => handleClick(e)}
                              color="primary"
                              size="small"
                            />
                            Select All
                          </Th>
                          <Th>
                            <Button
                              variant="contained"
                              size="small"
                              style={{
                                backgroundColor: "#aa0000",
                                color: "white",
                              }}
                              onClick={handleDialogOpen}
                              disabled={selectedData.length===0}
                            >
                              Delete
                            </Button>
                          </Th>
                        </tr>)}

                        <tr>
                          <td colSpan={13}>
                            <ButtonHolder>
                              <StandardButton
                                type="button"
                                title="Add shift"
                                onClick={() => push(newShiftPlaceholder)}
                              />
                              <StandardButton
                                type="submit"
                                title="Submit"
                                accent={true}
                                disabled={isSubmitting || !dirty || !isValid}
                              />
                            </ButtonHolder>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            {rightClickMenu.index !== undefined ? (
                              <ClickAwayListener onClickAway={onClickAway}>
                                <FloatingMenu
                                  x={rightClickMenu.x}
                                  y={rightClickMenu.y}
                                  onClick={() => {
                                    remove(rightClickMenu.index as number);
                                    onClickAway();
                                  }}
                                >
                                  <FloatingMenuItem
                                    color={styledTheme.colors.deepRed}
                                  >
                                    Remove
                                  </FloatingMenuItem>
                                </FloatingMenu>
                              </ClickAwayListener>
                            ) : null}
                          </td>
                        </tr>
                      </Fragment>
                    )}
                  </FieldArray>
                </tbody>
              </Table>
            </Form>
          )}
        </Formik>
      )}
      <Dialog open={isOpen} maxWidth="sm" fullWidth>
        <DialogTitle style={{ textAlign: "center" }}>
          <ErrorOutline style={{ fontSize: "8em", color: "navajowhite" }} />
        </DialogTitle>
        <DialogContent style={{ textAlign: "center" }}>
          <h2>Are you sure?</h2>
          <Typography>You want to delete Shifts?</Typography>
          <Typography>Please Ensure and then delete!</Typography>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="contained" onClick={handleCancel}>
            No
          </Button>
          <Button
            variant="contained"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={handleDelete}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default RosterDayView;
