import React, { useState, useEffect, useCallback } from "react";
import styles from "./styles.module.css";
import { RWrapper } from "../../components/layout/RWrapper";
import { Typography, Divider, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import StandardButton from "../../components/Buttons/StandardButton";
import ModalContainer from "../../components/modals/ModalContainer";
import AddLocationModal, {
  AddLocationModalSubmitValues,
} from "./modals/AddLocationModal";
import {
  requestPostLocation,
  requestGetLocations,
  requestPostRoster,
  requestGetRosters,
  Roster,
} from "./requests";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  useParams,
} from "react-router-dom";
import SelectDefault from "../../components/inputs/SelectDefault";
import RosterYearView from "./views/RosterYearView";
import CreateRosterModal, {
  CreateRosterModalSubmitValues,
} from "./modals/CreateRosterModal";
import { UserType, User } from "../StaffScreen";
import { requestGetUserTypes, requestGetUsers } from "../StaffScreen/requests";
import RosterMonthView from "./views/RosterMonthView";
import RosterWeekView from "./views/RosterWeekView";
// import RosterDayView from "./views/RosterDayView/index_";
import RosterDayView from "./views/RosterDayView";
import FloatingButton from "../../components/Buttons/FloatingButton";
import ImportTemplateModal from "../../components/modals/ImportTemplateModal";
import LetterIcon from "../../components/dynamicIcons/LetterIcon";
import { useUserSelections } from "../../context/userSelectionsProvider";
import { useSnackbar } from "notistack";
import { useProgressBar } from "../../components/bars/ProgressBarGlobal";
import Dropdown from "../../components/inputs/Dropdown";

export interface Location {
  id?: number;
  locationName: string;
}

const useStyles = makeStyles((theme) => ({
  snackGreen: {
    backgroundColor: theme.palette.success.main,
  },
  snackRed: {
    backgroundColor: theme.palette.error.main,
  },
  cross: {
    color: "#ffffff",
  },
  floatingButtonTitle: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  mr: {
    marginRight: theme.spacing(1),
  },
  rightSide: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  dropdownContainer: {
    width: 100,
    backgroundColor: "#ffffff",
    borderRadius: 2,
  },
}));

const RosterScreen = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { barActivate, barStop } = useProgressBar();
  const {
    selectedLocation: locationSelected,
    setLocation,
    selectedRosters: rostersSelected,
    setRosters: setRostersSelected,
    initialized: selectedInitialized,
  } = useUserSelections();

  const [rosters, setRosters] = useState<Array<Roster>>([]);

  const [userTypes, setUserTypes] = useState<Array<UserType>>([]);

  // const [possibleAssignees, setPossibleAssignees] = useState<Array<User>>([]);

  const [locations, setLocations] = useState<Array<Location>>([]);

  const [modalLocationsOpen, setModalLocationsOpen] = useState<boolean>(false);
  const [modalRosterOpen, setModalRosterOpen] = useState<boolean>(false);
  const [modalImportTemplateOpen, setModalImportTemplateOpen] =
    useState<boolean>(false);

  const [calendarViewSelectorText, setCalendarViewSelectorText] =
    useState<string>("");

  const _closeModalLocation = () => setModalLocationsOpen(false);
  const _openModalLocation = () => setModalLocationsOpen(true);
  const _closeModalRoster = () => setModalRosterOpen(false);
  const _openModalRoster = () => {
    if (userTypes.length) setModalRosterOpen(true);
  };
  const _closeModalImpTemplate = () => setModalImportTemplateOpen(false);
  const _openModalImpTemplate = () => setModalImportTemplateOpen(true);

  const adjustCalendarViewSelectorText = useCallback(() => {
    if (pathname.includes("day")) {
      setCalendarViewSelectorText("Day");
    } else if (pathname.includes("month")) {
      setCalendarViewSelectorText("Month");
    } else {
      setCalendarViewSelectorText("Year");
    }
  }, [pathname]);

  useEffect(() => {
    adjustCalendarViewSelectorText();
  }, [adjustCalendarViewSelectorText]);

  const retrieveRosters = useCallback(async () => {
    if (locationSelected && locationSelected.id) {
      try {
        const rn = await requestGetRosters(locationSelected.id);
        setRosters(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    }
  }, [locationSelected, enqueueSnackbar]);

  useEffect(() => {
    retrieveRosters();
  }, [retrieveRosters]);

  function unique(value: Array<number>): Array<number> {
    const result: Array<number> = [];
    value.forEach((v) => {
      if (!result.includes(v)) {
        result.push(v);
      }
    });
    return result;
  }

  // const retrievePossibleAssignees = useCallback(async () => {
  //   try {
  //     if (rostersSelected.length) {
  //       const userTypes = rostersSelected.map((ut) => ut.userTypes).flat();
  //       const userTypesIds = unique(
  //         userTypes.filter((u) => u).map((u) => u?.id) as Array<number>
  //       );

  //       const result = await requestGetUsers(userTypesIds);
  //       setPossibleAssignees(result);
  //     } else {
  //       setPossibleAssignees([]);
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }, [rostersSelected]);

  // useEffect(() => {
  //   retrievePossibleAssignees();
  // }, [retrievePossibleAssignees]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocations(result);
      if (locationSelected && result.length) {
        const found = result.find((e) => {
          return e.id === locationSelected.id;
        });
        if (found) {
          if (found.locationName !== locationSelected.locationName) {
            setLocation(found);
          }
        } else {
          setLocation(result[0]);
        }
      } else if (!locationSelected && result.length) {
        setLocation(result[0]);
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [locationSelected, setLocation, enqueueSnackbar, barStop, barActivate]);

  const getUserTypes = useCallback(async () => {
    try {
      barActivate();
      const ut = await requestGetUserTypes();
      setUserTypes(ut);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve user types", { variant: "error" });
    } finally {
      barStop();
    }
  }, [enqueueSnackbar, barActivate, barStop]);

  useEffect(() => {
    if (selectedInitialized) {
      // GET LOCATIONS
      getLocations();
      // GET USER TYPES
      getUserTypes();
    }
  }, [selectedInitialized, getLocations, getUserTypes]);

  const _submitLocation = async ({
    locationName,
    id,
  }: AddLocationModalSubmitValues) => {
    try {
      barActivate();
      const location = await requestPostLocation(locationName);
      setLocations([...locations, location]);
      setLocation(location);
      _closeModalLocation();
      enqueueSnackbar("Location added", { variant: "success" });
    } catch (error) {
      console.error(error);
      const response = error.response || {};
      const { status } = response;
      if (status === 409) {
        enqueueSnackbar("Location already exists", { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const _submitRoster = async (values: CreateRosterModalSubmitValues) => {
    try {
      barActivate();
      if (!locationSelected || !locationSelected.id)
        throw new Error("no location selected");
      const response = await requestPostRoster({
        ...values,
        locationId: locationSelected.id,
      });
      console.log("requestPostRoster response", response);
      const rn = await requestGetRosters(locationSelected.id);
      setRosters(rn);
      // setRosters([...rosters, response]);
      setRostersSelected([response]);
      _closeModalRoster();
      enqueueSnackbar("Roster created", { variant: "success" });
    } catch (error) {
      console.error(error);
      const response = error.response || {};
      const { status } = response;
      if (status === 409) {
        enqueueSnackbar(`Roster with the name ${values.name} already exists`, {
          variant: "error",
        });
      } else if (error.message === "no location selected") {
        enqueueSnackbar(`Please select location first`, { variant: "error" });
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } finally {
      barStop();
    }
  };

  const _floatingButtonActive = (id: number) => {
    if (!rostersSelected.length) return false;
    else if (rostersSelected.find((e) => e.id === id)) return true;
    else return false;
  };

  const _handleRosterNameButtonToggle = (roster: Roster) => {
    if (_floatingButtonActive(roster.id)) {
      const without = rostersSelected.filter((i) => i.id !== roster.id);
      setRostersSelected(without);
    } else {
      setRostersSelected([...rostersSelected, roster]);
    }
  };

  const handleCalendarViewSelectChange = useCallback(
    ({ value }: { title: string; value?: number | string }) => {
      const [, , , year, , month] = pathname.split("/");
      if (value === "year") {
        // go current year or year in the pathname
        history.push(`/roster/year/${year ? year : ""}`);
      } else if (value === "month") {
        // go to current month or month in the pathname
        const d = new Date();
        history.push(
          `/roster/year/${year ? year : d.getFullYear()}/month/${month ? month : ""
          }`
        );
      } else if (value === "day") {
        // go to today
        const d = new Date();
        history.push(
          `/roster/year/${d.getFullYear()}/month/${d.getMonth() + 1
          }/day/${d.getDate()}`
        );
      }
    },
    [history, pathname]
  );

  return (
    <RWrapper>
      <ModalContainer onClose={_closeModalLocation} open={modalLocationsOpen}>
        <AddLocationModal
          onSubmit={_submitLocation}
          onCancel={_closeModalLocation}
        />
      </ModalContainer>
      <ModalContainer onClose={_closeModalRoster} open={modalRosterOpen}>
        <CreateRosterModal
          onSubmit={_submitRoster}
          onCancel={_closeModalRoster}
          userTypes={userTypes.map((ut) => ({
            ...ut,
            selected: false,
          }))}
        />
      </ModalContainer>
      <ImportTemplateModal
        onClose={_closeModalImpTemplate}
        open={modalImportTemplateOpen}
        rosterNames={rosters}
        onActionSuccess={() => {
          enqueueSnackbar("Template imported", { variant: "success" });
          setModalImportTemplateOpen(false);
        }}
        onActionError={(error) =>
          enqueueSnackbar("Template was not imported", { variant: "error" })
        }
      />
      <Box className={styles.pagetitlebox}>
        <Typography variant="h4" color="textPrimary">
          Roster
        </Typography>
        <Box className={styles.marginleft1}>
          <SelectDefault
            id="roster-shift-loc-select-1"
            onChange={(loc) => {
              setLocation(locations.find((l) => l.id === loc.value));
            }}
            value={locationSelected ? locationSelected.id : ""}
            options={locations.map((l) => ({
              value: l.id || 0,
              title: l.locationName,
            }))}
          />
        </Box>
        <Box className={styles.marginleft1}>
          <StandardButton title="Add Location" onClick={_openModalLocation} />
        </Box>
        <Box className={styles.marginleft1}>
          <StandardButton
            title="Import Template"
            onClick={_openModalImpTemplate}
          />
        </Box>
        <Box className={classes.rightSide}>
          <Box className={classes.dropdownContainer}>
            <Dropdown
              textShown={calendarViewSelectorText}
              onSelect={handleCalendarViewSelectChange}
              options={[
                { title: "Year", value: "year" },
                { title: "Month", value: "month" },
                { title: "Day", value: "day" },
              ]}
            />
          </Box>
        </Box>
      </Box>

      {rosters.length ? <Divider /> : null}

      <Box className={styles.pagetitlebox1}>
        {locationSelected && locationSelected.id && userTypes.length ? (
          <Box className={styles.marginright03}>
            <FloatingButton
              title={rosters.length ? "+" : "Create Roster +"}
              width="1em"
              onClick={_openModalRoster}
            />
          </Box>
        ) : null}
        {rosters.map((r) => (
          <Box className={styles.marginright03} key={r.id}>
            <FloatingButton
              title={
                <div className={classes.floatingButtonTitle}>
                  <div className={classes.mr}>
                    <LetterIcon
                      color={r.color}
                      letter={r.name.charAt(0).toUpperCase()}
                    />
                  </div>
                  {r.name}
                </div>
              }
              width="5em"
              active={_floatingButtonActive(r.id)}
              onClick={() => _handleRosterNameButtonToggle(r)}
            />
          </Box>
        ))}

      </Box>

      <Divider />
      {!locationSelected ||
        !locationSelected.id ||
        !rostersSelected[0] ? null : (
        <Switch>
          <Route exact path={`/roster/year/:year?`}>
            <RosterYearView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month?`}>
            <RosterMonthView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month/week/:week?`}>
            <RosterWeekView />
          </Route>
          <Route exact path={`/roster/year/:year/month/:month/day/:day`}>
            <RosterDayView />
          </Route>
          <Route>
            <Redirect to="/roster/year" />
          </Route>
        </Switch>
      )}
    </RWrapper>
  );
};

export default RosterScreen;
