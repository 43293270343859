import { authorizedRequest } from "../httprequests";
import { Shift } from "../screens/RosterScreen/requests";
import { User } from "../screens/StaffScreen";

export enum OfferStatus {
  ACCEPTED = "accepted",
  CANCELLED = "cancelled",
  DECLINED = "declined",
  PENDING = "pending",
}
export interface Offer {
  createdAt: string;
  id: number;
  inboundShift: Shift;
  inboundShiftId: number;
  inboundUserId: number;
  outboundShift: Shift;
  outboundUser?: User;
  inboundUser?: User;
  outboundShiftId: number;
  outboundUserId?: number;
  updatedAt?: string;
  status: OfferStatus;
}

type GetIncomingOffersResponse = Array<Offer>;
export async function getIncomingOffers() {
  return authorizedRequest.get<GetIncomingOffersResponse>(`/offers/incoming`);
}

type GetOutgoingOffersResponse = Array<Offer>;
export async function getOutgoingOffers() {
  return authorizedRequest.get<GetOutgoingOffersResponse>(`/offers/outgoing`);
}

export async function deleteOffer(offerId: number) {
  return authorizedRequest.delete(`/offers/${offerId}`);
}

export async function postAcceptOffer(offerId: number) {
  return authorizedRequest.post(`/offers/${offerId}`, {});
}
export async function postAcceptTakeShiftOffer(offerId: number) {
  return authorizedRequest.post(`/offers/${offerId}/accept`, {});
}

export interface GetSwapOffersResponse {
  offers: Array<Offer>;
  count: number;
}
export function getSwapOffers(params: {
  take: number;
  skip: number;
  order: "DESC" | "ASC";
  status?: OfferStatus;
}) {
  return authorizedRequest.get<GetSwapOffersResponse>(`/offers/`, { params });
}

export function getSingleSwapOffer(id: number) {
  return authorizedRequest.get<Offer>(`/offers/${id}`);
}
