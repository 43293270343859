import React from "react";
import styles from "./styles.module.css";
import { Formik, Form, Field, FormikProps } from "formik";
import StandardButton from "../../../../components/Buttons/StandardButton";
import { Typography, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ModalWindow from "../../../../components/modals/ModalWindow";

export interface AddLocationModalSubmitValues {
  locationName: string;
  id?: number;
}

interface AddLocationModalProps {
  onSubmit: (values: AddLocationModalSubmitValues) => void;
  onCancel: () => void;
  locationName?: string;
  id?: number;
}

interface ValidationProps {
  locationName: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: "0.3em",
  },
}));

const validation = ({ locationName }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!locationName) {
    errors.locationName = "Location name can not be empty";
  }
  return errors;
};

export default ({
  onSubmit,
  onCancel,
  locationName,
  id,
}: AddLocationModalProps) => {
  const classes = useStyles();
  return (
    <ModalWindow>
      <Formik
        initialValues={{ locationName: locationName || "" }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate autoComplete="off">
            <Typography variant="h5" align="center">
              {id ? "Edit Location Name" : "Add Location"}
            </Typography>
            <InputLabel color="primary" className={classes.label}>
              Location name
            </InputLabel>
            <Field name="locationName" type="text" className={styles.input} />
            <div className={styles.buttonsholder}>
              <div />
              <div className={styles.submitbuttonsholder}>
                <div className={styles.cancelbuttonmargin}>
                  <StandardButton title="Cancel" onClick={onCancel} />
                </div>
                <StandardButton
                  title="Create"
                  type="submit"
                  accent
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </ModalWindow>
  );
};
