import React from "react";
import useStyles from "./styles";
import { Grid, Paper } from "@material-ui/core";
import YearTabMonthCalendar from "../YearTabMonthCalendar";
import { useHistory } from "react-router-dom";

enum MonthStatus {
  published = "published",
  unpublished = "unpublished",
  complete = "complete",
}

export interface YearCalendarMonthStatuses {
  [key: number]: MonthStatus;
}
interface YearCalendarProps {
  year: number;
  statuses: YearCalendarMonthStatuses;
  path?:string
}

// array [1,2,...12]
const arr = Array.from(Array(12).keys()).map((e) => e + 1);

const YearCalendar = ({ year, statuses,path }: YearCalendarProps) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Paper className={classes.papercontainer} elevation={0}>
      <Grid container spacing={10}>
        {arr.map((monthNumber) => (
          <Grid item key={monthNumber} xs={12} sm={6} md={4} lg={3} xl={2}>
            <YearTabMonthCalendar
              onMonthNameClick={(month) =>
                history.push(`/${path}/year/${year}/month/${month}`)
              }
              onDayClick={(d) =>
                history.push(
                  `/${path}/year/${year}/month/${monthNumber}/day/${d.day}`
                )
              }
              year={year}
              month={monthNumber}
              status={statuses[monthNumber]}
            />
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};

export default YearCalendar;
