import { authorizedRequest } from "../httprequests";

export async function getMessageEmail(name: string) {
  return authorizedRequest.get<{ subject?: string; body: string }>(
    `/settings/message-template/${name}`
  );
}

interface PostMessageEmailProps {
  subject?: string | null;
  body: string;
}
export async function postMessageEmail(
  name: string,
  data: PostMessageEmailProps
) {
  return authorizedRequest.post<PostMessageEmailProps, unknown>(
    `/settings/message-template/${name}`,
    data
  );
}

export async function putMessageEnabled(name: string, enabled: boolean) {
  return authorizedRequest.put(`/settings/message-template/${name}`, {
    enabled,
  });
}

export interface GetSettingResponse {
  smsReminderOffset: number;
}
export function getSettings() {
  return authorizedRequest.get<GetSettingResponse>(`/settings/`);
}

export function putSmsReminderTimeOffset(offset: number) {
  return authorizedRequest.put(`/settings/sms-reminder-time-offset`, {
    offset,
  });
}



export async function postImage(
  userID:number, 
  formData:any,
){
  return authorizedRequest.post<any,unknown>(
    `/settings/image-uploaded/${userID}`,
    formData
  );
}