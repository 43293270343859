import { makeStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import ModalContainer from "../../../components/modals/ModalContainer";
import ModalWindow from "../../../components/modals/ModalWindow";

import { Grid } from "@material-ui/core";
import SwapHorizIcon from "@material-ui/icons/SwapHoriz";
import StandardButton from "../../../components/Buttons/StandardButton";
import { Shift } from "../../RosterScreen/requests";
import SwapShiftCard from "./swapShiftCard";

const useStyles = makeStyles((theme) => {
  return {
    arrowsContainer: {
      display: "flex",
      flex: 1,
      height: "100%",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    buttonsHolder: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginTop: theme.spacing(2),
      flexWrap: "wrap",
    },
    btn: {
      minWidth: 130,
      margin: theme.spacing(0.5),
    },
    btnWarn: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      border: "none",
      "&:focus, &:active": {
        border: "none",
      },
    },
    // spacer: {
    //   width: theme.spacing(1),
    //   height: 10,
    //   backgroundColor: "red",
    // },
  };
});

interface SwapModalProps {
  onClose: () => void;
  open: boolean;
  currentShift?: Shift;
  swapShift?: Shift[];
  onSubmit?: (p: { currentShift: Shift; swapShift: Shift }) => void;
  onDelete?: (id: number) => void;
  onAccept?: (id: number) => void;
  offerId?: number;
  onTakeShift?: (p: { swapShift: Shift }) => void;
}

export default function SwapModal({
  onClose,
  open,
  currentShift,
  swapShift,
  onSubmit,
  onDelete,
  onAccept,
  offerId,
  onTakeShift,
}: SwapModalProps) {
  const c = useStyles();

  return (
    <ModalContainer onClose={onClose} open={open}>
      <ModalWindow>
        {swapShift?.length && (
          <Grid container spacing={1}>
            {currentShift
              ? swapShift.map((shift) => {
                  return (
                    <>
                      {" "}
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <SwapShiftCard shift={currentShift} outlined />
                      </Grid>
                      <Grid item xs={12} sm={12} md={2} lg={2}>
                        <div className={c.arrowsContainer}>
                          <SwapHorizIcon color="action" />
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12} md={5} lg={5}>
                        <SwapShiftCard shift={shift} outlined />
                      </Grid>
                    </>
                  );
                })
              : swapShift.map((shift) => {
                  return (
                    <Grid item xs={12} sm={12} md={5} lg={5}>
                      <SwapShiftCard shift={shift} outlined />
                    </Grid>
                  );
                })}
          </Grid>
        )}

        <div className={c.buttonsHolder}>
          <StandardButton className={c.btn} title="Cancel" onClick={onClose} />
          {currentShift && swapShift?.length && onSubmit ? (
            <Fragment>
              <StandardButton
                className={c.btn}
                title="Send Request"
                accent
                onClick={() =>
                  swapShift?.map((shift) =>
                    onSubmit({ currentShift, swapShift: shift })
                  )
                }
              />
            </Fragment>
          ) : null}
          {!currentShift && swapShift && onTakeShift ? (
            <Fragment>
              {/* <div className={c.spacer} /> */}
              <StandardButton
                className={c.btn}
                title="Send Request"
                accent
                onClick={() => onTakeShift({ swapShift: swapShift[0] })}
              />
            </Fragment>
          ) : null}
          {currentShift && swapShift && onDelete && offerId ? (
            <Fragment>
              {/* <div className={c.spacer} /> */}
              <StandardButton
                className={`${c.btn} ${c.btnWarn}`}
                title={onAccept ? "Decline offer" : "Cancel shift request"}
                onClick={() => onDelete(offerId)}
              />
            </Fragment>
          ) : null}
          {currentShift && swapShift && onAccept && offerId ? (
            <Fragment>
              {/* <div className={c.spacer} /> */}
              <StandardButton
                className={c.btn}
                accent
                title="Accept offer"
                onClick={() => onAccept(offerId)}
              />
            </Fragment>
          ) : null}
        </div>
      </ModalWindow>
    </ModalContainer>
  );
}
