import React, { useState, useEffect } from "react";
import style from "../ForgotPasswordForm/style.module.css";
import { Formik, Field, Form } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { requestCheckKey } from "../requests";
import { History } from "history";

interface PasswordFormProps {
  onSubmit: ({ password }: { password: string }) => void;
  history: History;
  resetKey: string;
}

const validation = ({ password }: { password: string }) => {
  const errors: { [key: string]: string } = {};
  if (!password) {
    errors.password = "Password required";
  } else if (password.length < 6) {
    errors.password = "Password is too short";
  }
  return errors;
};

export default ({ onSubmit, history, resetKey }: PasswordFormProps) => {
  const [keyChecked, setkeyChecked] = useState(false);
  const [keyValid, setkeyValid] = useState(false);
  // WHEN RENDERS CHECK IF KEY IS VALID
  useEffect(() => {
    _checkKey(resetKey);
  }, [resetKey]);

  const _checkKey = async (resetKey: string) => {
    if (!resetKey) {
      setkeyChecked(true);
      return;
    }
    try {
      await requestCheckKey(resetKey);
      setkeyValid(true);
    } catch (err) {
      setkeyValid(false);
    } finally {
      setkeyChecked(true);
    }
  };

  if (!keyChecked)
    return (
      <motion.div
        className={style.spinner}
        animate={{
          opacity: [0.3, 0.7, 1, 0.7, 0.3],
          scale: [1, 1.5, 1.5, 1, 1],
          rotate: [0, 0, 270, 270, 0],
          borderRadius: ["20%", "20%", "50%", "50%", "20%"],
        }}
        transition={{
          loop: Infinity,
          duration: 2,
        }}
      />
    );

  if (!keyValid)
    return (
      <div className={`${style.errorblock} ${style.erropacity1}`}>
        <span className={style.errortext}>Link Is Invalid</span>
      </div>
    );

  return (
    <AnimatePresence>
      <motion.div
        className={style.formcontainer}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Formik
          initialValues={{ email: "", password: "" }}
          validate={validation}
          onSubmit={onSubmit}
        >
          {({ errors, isValid, dirty, touched }) => (
            <Form className={style.form} noValidate>
              <div
                className={`${style.errorblock} ${
                  errors.password && touched.password ? style.erropacity1 : ""
                }`}
              >
                <span className={style.errortext}>
                  {errors.password ? errors.password : ""}
                </span>
              </div>
              {/* EMAIL FIELD IS HERE ONLY TO BROWSER'S UPDATE PASSWORD DIALOGUE */}
              <Field
                name="email"
                type="email"
                className={style.input}
                autoComplete="email"
                hidden
              />
              <Field
                name="password"
                type="password"
                placeholder="New password"
                className={style.input}
                autoComplete="new-password"
              />
              <div className={style.buttonsrow}>
                <Link
                  to="/login"
                  className={`${style.forgotpasswordtext} hidewhensmall`}
                >
                  Login
                </Link>
                <motion.button
                  type="submit"
                  className={style.button}
                  whileHover={!dirty || !isValid ? {} : { scale: 1.1 }}
                  whileTap={!dirty || !isValid ? {} : { scale: 0.9 }}
                  disabled={!dirty || !isValid}
                >
                  <span className={style.buttonText}>Save Password</span>
                </motion.button>
              </div>
            </Form>
          )}
        </Formik>
        <Link
          to="/login"
          className={`${style.forgotpasswordtext} hidewhenmedium ${style.margin20em}`}
        >
          Login
        </Link>
      </motion.div>
    </AnimatePresence>
  );
};
