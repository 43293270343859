import React from "react";
import useStyles from "./styles";
import { Paper, Typography, Grid } from "@material-ui/core";
import { CalendarBaseDay } from "../../../../../components/calendars/CalendarBase";
import LetterIcon from "../../../../../components/dynamicIcons/LetterIcon";


interface DayCellItem {
  id: number;
  title: string;
  color: string;
}

interface DayCellProps {
  day: CalendarBaseDay;
  items: Array<DayCellItem>;
  status?: string | "unpublished" | "worked" | "published";
  onClick?: (day: CalendarBaseDay) => void;
}

const DayCell = ({
  day: d,
  items,
  status,
  onClick = () => null,
}: DayCellProps) => {
  const classes = useStyles({ status });
  const { day, type } = d;

  const onClickHandler = () => {
    onClick(d);
  };

  if (type !== "current") return null;
  return (
    <Paper
      className={classes.cellContainer}
      elevation={1}
      onClick={onClickHandler}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography className={classes.cellday}>
            {`${day}`.padStart(2, "0")}
          </Typography>
        </Grid>
      </Grid>
      <div className={classes.cellItemsContainer}>
        {items.map((e) => (
          <div className={classes.cellItem} key={e.id}>
            <LetterIcon letter={e.title.toUpperCase()} color={e.color} />
          </div>
        ))}
      </div>
    </Paper>
  );
};

export default DayCell;
