import { authorizedRequest } from "../../httprequests";
import { UserType, User } from ".";

interface PostUserTypeRequest {
  typeName: string;
  description?: string;
}

export const requestPostUserType = (typeName: string, description?: string) => {
  return authorizedRequest.post<PostUserTypeRequest, UserType>(
    `/staff/usertypes`,
    {
      typeName,
      description,
    }
  );
};

export const requestGetUserTypes = () =>
  authorizedRequest.get<Array<UserType>>(`/staff/usertypes`);

export const requestGetUsers = (userType?: number | "admin" | Array<number>) =>
  authorizedRequest.get<Array<User>>(
    `/staff/with-user-type/${userType ? userType : ""}`
  );

export const requestGetStaff = (userType?: number | "admin" | Array<number>) =>
  authorizedRequest.get<Array<User>>(
    `/staffList/with-user-type/${userType ? userType : ""}`
  );
