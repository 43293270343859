import React from "react";
import {
  Select,
  MenuItem,
  InputBase,
  InputBaseProps,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

interface Option {
  value: string | number;
  title?: number | string | JSX.Element;
}

interface SelectDefaultProps {
  id: string;
  value?: string | number;
  options?: Array<Option>;
  onChange: (option: Option) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    width: "fit-content",
    minWidth: 120,
    maxWidth: 200,
    paddingLeft: "0.5em",
    paddingRight: "0.5em",
  },
}));

const StyledInput = (props: InputBaseProps) => {
  const classes = useStyles();
  return <InputBase className={classes.container} {...props} />;
};

const SelectDefault = ({
  id,
  value,
  options,
  onChange,
}: SelectDefaultProps) => {
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    try {
      const o = options?.find((item) => item.value === event.target.value);
      if (o) onChange(o);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Select
      labelId={`${id}-label`}
      onChange={handleChange}
      input={<StyledInput/>}
      value={value}
      // IconComponent={""}
    >
      <MenuItem value={""}>None</MenuItem>
      {options &&
        options.map((item) => (
          <MenuItem key={item.value} value={item.value}>
            {item.title}
          </MenuItem>
        ))}
    </Select>
  );
};

export default SelectDefault;
