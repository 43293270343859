import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

interface YearMonth {
  year: number;
  month: number;
}

interface CurrentDate extends YearMonth {
  date: Date;
}

interface CalendarBaseProps {
  date?: Date;
  month?: number;
  year?: number;
  cellComponent?: (e: CalendarBaseDay, index: number) => JSX.Element | null;
  spacing?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10;
}

export interface CalendarBaseDay {
  value?: Date;
  type: "previous" | "current" | "next";
  year: number;
  month: number;
  day: number;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
  },
  griditem: {
    width: "calc(100% / 7)",
  },
}));

const CalendarBase = ({
  cellComponent = (d: CalendarBaseDay, index?: number) => <div>{d.day}</div>,
  date,
  month,
  year,
  spacing,
}: CalendarBaseProps) => {
  const classes = useStyles();

  const [currentDate, setcurrentDate] = useState<CurrentDate | undefined>(
    undefined
  );

  const [prevDays, setprevDays] = useState<Array<CalendarBaseDay>>([]);
  const [currentDays, setcurrentDays] = useState<Array<CalendarBaseDay>>([]);
  const [nextDays, setnextDays] = useState<Array<CalendarBaseDay>>([]);

  // SETTING INITIAL DATE
  useEffect(() => {
    if (year && month) {
      const d = new Date(year, month);
      setcurrentDate({
        date: d,
        year: year,
        month: month,
      });
    } else {
      const d = date ? new Date(date) : new Date();
      setcurrentDate({
        date: d,
        year: d.getFullYear(),
        month: d.getMonth() + 1,
      });
    }
  }, [date, year, month]);

  const daysInMonth = ({ year, month }: YearMonth): number => {
    return new Date(year, month, 0).getDate();
  };

  const buildPrevDays = useCallback(function (ym: YearMonth) {
    let year = ym.year;
    let month = ym.month - 1;
    if (ym.month === 1) {
      year = year - 1;
      month = 12;
    }
    const prevDaysAmount = daysInMonth({ year, month });
    const { position } = firstDayOfMonth(ym);
    const arrDaysBefore =
      position > 0
        ? Array.from(Array(position - 1).keys())
        : Array.from(Array(6).keys());
    const daysBefore: Array<CalendarBaseDay> = arrDaysBefore
      .map(
        (e, i): CalendarBaseDay => ({
          day: prevDaysAmount - i,
          month: month,
          year: year,
          type: "previous",
        })
      )
      .reverse();
    return { days: daysBefore, amount: daysBefore.length };
  }, []);

  const buildCurrentDays = useCallback(function (ym: YearMonth) {
    const daysAmount = daysInMonth(ym);
    const arrDaysCurrent = Array.from(Array(daysAmount).keys());
    const dayscurrentMonth: Array<CalendarBaseDay> = arrDaysCurrent.map(
      (i): CalendarBaseDay => ({
        day: i + 1,
        month: ym.month,
        year: ym.year,
        type: "current",
      })
    );
    return { days: dayscurrentMonth, amount: dayscurrentMonth.length };
  }, []);

  const buildNextDays = useCallback(function (ym: YearMonth, amount: number) {
    let year = ym.year;
    let month = ym.month + 1;
    if (ym.month === 12) {
      year = ym.year + 1;
      month = 1;
    }
    const daysNext: Array<CalendarBaseDay> = Array.from(
      Array(amount).keys()
    ).map(
      (e): CalendarBaseDay => ({
        year,
        month,
        day: e + 1,
        type: "next",
      })
    );
    return { days: daysNext };
  }, []);

  useEffect(() => {
    if (currentDate) {
      const ym = { year: currentDate.year, month: currentDate.month };
      // GET PREVIOUS DAYS
      // console.log(buildPrevDays({year: currentDate.year, month: currentDate.month}));
      const { days: pDays, amount: pDaysAmount } = buildPrevDays(ym);
      setprevDays(pDays);

      // GET CURRENT DAYS
      const { days: cDays, amount: cDaysAmount } = buildCurrentDays(ym);
      // console.log(cDays);
      setcurrentDays(cDays);

      // GET FUTURE DAYS
      // amount of future days required (afdr)
      const afdr = 42 - pDaysAmount - cDaysAmount;
      const { days: nDays } = buildNextDays(
        {
          year: currentDate.year,
          month: currentDate.month,
        },
        afdr
      );
      setnextDays(nDays);
    }
  }, [currentDate, buildCurrentDays, buildPrevDays, buildNextDays]);

  const firstDayOfMonth = (ym: YearMonth): { date: Date; position: number } => {
    const firstDay = new Date(ym.year, ym.month - 1, 1);
    return { date: firstDay, position: firstDay.getDay() };
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={spacing ?? 1}>
        {[...prevDays, ...currentDays, ...nextDays].map(
          (d: CalendarBaseDay, i: number) => (
            <Grid item key={i} className={classes.griditem}>
              {cellComponent(d, i)}
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
};

export default CalendarBase;

// import React, { useState, useEffect } from "react";
// import { makeStyles } from "@material-ui/core/styles";
// import moment, { Moment } from "moment";
// import { Grid } from "@material-ui/core";

// interface CalendarBaseProps {
//   date?: Date;
//   month?: number;
//   year?: number;
//   cellComponent?: (e: CalendarBaseCellProps) => JSX.Element | null;
// }

// export interface CalendarBaseDay {
//   number: number;
//   value: Date;
//   type: "previous" | "current" | "next";
// }

// export interface CalendarBaseCellProps {
//   day: CalendarBaseDay;
// }

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: "flex",
//   },
//   griditem: {
//     width: "calc(100% / 7)",
//   },
// }));

// const CalendarBase = ({
//   cellComponent = (e: CalendarBaseCellProps) => <div>{e.day.number}</div>,
//   date,
//   month,
//   year,
// }: CalendarBaseProps) => {
//   const classes = useStyles();

//   const [currentMoment, setcurrentMoment] = useState<Moment>(
//     moment(new Date())
//   );

//   const [prevDays, setprevDays] = useState<Array<CalendarBaseDay>>([]);
//   const [currentDays, setcurrentDays] = useState<Array<CalendarBaseDay>>([]);
//   const [nextDays, setnextDays] = useState<Array<CalendarBaseDay>>([]);

//   useEffect(() => {
//     if (date) {
//       setcurrentMoment(moment(date));
//     } else if (year && month) {
//       setcurrentMoment(moment(`${year}-${month}`, "YYYY-MM"));
//     }
//   }, [date, year, month]);

//   useEffect(() => {
//     const prevMonthMoment = currentMoment.clone().subtract(1, "month");
//     const daysPrevMonth = daysInMonth(prevMonthMoment);
//     const arrDaysBefore = Array.from(Array(firstDayOfMonth() - 1).keys());
//     const daysBefore: Array<CalendarBaseDay> = arrDaysBefore
//       .map(
//         (e, i): CalendarBaseDay => ({
//           number: daysPrevMonth - i,
//           value: new Date(),
//           type: "previous",
//         })
//       )
//       .reverse();
//     setprevDays(daysBefore);
//     const daysCurrentMonth = daysInMonth(currentMoment);
//     const arrDaysCurrent = Array.from(Array(daysCurrentMonth).keys());
//     const dayscurrentMonth: Array<CalendarBaseDay> = arrDaysCurrent.map(
//       (i): CalendarBaseDay => ({ number: i + 1, value: new Date(), type: "current" })
//     );
//     setcurrentDays(dayscurrentMonth);
//     const daysNextMonth = 42 - daysBefore.length - dayscurrentMonth.length;
//     const daysNext: Array<CalendarBaseDay> = Array.from(
//       Array(daysNextMonth).keys()
//     ).map(
//       (e): CalendarBaseDay => ({
//         number: e + 1,
//         value: new Date(),
//         type: "next",
//       })
//     );
//     setnextDays(daysNext);
//   }, [currentMoment]);

//   const firstDayOfMonth = (m: Moment = currentMoment) => {
//     return Number(m.clone().startOf("month").format("E"));
//   };
//   const daysInMonth = (m: Moment) => m.daysInMonth();

//   return (
//     <div className={classes.container}>
//       <Grid container spacing={1}>
//         {[...prevDays, ...currentDays, ...nextDays].map(
//           (d: CalendarBaseDay, i: number) => (
//             <Grid item key={i} className={classes.griditem}>
//               {cellComponent({ day: d })}
//             </Grid>
//           )
//         )}
//       </Grid>
//     </div>
//   );
// };

// export default CalendarBase;
