import React, { useState } from "react";
import style from "./style.module.css";
import LoginForm, { FormValues } from "./LoginForm";
import Logo from "./Logo";
import { RouteComponentProps } from "react-router-dom";
import { requestLogin } from "./requests";
import { useProgressBar } from "../../components/bars/ProgressBarGlobal";

export default ({ history }: RouteComponentProps) => {
  const [apiError, setapiError] = useState("");
  const { barActivate, barStop } = useProgressBar();

  let timeout: number;

  const submit = async (values: FormValues) => {
    if (!values.email || !values.password) return;
    try {
      barActivate();
      await requestLogin(values);
      await history.push("/");
    } catch (error) {
      localStorage.clear();
      clearTimeout(timeout);
      timeout = setTimeout(() => setapiError(""), 3000);
      if (error.response && error.response.status === 401) {
        setapiError("Email or password does not match");
      } else {
        setapiError("Something went wrong. Please try again later");
      }
    } finally {
      barStop();
    }
  };

  return (
    <div className={style.container}>
      <div className={style.logoholder}>
        <Logo />
      </div>
      <LoginForm onSubmit={submit} externalError={apiError} />
    </div>
  );
};
