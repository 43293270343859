import React from "react";
import styles from "./styles.module.css";

interface ShiftRowProps {
  cell1: string | JSX.Element;
  cell2: string | JSX.Element;
  cell3: string | JSX.Element;
  cell4: string | JSX.Element;
  cell5: string | JSX.Element;
}

export default ({ cell1, cell2, cell3, cell4, cell5 }: ShiftRowProps) => {
  return (
    <div className={`${styles.row}`}>
      <div className={`${styles.cell} ${styles.cell1}`}>{cell1}</div>
      <div className={`${styles.cell} ${styles.cell2}`}>{cell2}</div>
      <div className={`${styles.cell} ${styles.cell3}`}>{cell3}</div>
      <div className={`${styles.cell} ${styles.cell4}`}>{cell4}</div>
      <div className={`${styles.cell} ${styles.cell5}`}>{cell5}</div>
    </div>
  );
};
