import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useTheme
} from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useProgressBar } from "../../components/bars/ProgressBarGlobal";
import RegularCheckbox from "../../components/CheckBoxes/RegularCheckbox";
import { getUserDetails } from "../../requests/user";
import { Location } from "../RosterScreen";
import {
  requestGetLocations,
  requestGetRosters,
  Roster
} from "../RosterScreen/requests";
import { User } from "../StaffScreen";
import { requestGetStaff } from "../StaffScreen/requests";
import styles from "./styles.module.css";

const useStyles = makeStyles((theme) => {
  const {
    palette: { text },
    shape: { borderRadius },
  } = theme;

  return {
    input: {
      borderRadius: theme.shape.borderRadius,
      border: ({ bordered }: { bordered: boolean }) => {
        return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
      },
      height: "100%",
      width: "100%",
    },
    outer: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: text.hint,
      borderRadius: borderRadius,
    },
    label: {
      fontSize: theme.typography.fontSize,
      marginBottom: "0.3em",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      marginLeft: 2,
      marginRight: 2,
    },
  };
});
const SelectedReport = ({ data }: any) => {
  const classes = useStyles({ bordered: true });
  const theme = useTheme();
  const { barActivate, barStop } = useProgressBar();
  const { enqueueSnackbar } = useSnackbar();
  const [rostersData, setRostersData] = useState<Array<Roster>>([]);
  const [userTypes, setUserTypes] = React.useState<Array<User>>([]);
  const [locationData, setLocationData] = useState<Location[]>([]);
  const [selectedData, setSelectedData] = useState<any>({
    FromDate: null,
    ToDate: null,
    StaffMember: "",
    Roster: [],
    location: 0,
    format: "CSV",
    IsDraft: false,
  });

  const { data: userData } = useQuery(["getUserDetails"], async () =>
    getUserDetails()
  );

  const username = 'SSRSRooster';
  const password = 'ProRoss$*12';
  const userString = `https://${username}:${password}@`;
  const url = `${data.url}&Tid=${userData?.tenants?.[0].id}`;
  let reportUrl = url.replace("https://", userString!);

  if (data.Parameter.length) {
    const filteredParameter = data.Parameter.filter(
      (para: any) => para !== "format"
    );
    const params = filteredParameter
      .map((para: any) =>
        para === "Roster"
          ? `${
              selectedData[para].length
                ? selectedData[para]
                    .map((ros: any) => `${para}=${ros}`)
                    .join("&")
                : rostersData.map((ros) => `${para}=${ros.id}`).join("&")
            }`
          : para === "FromDate" || para === "ToDate"
          ? `${para}=${moment(selectedData[para]).format("YYYY-MM-DD")}`
          : `${para}=${selectedData[para]}`
      )
      .join("&");
    reportUrl = reportUrl.concat(`&${params}`);
  }

  const viewReport = () => {
    const viewUrl = reportUrl.concat(`&rc:Toolbar=false`);
    window.open(viewUrl, "", "width=1200,height=600,left=200,top=200");
  };

  const downloadReport = () => {
    const downloadUrl = reportUrl.concat(
      `&rs:format=${
        data.Parameter.includes("format") ? selectedData.format : "PDF"
      }`
    );
    const link = document.createElement("a");
    link.href = downloadUrl;
    document.body.append(link);
    link.click();
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 150,
      },
    },
  };
  const retrieveUsers = useCallback(
    async (userType?: number | "admin") => {
      try {
        barActivate();
        const ut = await requestGetStaff(userType);
        setUserTypes(ut);
      } catch (err) {
        enqueueSnackbar("Unable to get users", { variant: "error" });
      } finally {
        barStop();
      }
    },
    [barActivate, barStop, enqueueSnackbar]
  );
  useEffect(() => {
    if (data) {
      retrieveUsers(undefined);
      setSelectedData({ ...selectedData, StaffMember: "" });
    }
  }, [retrieveUsers, data]);

  const getLocations = useCallback(async () => {
    try {
      barActivate();
      const result = await requestGetLocations();
      setLocationData(result);
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Unable to retrieve locations", { variant: "error" });
    } finally {
      barStop();
    }
  }, [setLocationData, enqueueSnackbar, barStop, barActivate]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  const handleChangeLocation = async (event: any) => {
    setSelectedData({ ...selectedData, location: event.target.value });
    if (event.target.value) {
      try {
        const rn = await requestGetRosters(event.target.value);
        setSelectedData({
          ...selectedData,
          location: event.target.value,
          Roster: [],
        });

        setRostersData(rn);
      } catch (error) {
        enqueueSnackbar("Unable to retrieve rosters", { variant: "error" });
      }
    } else {
      setSelectedData({ ...selectedData, Roster: [] });
      setRostersData([]);
    }
  };

  return (
    <>
      {((userData?.isAdministrator === false && data.access.includes("user")) ||
        (userData?.isAdministrator && data.access.includes("admin"))) && (
        <Box
          my={1}
          border={1}
          borderColor="divider"
          borderRadius={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid container spacing={1} className={styles.reportcontainer}>
            <Grid item lg={9} className={styles.reportdetails}>
              <Box display="flex">
                <Grid container spacing={2}>
                  <Grid item lg={2} className={styles.reportinput}>
                    <h3>{data.name}</h3>
                  </Grid>
                  <Grid item lg={10}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div style={{ display: "flex" }}>
                        <FormControl className={styles.formControl}>
                          {data.Parameter.includes("StaffMember") && (
                            <>
                              <InputLabel id="users-select-label">
                                Staff Member
                              </InputLabel>
                              <Select
                                labelId="users-select-label"
                                id="users-select"
                                value={selectedData.StaffMember}
                                onChange={(e) =>
                                  setSelectedData({
                                    ...selectedData,
                                    StaffMember: e.target.value,
                                  })
                                }
                              >
                                <MenuItem value="">None</MenuItem>
                                {userTypes.map((u) => (
                                  <MenuItem value={u.fullName}>
                                    {u.fullName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </>
                          )}
                        </FormControl>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Grid container spacing={1} alignItems="center">
                            {data.Parameter.includes("FromDate") && (
                              <Grid item lg={2} className={styles.reportinput}>
                                <Box>
                                  <KeyboardDatePicker
                                    variant="inline"
                                    className={styles.datepicker}
                                    label={
                                      data.Parameter.includes("ToDate")
                                        ? "From Date"
                                        : "Select Date"
                                    }
                                    value={selectedData.FromDate}
                                    format="dd/MM/yyyy"
                                    onChange={(date) =>
                                      setSelectedData({
                                        ...selectedData,
                                        FromDate: date,
                                      })
                                    }
                                    autoOk
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </Box>
                              </Grid>
                            )}
                            {data.Parameter.includes("ToDate") && (
                              <Grid item lg={2} className={styles.reportinput}>
                                <Box ml={2}>
                                  <KeyboardDatePicker
                                    className={styles.datepicker}
                                    variant="inline"
                                    label="To Date"
                                    value={selectedData.ToDate}
                                    format="dd/MM/yyyy"
                                    onChange={(date) =>
                                      setSelectedData({
                                        ...selectedData,
                                        ToDate: date,
                                      })
                                    }
                                    autoOk
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                </Box>
                              </Grid>
                            )}

                            {data.Parameter.includes("Roster") && (
                              <>
                                <Grid
                                  item
                                  lg={2}
                                  className={styles.reportinput}
                                >
                                  <Box ml={2}>
                                    <FormControl className={styles.formControl}>
                                      <InputLabel id="locations-select-label">
                                        Location
                                      </InputLabel>
                                      <Select
                                        labelId="locations-select-label"
                                        id="locations-select"
                                        value={selectedData.location}
                                        onChange={handleChangeLocation}
                                      >
                                        <MenuItem value={0}>None</MenuItem>
                                        {locationData.map((location) => (
                                          <MenuItem value={location.id}>
                                            {location.locationName}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                                <Grid
                                  item
                                  lg={2}
                                  className={styles.reportinput}
                                >
                                  <Box ml={2} style={{ maxWidth: "500px" }}>
                                    <FormControl className={styles.formControl}>
                                      <InputLabel id="roosters-mutiple-chip-label">
                                        Rosters
                                      </InputLabel>
                                      <Select
                                        labelId="roosters-mutiple-chip-label"
                                        id="roosters-mutiple-chip"
                                        multiple
                                        value={selectedData.Roster}
                                        onChange={(e) =>
                                          setSelectedData({
                                            ...selectedData,
                                            Roster: e.target.value,
                                          })
                                        }
                                        renderValue={(selected: any) => (
                                          <Box display="flex" flexWrap="wrap">
                                            {selected.map((value: any) => {
                                              const rooster = rostersData.find(
                                                (rooster) =>
                                                  rooster.id === value
                                              );
                                              return (
                                                <Chip
                                                  key={value}
                                                  label={rooster?.name}
                                                  className={classes.chip}
                                                />
                                              );
                                            })}
                                          </Box>
                                        )}
                                        MenuProps={MenuProps}
                                      >
                                        {rostersData.map((rooster) => (
                                          <MenuItem
                                            key={rooster.id}
                                            value={rooster.id}
                                          >
                                            {rooster.name}
                                          </MenuItem>
                                        ))}
                                      </Select>
                                    </FormControl>
                                  </Box>
                                </Grid>
                              </>
                            )}
                            {data.Parameter.includes("IsDraft") && (
                              <Grid item lg={2} className={styles.reportinput}>
                                <Box className={styles.checkbox}>
                                  <RegularCheckbox
                                    checked={selectedData.IsDraft}
                                    onClick={(checked) =>
                                      setSelectedData({
                                        ...selectedData,
                                        IsDraft: checked,
                                      })
                                    }
                                    sizeOuter={16}
                                    sizeInner={16}
                                  />
                                  <Typography>Include Draft Shifts</Typography>
                                </Box>
                              </Grid>
                            )}
                            {data.Parameter.includes("format") && (
                              <Grid item lg={2} className={styles.reportinput}>
                                <Box ml={2}>
                                  <FormControl className={styles.formControl}>
                                    <InputLabel id="format-select-label">
                                      Download Format
                                    </InputLabel>
                                    <Select
                                      labelId="format-select-label"
                                      id="format-select"
                                      value={selectedData.format}
                                      onChange={(e) =>
                                        setSelectedData({
                                          ...selectedData,
                                          format: e.target.value,
                                        })
                                      }
                                    >
                                      <MenuItem value={"CSV"}>CSV</MenuItem>
                                      <MenuItem value={"PDF"}>PDF</MenuItem>
                                      <MenuItem value={"EXCEL"}>EXCEL</MenuItem>
                                    </Select>
                                  </FormControl>
                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </MuiPickersUtilsProvider>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item lg={3} className={styles.reportsubmit}>
              <Box
                className={styles.buttonholder}
                height="100%"
                width="300px"
                p={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={viewReport}
                  disabled={
                    (data.Parameter.includes("FromDate") &&
                      selectedData.FromDate === null) ||
                    (data.Parameter.includes("ToDate") &&
                      selectedData.ToDate === null) ||
                    (data.Parameter.includes("Roster") &&
                      selectedData.location === 0)
                  }
                >
                  View Report
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={downloadReport}
                  disabled={
                    (data.Parameter.includes("FromDate") &&
                    selectedData.FromDate === null) ||
                  (data.Parameter.includes("ToDate") &&
                    selectedData.ToDate === null) ||
                  (data.Parameter.includes("Roster") &&
                    selectedData.location === 0)
                  }
                >
                  Download Report
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};

export default SelectedReport;
