import React, { FC, useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Day } from "../../screens/userView/UserMonthlyRoster";
import moment, { Moment } from "moment";

interface CalendarHorizontalBaseProps {
  cellComponent?: (
    d: Day,
    moment: Moment,
    index: number
  ) => JSX.Element | FC<any> | null;
  startMoment?: Moment;
  amountOfDays?: number;
}

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      width: "100%",
    },
  };
});

export default function CalendarHorizontalBase({
  cellComponent,
  startMoment,
  amountOfDays = 7,
}: CalendarHorizontalBaseProps) {
  const c = useStyles();

  const [initialMoment, setinitialMoment] = useState<Moment | undefined>(
    undefined
  );

  const [daysToRender, setdaysToRender] = useState<
    Array<{ moment: Moment; day: Day }>
  >([]);

  useEffect(() => {
    if (startMoment) {
      setinitialMoment(startMoment);
    } else {
      const m = moment();
      setinitialMoment(m);
    }
  }, [startMoment]);

  const buildDays = useCallback(() => {
    try {
      if (initialMoment && amountOfDays) {
        const arrOfDays = Array.from(Array(amountOfDays)).map((_, index) => {
          const m = initialMoment.clone().add(index, "days");
          return {
            moment: m,
            day: {
              day: Number(m.format("D")),
              month: Number(m.format("M")),
              year: Number(m.format("YYYY")),
            },
          };
        });
        setdaysToRender(arrOfDays);
      }
    } catch (err) {
      console.log(err);
    }
  }, [initialMoment, amountOfDays]);

  useEffect(() => {
    buildDays();
  }, [buildDays]);

  return (
    <div className={c.container}>
      {daysToRender.map(({ moment, day }, index) =>
        cellComponent ? (
          cellComponent(day, moment, index)
        ) : (
          <div key={moment.format("YYYY-MM-DD")}>
            {moment.format("YYYY-MM-DD")}
          </div>
        )
      )}
    </div>
  );
}
