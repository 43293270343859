import React from "react";
import { Formik, Field, Form } from "formik";
import style from "./style.module.css";
import { Link } from "react-router-dom";
import arrow from "./img/arrow.svg";
import { motion } from "framer-motion";
import { emailRegex } from "../../../GLOBALS";

export interface FormValues {
  email: string;
  password: string;
}

interface FormProps {
  onSubmit: (values: FormValues) => Promise<void>;
  externalError: string;
}

const validation = ({ email, password }: FormValues) => {
  const errors: { [key: string]: string } = {};
  if (!email) {
    errors.email = "Email required";
  } else if (!emailRegex.test(email)) {
    errors.email = "Invalid email address";
  }
  if (!password) {
    errors.password = "Password required";
  } else if (password.length < 6) {
    errors.password = "Password must be at least 6 chars";
  }

  return errors;
};

export default ({ onSubmit, externalError }: FormProps) => {
  return (
    <div className={style.formcontainer}>
      <Formik
        initialValues={{ email: "", password: "" }}
        validate={validation}
        onSubmit={onSubmit}
      >
        {({ errors, isValid, dirty, touched, isSubmitting }) => (
          <Form className={style.form} noValidate>
            <div
              className={`${style.errorblock} ${
                externalError ||
                (errors.email && touched.email) ||
                (errors.password && touched.password)
                  ? style.erropacity1
                  : ""
              }`}
            >
              <span className={style.errortext}>
                {externalError
                  ? externalError
                  : errors.email
                  ? errors.email
                  : errors.password
                  ? errors.password
                  : ""}
              </span>
            </div>
            <Field
              name="email"
              type="email"
              placeholder="Email"
              className={style.input}
              autoComplete="username"
              autoCorrect="off"
              autoCapitalize="none"
            />
            <Field
              name="password"
              type="password"
              placeholder="Password"
              className={style.input}
              autoComplete="current-password"
            />
            <div className={style.buttonsrow}>
              <Link
                to="/forgot-password"
                className={`${style.forgotpasswordtext} hidewhensmall`}
              >
                Forgot Password
              </Link>
              <motion.button
                type="submit"
                className={style.button}
                whileHover={!dirty || !isValid ? {} : { scale: 1.1 }}
                whileTap={!dirty || !isValid ? {} : { scale: 0.9 }}
                disabled={isSubmitting || !dirty || !isValid}
              >
                <span className={style.buttonText}>Sign in</span>
                <img alt="" src={arrow} className={style.arrow} />
              </motion.button>
            </div>
          </Form>
        )}
      </Formik>

      <Link
        to="/forgot-password"
        className={`${style.forgotpasswordtext} hidewhenmedium ${style.margin20em}`}
      >
        Forgot Password
      </Link>
    </div>
  );
};
