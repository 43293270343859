import React from "react";
import { FieldProps } from "formik";
import { InputBase } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => {
  const {
    palette: { text },
    shape: { borderRadius },
  } = theme;
  return {
    outer: {
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: text.hint,
      borderRadius: borderRadius,
      paddingLeft: "0.5em",
      paddingRight: "0.5em",
    },
  };
});

const TextInputFormik = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}: FieldProps) => {
  const classes = useStyles();
  return (
    <InputBase
      type="text"
      {...field}
      {...props}
      className={classes.outer}
      fullWidth
    />
  );
};

export default TextInputFormik;
