import styled from "styled-components";

export const CardTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

export const CardText = styled.span<{ flex?: number }>`
  display: flex;
  flex: ${({ flex }) => flex || 1};
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
`;
