import React, { useState, useEffect, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

interface TimePickerProps {
  onSelected?: (t: TimePickerReturn) => void;
  initialHour?: number;
  initialMinute?: number;
}

export interface TimePickerReturn {
  hour: number;
  minute: number;
}

const useStyles = makeStyles((theme) => {
  const cellHeight = theme.typography.fontSize * 2.5;
  const circleWidth = theme.typography.fontSize * 1.8;
  return {
    container: {
      maxWidth: 800,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    zone: {
      maxWidth: 400,
      minWidth: 270,
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      flex: 1,
    },
    hourCell: {
      height: cellHeight,
      display: "flex",
      flex: "0 16%",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      cursor: "pointer",
      transition: "background-color 0.2s ease-out",
      // "&:hover": {
      //   backgroundColor: theme.palette.grey[300],
      // },
    },
    timeCell: {
      display: "flex",
      flex: "0 10%",
      height: cellHeight,
      marginBottom: theme.spacing(1),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius,
      cursor: "pointer",
      transition: "background-color 0.2s ease-out",
      // "&:hover": {
      //   backgroundColor: theme.palette.grey[300],
      // },
    },
    circle: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: circleWidth,
      height: circleWidth,
      color: theme.palette.text.primary,
      transition: "all 0.2s ease-out",
      borderRadius: "10%",
    },
    active: {
      borderRadius: "50%",
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.main,
    },
    block: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      alignItems: "center",
    },
    spacer: {
      height: theme.spacing(2),
      width: theme.spacing(2),
    },
  };
});

export default function TimePicker({
  onSelected,
  initialHour,
  initialMinute,
}: TimePickerProps) {
  const c = useStyles();

  const [selectedHours, setselectedHours] = useState<number | undefined>(
    undefined
  );
  const [selectedMinutes, setselectedMinutes] = useState<number | undefined>(
    undefined
  );

  const handleTimeFromProps = useCallback(() => {
    if (initialHour) {
      setselectedHours(initialHour);
    }
    if (initialMinute) {
      setselectedMinutes(initialMinute);
    }
  }, [initialHour, initialMinute]);

  useEffect(() => {
    handleTimeFromProps();
  }, [handleTimeFromProps]);

  const handleSelection = useCallback(() => {
    if (
      onSelected &&
      (selectedHours || selectedHours === 0) &&
      (selectedMinutes || selectedMinutes === 0) &&
      (selectedHours !== initialHour || selectedMinutes !== initialMinute)
    ) {
      onSelected({ hour: selectedHours, minute: selectedMinutes });
    }
  }, [selectedHours, selectedMinutes, onSelected, initialHour, initialMinute]);

  useEffect(() => {
    handleSelection();
  }, [handleSelection]);

  const handleClickHours = (h: number) => {
    setselectedHours(h);
  };

  const handleClickMinutes = (m: number) => {
    setselectedMinutes(m);
  };

  return (
    <div className={c.container}>
      <div className={c.block}>
        <Typography variant="h6" color="textSecondary">
          Hours
        </Typography>
        <div className={c.zone}>
          {Array.from(Array(24).keys()).map((hour) => (
            <div
              className={c.hourCell}
              key={hour}
              onClick={() => handleClickHours(hour)}
            >
              <Typography
                variant="body2"
                color="textPrimary"
                className={`${c.circle} ${
                  hour === selectedHours ? c.active : ""
                }`}
              >
                {`${hour}`.padStart(2, "0")}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <div className={c.spacer} />

      <div className={c.block}>
        <Typography variant="h6" color="textSecondary">
          Minutes
        </Typography>
        <div className={c.zone}>
          {Array.from(Array(60).keys()).map((minute) => (
            <div
              className={c.timeCell}
              key={minute}
              onClick={() => handleClickMinutes(minute)}
            >
              <Typography
                variant="body2"
                color="textPrimary"
                className={`${c.circle} ${
                  minute === selectedMinutes ? c.active : ""
                }`}
              >
                {`${minute}`.padStart(2, "0")}
              </Typography>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
