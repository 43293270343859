export const emailRegex = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i); // i for case-insensitive matching
export const hoursMinutesRegex = RegExp(
  /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
);

export const pastelPalette = [
  "#0583f2",
  "#A6C7EA",
  "#6899AA",
  "#A9DCD3",
  "#88BDAB",
  "#BAC9B2",
  "#ACA60C",
  "#DFE88D",
  "#CDAB8F",
  "#575757",
  "#F8B2BD",
];
