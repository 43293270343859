import React from "react";
import styled from "styled-components";
import arrowLeft from "./arrow-left.svg";
import arrowRight from "./arrow-right.svg";

const Wrapper = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #efefef;
  }
`;

export const DateNavButtonText = styled.div`
  height: 30px;
  width: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  &:hover {
    background-color: #efefef;
  }
`;

export const DateNavText = styled.div`
  height: 30px;
  width: fit-content;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
  font-size: 20px;
`;

const Img = styled.img`
  height: 70%;
  width: 70%;
`;

interface ArrowButtonProps {
  onClick: () => void;
}

export function DateNavButtonLeft({ onClick }: ArrowButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <Img alt="arrow-left" src={arrowLeft} />
    </Wrapper>
  );
}

export function DateNavButtonRight({ onClick }: ArrowButtonProps) {
  return (
    <Wrapper onClick={onClick}>
      <Img alt="arrow-right" src={arrowRight} />
    </Wrapper>
  );
}

// export function DateNavButtonText({ children }: { children: string }) {
//   return <TextWrapper>{children}</TextWrapper>;
// }
