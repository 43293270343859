import React, { useState } from "react";
import style from "./style.module.css";
import { motion } from "framer-motion";
import { Formik, Field, Form, FormikProps } from "formik";
import ErrorBlockThin from "../../../components/ErrorBlockThin";
import StandardButton from "../../../components/Buttons/StandardButton";

export interface CreateTemplateWindowSubmitValues {
  name: string;
  id?: number;
}

interface CreateTemplateWindowProps {
  onSubmit: (values: CreateTemplateWindowSubmitValues) => void;
  onDelete: (id: number) => void;
  onCancel: () => void;
  apiError?: string;
  templateName?: string;
  templateId?: number;
}

interface ValidationProps {
  name: string;
}

interface ValidationErrors {
  [key: string]: string;
}

const validation = ({ name }: ValidationProps) => {
  const errors: ValidationErrors = {};
  if (!name) {
    errors.name = "Template name can not be empty";
  }
  return errors;
};

export default ({
  onSubmit,
  onDelete,
  onCancel,
  apiError,
  templateName,
  templateId,
}: CreateTemplateWindowProps) => {
  const [deleteMode, setdeleteMode] = useState<boolean>(false);

  if (deleteMode && templateId && templateName)
    return (
      <motion.div
        className={style.container}
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "tween", duration: 0.1 }}
      >
        <span className={style.title}>Delete template "{templateName}"?</span>
        <div className={style.buttonsholder}>
          <StandardButton
            title="Cancel"
            // height="tall"
            onClick={() => {
              setdeleteMode(false);
              onCancel();
            }}
          />
          <StandardButton
            title="Delete"
            // height="tall"
            // color="var(--deep-red)"
            onClick={() => onDelete(templateId)}
          />
        </div>
      </motion.div>
    );

  return (
    <motion.div
      className={style.container}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "tween", duration: 0.1 }}
    >
      <Formik
        initialValues={{ name: templateName || "" }}
        validate={validation}
        onSubmit={onSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          isValid,
          dirty,
          touched,
          isSubmitting,
        }: FormikProps<any>) => (
          <Form noValidate>
            <span className={style.title}>
              {templateName && templateId ? "Edit Template" : "New Template"}
            </span>
            <Field
              name="name"
              type="text"
              placeholder="Template Name"
              className={style.input}
            />
            <ErrorBlockThin
              message={apiError ? apiError : errors.name?.toString()}
            />
            <div className={style.buttonsholder}>
              <div>
                {templateId && (
                  <StandardButton
                    title="Delete"
                    // color="var(--deep-red)"
                    onClick={() => setdeleteMode(true)}
                  />
                )}
              </div>
              <div className={style.submitbuttonsholder}>
                <div className={style.cancelbuttonmargin}>
                  <StandardButton title="Cancel" onClick={onCancel} />
                </div>
                <StandardButton
                  title={templateName ? "Edit" : "Create"}
                  type="submit"
                  accent
                  disabled={isSubmitting || !isValid || !dirty}
                />
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </motion.div>
  );
};
