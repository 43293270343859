import React, { useEffect, useState } from "react";
import useAutocomplete from "@material-ui/lab/useAutocomplete";
import { makeStyles } from "@material-ui/core/styles";
import { useField } from "formik";
import { Typography } from "@material-ui/core";

interface AutocompleteBorderedFormikProps {
  name: string;
  options: Array<{ title?: string; value: string | number }>;
  placeholder?: string;
  onSelectedValueChange?: (value?: string | number) => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  inputHolder: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  input: {
    width: "100%",
    height: "100%",
    borderRadius: theme.shape.borderRadius,
    border: ({ bordered }: { bordered: boolean }) => {
      return bordered ? `1px solid ${theme.palette.grey[300]}` : "none";
    },
  },
  listbox: {
    boxSizing: "border-box",
    width: "100%",
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: "absolute",
    listStyle: "none",
    backgroundColor: theme.palette.background.paper,
    overflow: "auto",
    maxHeight: 200,
    "& li[data-focus='true']": {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      cursor: "pointer",
    },
    "& li:active": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },
  },
  li: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: theme.shape.borderRadius,
    padding: `0 ${theme.spacing(0.5)}px`,
  },
}));

export default function AutocompleteBorderedFormik({
  options,
  name,
  placeholder,
  onSelectedValueChange,
}: AutocompleteBorderedFormikProps) {
  const [, { value }, { setValue }] = useField({ name });
  const c = useStyles({ bordered: value ? false : true });
  const [inpValue, setinpValue] = useState<string>("");
  const {
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
  } = useAutocomplete({
    componentName: "AutocompleteBorderedFormik",
    options,
    id: name,
    getOptionLabel: (o) => o.title ?? `${o.value}`,
    onChange: (e, v) => {
      if (v && v.value) {
        setValue(v.value);
        if (onSelectedValueChange && v.value !== value) {
          onSelectedValueChange(v.value);
        }
      } else {
        setValue(null);
        if (onSelectedValueChange && value) {
          onSelectedValueChange(undefined);
        }
      }
    },
    getOptionSelected: (o, v) => {
      return o.value === v.value;
    },
    inputValue: inpValue,
    onInputChange: (e, v, reason) => {
      if (reason === "reset") {
        if (value) {
          const row = options.find((o) => o.value === value);
          if (row && row.title) {
            setinpValue(row.title);
          }
        } else {
          setinpValue("");
        }
      } else {
        setinpValue(v);
      }
    },
  });

  useEffect(() => {
    const row = options.find((o) => o.value === value);
    if (row && row.title) {
      setinpValue(row.title);
    }
    if (!value) {
      setinpValue("");
    }
  }, [value, options]);

  // console.log("autocomplete", value, options)

  return (
    <div {...getRootProps()} className={c.container}>
      <div className={c.inputHolder}>
        <input
          {...getInputProps()}
          className={c.input}
          placeholder={placeholder}
        />
      </div>
      {groupedOptions.length > 0 ? (
        <ul className={c.listbox} {...getListboxProps()}>
          {groupedOptions.map((option, index) => {
            return (
              <li {...getOptionProps({ option, index })} className={c.li}>
                <Typography>{option.title}</Typography>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
}
