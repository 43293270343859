import React, { useState, ReactNode } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import StandardButton from "../../../components/Buttons/StandardButton";
import {
  getMessageEmail,
  postMessageEmail,
  putMessageEnabled,
} from "../../../requests/settings";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { animated, useTransition } from "react-spring";
import Switch from "../../../components/Buttons/Switch";

const SectionContainer = styled.div`
  padding: ${({ theme }) => theme.gap[1]};
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  flex-direction: column;
  border-radius: ${({ theme }) => theme.shape.borderRadius};
`;

const SectionTitle = styled.div`
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.span`
  font-size: ${({ theme }) => theme.fonts.size.default};
  color: ${({ theme }) => theme.colors.greyishBrown};
`;

const SubjectInput = styled.input<{ errored?: boolean }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 1px solid
    ${({ theme, errored }) =>
      errored ? theme.colors.deepRed : theme.colors.greyish};
  padding: ${({ theme }) => theme.gap[1]};
  width: 100%;
  box-sizing: border-box;
  font-family: monospace;
  margin-bottom: ${({ theme }) => theme.gap[1]};
`;

const TextArea = styled.textarea<{ errored?: boolean }>`
  border-radius: ${({ theme }) => theme.shape.borderRadius};
  border: 1px solid
    ${({ theme, errored }) =>
      errored ? theme.colors.deepRed : theme.colors.greyish};
  resize: none;
  height: 140px;
  padding: ${({ theme }) => theme.gap[1]};
  width: 100%;
  box-sizing: border-box;
`;

const ButtonsHolder = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.gap[1]};
`;

interface Inputs {
  subject?: string | null;
  body: string;
  enabled?: boolean;
}

interface EmailTemplateFormProps {
  templateName: string;
  userFriendlyTemplateName: string;
  toggleVisible?: boolean;
  description?: ReactNode;
}
function EmailTemplateForm({
  templateName,
  userFriendlyTemplateName,
  toggleVisible,
  description,
}: EmailTemplateFormProps) {
  const [open, setOpen] = useState<boolean>(false);

  const transition = useTransition(open, null, {
    from: { maxHeight: 0 },
    enter: { maxHeight: 800 },
    leave: { maxHeight: 0 },
  });

  const { enqueueSnackbar } = useSnackbar();
  const { data, isLoading, refetch } = useQuery<Inputs, AxiosError>(
    templateName,
    () => getMessageEmail(templateName),
    {
      refetchOnWindowFocus: false,
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to get ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
    }
  );

  const { mutate } = useMutation<unknown, AxiosError, Inputs>(
    (data) => postMessageEmail(templateName, data),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to submit ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
      onSuccess: async () => {
        await refetch();
        reset();
        enqueueSnackbar(`${userFriendlyTemplateName} saved`, {
          variant: "success",
        });
      },
    }
  );

  const { mutate: toggle } = useMutation<unknown, AxiosError, boolean>(
    (enabled) => putMessageEnabled(templateName, enabled),
    {
      onError: (err) => {
        enqueueSnackbar(
          err.response && err.response.data.message
            ? err.response.data.message
            : `Unable to toggle ${userFriendlyTemplateName}`,
          { variant: "error" }
        );
      },
      onSuccess: () => {
        refetch();
      },
    }
  );

  const {
    register,
    handleSubmit,
    errors,
    formState: { isDirty },
    reset,
  } = useForm<Inputs>();

  const submit = (data: Inputs) => {
    mutate({ ...data, subject: data.subject ? data.subject : null });
  };

  function toggleFormOpen() {
    setOpen((o) => !o);
  }

  return (
    <SectionContainer>
      <SectionTitle onClick={toggleFormOpen}>
        <div />
        <Title>{userFriendlyTemplateName}</Title>
        {toggleVisible ? (
          <Switch active={data?.enabled} onToggle={toggle} />
        ) : (
          <div />
        )}
      </SectionTitle>

      {transition.map(
        ({ item, props, key }) =>
          item && (
            <animated.form
              key={key}
              onSubmit={handleSubmit(submit)}
              style={{ overflow: "hidden", ...props }}
            >
              {description}
              <SubjectInput
                name="subject"
                ref={register}
                disabled={isLoading}
                defaultValue={data?.subject || ""}
                placeholder="Subject"
                autoComplete={"off"}
                errored={!!errors.subject}
              />
              <TextArea
                name="body"
                ref={register({ required: true })}
                disabled={isLoading}
                defaultValue={data?.body}
                errored={!!errors.body}
                placeholder="HTML or plain text..."
              />
              <ButtonsHolder>
                <StandardButton
                  type="button"
                  title="Reset"
                  onClick={() => reset()}
                />
                <StandardButton
                  type="submit"
                  title="Save"
                  accent
                  disabled={!isDirty}
                />
              </ButtonsHolder>
            </animated.form>
          )
      )}
    </SectionContainer>
  );
}

export default EmailTemplateForm;
