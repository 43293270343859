import React, { Fragment } from "react";
import styled from "styled-components";
import { Collapse, IconButton } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Offer, OfferStatus } from "../../../requests/offers";
import moment from "moment";
import { User } from "../../StaffScreen";
import { Shift } from "../../RosterScreen/requests";
import {
  CardText,
  CardTitle,
} from "../../../components/styled-components/text";
import { OffersTd as Td, OffersTr as Tr } from "./styled";
import { RectangleBadgeFilled } from "../../../components/styled-components/badges";
import { styledTheme } from "../../../themes/styledTheme";
import OfferSwapCard from "./offer-swap-card";
import OfferCardsBlock from "./offer-cards-block";

const colors = {
  pending: styledTheme.colors.deepSkyBlue,
  cancelled: styledTheme.colors.deepRed,
  declined: styledTheme.colors.deepRed,
  accepted: "green",
};

interface OfferRowProps {
  offer: Offer;
  index?: number;
  onCancelClick: (offerId: number) => void;
}
function OfferRow({ offer, index, onCancelClick }: OfferRowProps) {
  const [open, setOpen] = React.useState(false);

  return (
    <Fragment>
      <Tr backgroundColor={index && index % 2 ? "#f5f6fa" : "#ffffff"}>
        <Td>
          <IconButton
            aria-label={`Offer ${offer.id} row icon`}
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Td>

        <Td>{offer.id}</Td>
        <Td>{moment(offer.createdAt).format("YYYY/MM/DD HH:mm")}</Td>
        <Td>{offer.outboundShift.shiftName}</Td>
        <Td>{offer.outboundUser?.fullName}</Td>
        <Td>{offer.inboundShift.shiftName}</Td>
        <Td>{offer.inboundUser?.fullName}</Td>
        <Td>
          <RectangleBadgeFilled backgroundColor={colors[offer.status]}>
            {offer.status}
          </RectangleBadgeFilled>
        </Td>
      </Tr>
      <Tr backgroundColor={index && index % 2 ? "#f5f6fa" : "#ffffff"}>
        <Td colSpan={8}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <OfferCardsBlock offer={offer} onCancelClick={onCancelClick} />
          </Collapse>
        </Td>
      </Tr>
    </Fragment>
  );
}

export default OfferRow;
